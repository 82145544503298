import "./App.css";
import { Routes, Route, useNavigate, useRoutes } from "react-router-dom";
// import { Dashboard } from "./components/Dashboard";
// import { Signup } from "./components/auth/Signup";
// import { Login } from "./components/auth/login";
// import { PrivateLayout } from "./components/PrivateLayout";
// import { Profile } from "./components/Profie";
// import { ManageUser } from "./components/ManageUser";
// import { AddCredit } from "./components/AddCredit";
// import { EditUser } from "./components/EditUser";
// import { Project } from "./components/Project";
// import { CreateProject } from "./components/CreateProject";
// import { EditPdfProject } from "./components/EditPdfProject";
// import ViewTransaction from "./components/ViewTransaction";
// import RequireAuth from "./components/RequireAuth";
import { AuthProvider } from "./context/AuthProvider";
// import ForgotPassword from "./components/ForgotPassword";
// import ResetPassword from "./components/ResetPassword";
// import Unauthorized from "./components/Unauthorized";
// import EditProject1 from "./components/EditProject1";
// import Register from "./components/auth/Register";

import PrivateLayout from "./components/PrivateLayout";
import Dashboard from "./components/Dashboard";
import Signup from "./components/auth/Signup";
import Login from "./components/auth/login";
import Profile from "./components/Profie";
import ManageUser from "./components/ManageUser";
import AddCredit from "./components/AddCredit";
import EditUser from "./components/EditUser";
import Project from "./components/Project";
import CreateProject from "./components/CreateProject";
import ViewTransaction from "./components/ViewTransaction";
import { EditPdfProject } from "./components/EditPdfProject";
import RequireAuth from "./components/RequireAuth";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Unauthorized from "./components/Unauthorized";
import EditProject1 from "./components/EditProject1";
import Register from "./components/auth/Register";
import AdminDashboard from "./components/AdminDashboard";
import CustomerCareDashboard from "./components/CustomerCareDashboard";
import MessageReminder from "./components/MessageReminder";
import Events from "./components/Events";
import { useEffect } from "react";
import { API } from "./API";
import useAuth from "./hooks/useAuth";
import ArchiveProjects from "./components/ArchiveProjects";
import Projects from "./components/Projects";

function App() {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    const checkToken = async () => {
      try {
        const res = await API.get("/auth/check-token");
        if (!res.data.success) {
          localStorage.removeItem("user");
          localStorage.removeItem("authToken");
        }
      } catch (error) {
        if (!error.response.data.success) {
          localStorage.removeItem("authToken");
          localStorage.removeItem("user");
          setAuth({});
          navigate("/login");
        }
      }
    };
    checkToken();
  }, []);

  return (
    <>
      <AuthProvider>
        {/* <Header /> */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>

        <Routes>
          <Route path="/" element={<PrivateLayout />}>
            <Route path="/userprofile" element={<Profile />} />
            <Route
              element={
                <RequireAuth allowedRoles={["ADMIN", "CUSTOMER_CARE"]} />
              }
            >
              <Route path="/manage-user" element={<ManageUser />} />
              <Route path="/edit-user" element={<EditUser />} />
              <Route path="/view-transaction" element={<ViewTransaction />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["ADMIN"]} />}>
              <Route path="/" index element={<AdminDashboard />} />
              <Route path="/create-user" element={<Signup />} />
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
              <Route path="/add-credit" element={<AddCredit />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["CUSTOMER_CARE"]} />}>
              <Route path="/" index element={<CustomerCareDashboard />} />
              <Route
                path="/customer-care-dashboard"
                index
                element={<CustomerCareDashboard />}
              />
            </Route>
            <Route element={<RequireAuth allowedRoles={["USER"]} />}>
              <Route path="/" index element={<Dashboard />} />
              <Route path="/user-dashboard" index element={<Dashboard />} />
              <Route path="/functions/project" element={<Project />} />
              <Route path="/functions" element={<Events />} />
              <Route path="/archive" element={<ArchiveProjects />} />
              <Route path="/projects" element={<Projects />} />

              <Route
                path="functions/project/create-project"
                element={<CreateProject />}
              />
              {/* <Route path="/project/edit-project" element={<EditProject />} /> */}
              <Route path="/project/edit-project" element={<EditProject1 />} />
              <Route path="/edit-pdf" element={<EditPdfProject />} />
              <Route
                path="/project/message-reminder"
                element={<MessageReminder />}
              />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
