import React, { useRef, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";

function Step2({
  nextStep,
  prevStep,
  handleFormData,
  values,
  url,
  resetValue,
}) {
  const [error, setError] = useState(false);
  const fileInputRef = useRef(null);

  const submitFormData = () => {
    if (!values?.pdf && !values.pdfDocument && !values.image) {
      setError(true);
    } else {
      nextStep();
    }
  };

  const clearPdf = () => {
    resetValue("pdf");
    fileInputRef.current.value = null;
  };

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-9">
          <label className="lable-fw-size">
            PDF/Image <span className="text-danger">*</span>{" "}
          </label>
          <button
            className="btn-gradient-primary nav-font border rounded next-btn-padding float-end mb-2"
            onClick={() => clearPdf("pdf")}
          >
            Clear
          </button>
          <form>
            <div className="form-group mt-2 ">
              <div className="d-flex w-100">
                <input
                  ref={fileInputRef}
                  type="file"
                  className="form-control form-fs rounded"
                  accept=".pdf, .png, .jpg"
                  name="image"
                  // defaultValue={values?.pdf}
                  onChange={handleFormData("image")}
                />

                {error ? (
                  <span className="text-danger form-fs">Pdf is required.</span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="space-between">
              <p className="text-danger text-end mt-1 form-fs">Max size: 5MB</p>
            </div>
            <div>
              <div className="mt-3">
                <div>
                  {url && (
                    <div>
                      <div
                        className="rounded"
                        style={{
                          height: "auto",
                        }}
                      >
                        {values.pdf ? (
                          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer fileUrl={url} />
                          </Worker>
                        ) : (
                          <img
                            src={url}
                            alt="Preview"
                            style={{ maxWidth: "100%", maxHeight: "200px" }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-2 d-flex" style={{ float: "right" }}>
              <button
                type="submit"
                className="btn-gradient-yellow nav-font border rounded next-btn-padding"
                onClick={prevStep}
              >
                Back
              </button>
              {(values?.pdf && values.pdfDocument) || values.image ? (
                <button
                  type="submit"
                  className="btn-gradient-primary nav-font border rounded next-btn-padding ms-2"
                  onClick={() => submitFormData()}
                >
                  Next
                </button>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Step2;
