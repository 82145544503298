import React from "react";
import { useNavigate } from "react-router-dom";
import { Password } from "./password";
import { UserProfile } from "./UserProfile";
import { RechargeHistory } from "./RechargeHistory";
import { AdminSettings } from "./AdminSettings";

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const ProfileRoute =
    user.userType === "USER"
      ? ["Profile", "Password", "Recharge History"]
      : user.userType === "CUSTOMER_CARE"
      ? ["Profile", "Password"]
      : ["Profile", "Password", "Settings"];
  const [selectedTab, isSelectedTab] = React.useState({
    key: 0,
    value: "Profile",
  });
  const navigate = useNavigate();
  const displayTab = () => {
    // console.log(selectedTab.value);
    switch (selectedTab.value) {
      case "Profile":
        return <UserProfile />;
      case "Password":
        return <Password />;
      case "Recharge History":
        return <RechargeHistory />;
      case "Settings":
        return <AdminSettings />;
      default:
        return <UserProfile />;
    }
  };
  return (
    <>
      {/* <div className="container mt-3 container-main ">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="page-title">User Profile</h3>
          {user?.userType !== "USER" && (
            <div>
              <button
                type="button"
                className="btn-gradient-primary nav-font p-2 border rounded"
                onClick={() => navigate("/manage-user")}
              >
                {" "}
                Manage Users
              </button>
            </div>
          )}
        </div>
        <div className="mt-3 bg-white rounded card-trans p-md-4 p-3">
          <div className="mt-1 mb-3">
            <h4 className="page-title">Manage Your Account</h4>
            <div className="mt-1" style={{ fontSize: "12px", opacity: "0.5" }}>
              Change Your Account Settings
            </div>
            <hr></hr>
            <div className="zipzap-tab">
            <Tabs
              defaultActiveKey={selectedTab}
              onSelect={(k) => {
                console.log("-kkkkk",k);
                isSelectedTab(k)
              }}
              // style={{backgroundColor:''}}
              activeKey={selectedTab} 
              fill
            >
              <Tab eventKey="profile" title="Profile">
                <UserProfile />
              </Tab>
              <Tab eventKey="password" title="Password">
                <Password />
              </Tab>
            </Tabs>
            </div>
          
          </div>
        </div>
      </div>
      {/* <Toaster /> */}{" "}
      <div className="container mt-3 container-main">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="page-title">User Profile</h3>
          {user?.userType !== "USER" && (
            <div>
              <button
                type="button"
                className="btn-gradient-primary nav-font p-2 border rounded"
                onClick={() => navigate("/manage-user")}
              >
                Manage Users
              </button>
            </div>
          )}
        </div>
        <div className="mt-3 bg-white rounded card-trans p-md-4 p-3">
          <div className="mt-1 mb-3">
            <h4 className="page-title">Manage Your Account</h4>
            <div className="mt-1" style={{ fontSize: "12px", opacity: "0.5" }}>
              Change Your Account Settings
            </div>
            <hr></hr>
            <div className="row">
              <div className="col-md-4">
                <ul className="list-group">
                  {ProfileRoute.map((el, key) => {
                    return (
                      <div key={key}>
                        <li
                          className={`
                        ${
                          key === selectedTab.key
                            ? " list-group-item zipzap-active"
                            : "list-group-item"
                        } `}
                          onClick={() =>
                            isSelectedTab({
                              key,
                              value: el,
                            })
                          }
                        >
                          {el}
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </div>
              <div className="col-md-8">{displayTab()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile;
