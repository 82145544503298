import React, { useRef, useState } from "react";
import { TypeBold, TypeItalic, EmojiSmile } from "react-bootstrap-icons";
import EmojiPicker from "emoji-picker-react";

function Step3({
  prevStep,
  handleFormData,
  values,
  project,
  userList,
  handleMsg,
  csvData,
  onUpload,
}) {
  const [text, setText] = useState(values.customMsg ? values.customMsg : "");
  const textareaRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const minDate = new Date().toISOString().slice(0, 16);

  const currentDate = new Date();
  const maxDate = new Date(currentDate.getTime() + 3 * 7 * 24 * 60 * 60 * 1000); // Add 3 weeks in milliseconds

  // Format the maximum date in ISO 8601 format
  const formattedMaxDate = maxDate.toISOString().slice(0, 16);

  const handleOptionClick = (option) => {
    const textarea = textareaRef.current;
    const cursorPosition = textarea.selectionStart;

    const beforeCursor = text.charAt(cursorPosition - 1);
    const afterCursor = text.charAt(cursorPosition);

    const spaceBefore =
      beforeCursor === " " || beforeCursor === "" || beforeCursor === "\n";
    const spaceAfter =
      afterCursor === " " || afterCursor === "" || afterCursor === "\n";

    const spaceToAdd = spaceBefore
      ? spaceAfter
        ? ""
        : " "
      : spaceAfter
      ? " "
      : "  ";

    const newText =
      text.slice(0, cursorPosition) +
      spaceToAdd +
      option +
      spaceToAdd +
      text.slice(cursorPosition);
    setText(newText);

    // Move the cursor to the end of the inserted option
    textarea.selectionStart =
      cursorPosition + spaceToAdd.length + option.length;
    textarea.selectionEnd = cursorPosition + spaceToAdd.length + option.length;
    textarea.focus();
    handleTextAreaChange({ target: { value: newText } });
  };

  const handleTextAreaChange = (event) => {
    const newValue = event.target.value;
    setText(newValue);

    // Call the handleMsg function with the updated text value
    handleMsg(newValue);
  };

  const handleBoldClick = () => {
    const textarea = textareaRef.current;
    const selectedText = textarea.value.substring(
      textarea.selectionStart,
      textarea.selectionEnd
    );

    // Check if the selected text contains italic formatting
    if (!selectedText.match(/^_.*_$/)) {
      const newText = `${text.substring(
        0,
        textarea.selectionStart
      )}*${selectedText}*${text.substring(textarea.selectionEnd)}`;
      setText(newText);
      handleTextAreaChange({ target: { value: newText } });
    }
  };

  const handleItalicClick = () => {
    const textarea = textareaRef.current;
    const selectedText = textarea.value.substring(
      textarea.selectionStart,
      textarea.selectionEnd
    );

    // Check if the selected text contains bold formatting
    if (!selectedText.match(/^\*.*\*$/)) {
      const newText = `${text.substring(
        0,
        textarea.selectionStart
      )}_${selectedText}_${text.substring(textarea.selectionEnd)}`;
      setText(newText);
      handleTextAreaChange({ target: { value: newText } });
    }
  };

  const handleEmojiClick = (emojiData) => {
    const textarea = textareaRef.current;
    const cursorPosition = textarea.selectionStart;

    const newText =
      text.slice(0, cursorPosition) +
      emojiData.emoji +
      text.slice(cursorPosition);
    setText(newText);

    handleTextAreaChange({ target: { value: newText } });

    textarea.selectionStart = cursorPosition + emojiData.emoji.length;
    textarea.selectionEnd = cursorPosition + emojiData.emoji.length;
    textarea.focus();
  };

  // const applyValuesToPlaceholders = async () => {
  //   let string_result = text;

  //   for (const key in element) {
  //     if (element.hasOwnProperty(key)) {
  //       const regex = new RegExp(`\\$\\{${key}\\}`, "g");
  //       string_result = string_result.replace(regex, element[key]);
  //     }
  //   }
  //   console.log("message", string_result);

  //   setText(string_result);
  // };

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-9">
          <div className="form-group mt-3">
            <label className="lable-fw-size">Message</label>
            <textarea
              ref={textareaRef}
              id="w3review"
              className="form-control mt-1 form-fs form-label-border p-3"
              rows="3"
              cols="50"
              name="customMsg"
              onChange={handleTextAreaChange}
              value={text}
              //   onChange={(e) => setCustomMsg(e.target.value)}
            />
            <div className="d-flex justify-content-between">
              <div className="mt-3 d-flex flex-sm-row flex-column">
                {project?.completStatus === "FAILED"
                  ? userList.length > 0 &&
                    Object.keys(userList[0])?.map((s, index) => {
                      return (
                        s !== "number" &&
                        s !== "_id" &&
                        s !== "userId" && (
                          <div
                            key={index}
                            className="me-2 p-md-2 rounded form-fs mt-2 mt-sm-0"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#e2e6ea",
                            }}
                            onClick={() => handleOptionClick(`\${${s}}`)}
                            // onClick={() =>
                            //   handleMsg(`${values?.customMsg} $${`{${s}}`}`)
                            // }
                          >
                            {" "}
                            {s} {""}
                          </div>
                        )
                      );
                    })
                  : csvData.length > 0 &&
                    Object.keys(csvData[0]).map((s, index) => {
                      return (
                        s !== "number" && (
                          <div
                            key={index}
                            className="me-2 p-2 rounded form-fs mt-2 mt-sm-0"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#e2e6ea",
                            }}
                            onClick={() => handleOptionClick(`\${${s}}`)}
                            // onClick={() =>
                            //   handleMsg(`${values?.customMsg} $${`{${s}}`}`)
                            // }
                          >
                            {" "}
                            {s} {""}
                          </div>
                        )
                      );
                    })}
              </div>

              <div className="mt-3 d-flex flex-sm-row flex-column">
                <TypeBold
                  size={30}
                  color={"#006aa8"}
                  onClick={handleBoldClick}
                />
                <TypeItalic
                  color={"#006aa8"}
                  size={30}
                  onClick={handleItalicClick}
                />
                <EmojiSmile
                  color={"#f98f03"}
                  size={30}
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                />
              </div>
            </div>
            {showEmojiPicker && (
              <div className="mt-2">
                <EmojiPicker onEmojiClick={handleEmojiClick} />{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-9 col-12">
          <div className="form-group mt-4 col-md-5 col-10">
            <div className="row mt-3 align-items-end">
              <label className="lable-fw-size md-4">Delay Second</label>
              <div className="form-group md-4">
                <input
                  type="number"
                  className="form-control mt-1 form-fs form-label-border p-2"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="delaySecond"
                  defaultValue={values.delaySecond}
                  onChange={handleFormData("delaySecond")}
                  min={1}
                  max={10}
                />
              </div>
            </div>
            <div className="row mt-3 align-items-end">
              <label className="lable-fw-size md-4">Schedule Time</label>
              <div className="form-group md-4">
                <input
                  type="datetime-local"
                  className="form-control mt-1 form-fs form-label-border p-2"
                  id="scheduleDate"
                  aria-describedby="scheduleDate"
                  name="scheduleDate"
                  defaultValue={values.scheduleDate}
                  onChange={handleFormData("scheduleDate")}
                  min={minDate}
                  max={formattedMaxDate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 d-flex" style={{ float: "right" }}>
        <button
          type="submit"
          className="btn-gradient-yellow nav-font border rounded next-btn-padding"
          onClick={prevStep}
        >
          Back
        </button>
        {(csvData.length || userList.length) > 0 &&
          (values?.pdf || values.image || values.isMessage) && (
            <button
              className="btn-gradient-primary nav-font border rounded next-btn-padding ms-2"
              type="submit"
              onClick={onUpload}
            >
              Upload
            </button>
          )}
      </div>
    </div>
  );
}

export default Step3;
