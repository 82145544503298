import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import noProjectGif from "../images/No_project.gif";
import { API } from "../API";
import toast from "react-hot-toast";
import { TableLoader } from "./TableLoader";
import moment from "moment";
import { PauseCircle, PlayCircle, Send, XCircle } from "react-bootstrap-icons";
import { truncatedTableCell } from "../utils/helpers";
import { Modal } from "react-bootstrap";
import { JsonToExcel } from "react-json-to-excel";
import Loading from "react-fullscreen-loading";

const Projects = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [setting, setSetting] = useState({});
  const [activeTab, setActiveTab] = useState("active");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [sendedUser, setSendedUser] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [isSessionStop, setIsSessionStop] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [qrData, setQrData] = useState("");
  const [loading, setLoading] = useState(false);
  const [qrModalStatus, setQrModalStatus] = useState(false);
  const [cancelProjectId, setcancelProjectId] = useState("");
  const [cancelProject, setCancelProject] = useState(false);
  const [reminderMessageSendedUser, setReminderMessageSendedUser] = useState(
    []
  );
  const [showReminderReport, setShowReminderReport] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalProjects, setTotalProjects] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [strings, setStrings] = useState("");
  const [page, setPage] = useState(1);
  const [pagelimits, setPagelimits] = useState([10, 20, 30, 40, 50]);

  // console.log("pageLimit----------",totalProjects,pageLimit);

  const handlePageLimitChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "All") {
      setPageLimit(totalProjects);
    } else {
      setPageLimit(selectedValue);
    }
    setPage(1);
  };

  // console.log("????????????????", selectedFilter);

  const getSetting = async () => {
    const { data } = await API.get("/admin-setting/");
    if (data.success) {
      setSetting(data.data);
      // console.log("data.data[0]?._id", data?.data?.functions[0]?._id);
      setSelectedFilter(data?.data?.functions[0]?._id);
    } else {
      toast.error(data.message);
    }
  };
  useEffect(() => {
    getSetting();
  }, []);

  // const getProject = async () => {
  //   const getAllProject = await API.get(
  //     `/project/get?page=${page}&limit=${pageLimit}`
  //   );
  //   const response = getAllProject.data;
  //   setData(response.data.result);
  //   setTotalProjects(response.data.projectCount);
  //   setIsLoading(false);
  //   setPage(1);
  // };

  const getProjects = async () => {
    try {
      setIsLoading(true);
      setData([]);
      const response = await API.get(
        `/project/filter/${selectedFilter}/${activeTab}?limit=${pageLimit}`
      );
      if (response.data.success) {
        // console.log("response===", response.data.data.result);
        setData(response.data.data.result);
        setTotalProjects(response.data.data.projectCount);
        // if (strings.length > 0) {
        //   const filtered = response.data.data.result.filter((project) =>
        //     project.name.toLowerCase().includes(strings.toLowerCase())
        //   );
        //   setFilterData(filtered);
        // } else {
        //   console.log("data-------->>", data);
        //   setFilterData(response.data.data.result);
        // }
      }
      // setFilterData(response.data.data.result);

      setIsLoading(false);
    } catch (error) {
      console.log("Error getting projects", error);
    }
  };

  let timer2;
  const getQr = async () => {
    if (!qrData) {
      setIsSessionStop(false);
      setLoading(true);
      timer2 = setInterval(async () => {
        const sesisonData = await API.get(
          `/whatsapp/issessionsaved/?projectId=${projectId}`
        );
        if (sesisonData.data.success === false) {
          const { data } = await API.get(
            `/whatsapp/getqr/?projectId=${projectId}`
          );
          setLoading(false);
          if (data.success) {
            setQrModalStatus(true);
            setQrData(data.data);
            getQrData();
          } else {
            toast.error(data.message);
          }
        }
      }, 10000);
    }
    if (qrData) {
      clearInterval(timer2);
    }
  };
  const getQrData = () => {
    return clearInterval(timer2);
  };

  let timer;
  const getScanQrCodeStatus = async () => {
    if (qrModalStatus) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(async () => {
        const { data } = await API.get(
          `/whatsapp/issessionsaved?projectId=${projectId}`
        );
        if (data.success) {
          setQrModalStatus(false);
          toast.success("Session added successfull");
        }
        // setSession(data);
      }, 10000);
    }
    if (!qrModalStatus) {
      clearInterval(timer);
    }
  };
  useEffect(() => {
    getScanQrCodeStatus();
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrModalStatus, timer]);

  const handleCancel = async (id) => {
    const { data } = await API.patch(
      `/project/project-status-change?projectId=${id}`
    );
    await API.get(`/whatsapp/logout?projectId=${id}`);
    toast.success(data.message);
    setCancelProject(false);
    getProjects();
  };

  useEffect(() => {
    getProjects();
    const interval = setInterval(() => {
      getProjects();
      // console.log("============interval Time out Projects tab===============");
    }, 20000);

    // Clean up function to clear interval when the component unmounts
    return () => clearInterval(interval);
  }, [selectedFilter, activeTab, pageLimit]);

  // const getArchive = async () => {
  //   const { data } = await API.get("/project/get-delete");
  //   if (data.success) {
  //     setData(data.data.projectCounts);
  //     console.log(data.data.projectCounts);
  //     setIsLoading(false);
  //   } else {
  //     toast.error(data.message);
  //   }
  // };

  const getFindUser = (project, value) => {
    if (project?.completStatus !== "NOT STARTED") {
      console.log("value========", value);
      // Check if countUser exists and is an array
      if (Array.isArray(project?.countUser)) {
        // console.log(
        //   "project?.countUser--------------------",
        //   project?.countUser
        // );
        const result = project.countUser.filter((s) => s._id === value);
        if (result.length) return result[0].total;
        else return 0;
      }
      // const result = project?.countUser.filter((s) => s._id === value);
      // if (result.length) return result[0].total;
      // else return 0;
    } else {
      return "-";
    }
  };

  const getReport = async (id) => {
    setShowReport(true);
    const { data } = await API.get(
      `/project/get-project-wise-user-list?projectId=${id}`
    );
    setSendedUser(data.data);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // if (activeTab === "active") {
    //   getProject();
    // } else if (activeTab === "archive") {
    //   getArchive();
    // }
  }, [activeTab]);

  // useEffect(() => {
  //   getProject();

  //   const interval = setInterval(() => {
  //     getProject();
  //     console.log(
  //       "=====================================interval Time out Projects tab================================"
  //     );
  //   }, 20000);

  //   // Clean up function to clear interval when the component unmounts
  //   return () => clearInterval(interval);
  // }, [getProject, page]);

  const handlePause = async (id, file_sending_status) => {
    const { data } = await API.post(
      `/whatsapp/fileSendingStatus?projectId=${id}`,
      {
        file_sending_status: file_sending_status,
      }
    );
    if (data.success) {
      toast.success(data.message);
      getProjects();
    } else {
      toast.error("Failed to pause the project: " + data.message);
      setProjectId(id);
      setIsSessionStop(true);
    }
  };

  const getReminderReport = async (id) => {
    setShowReminderReport(true);
    const { data } = await API.get(
      `/project/get-message-reminder-list?projectId=${id}`
    );
    setReminderMessageSendedUser(data.data);
  };

  const handleFilterSelect = (type) => {
    setSelectedFilter(type);
  };

  useEffect(() => {
    if (strings.length > 0) {
      const filtered = data.filter((project) =>
        project.name.toLowerCase().includes(strings.toLowerCase())
      );
      setFilterData(filtered);
    } else {
      setFilterData(data);
    }
  }, [strings, data]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between p-md-3 p-2 ">
        <h1 className="page-title">Projects </h1>
        <div className="d-flex gap-2">
          <input
            type="search"
            className="border-color-primary border rounded"
            placeholder="Search by project name"
            onChange={(e) => setStrings(e.target.value.trim())}
          />
          <button
            className="btn btn-dropdown btn-gradient-primary nav-font p-2 border rounded dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            onClick={toggleDropdown}
            aria-haspopup="true"
            aria-expanded={isOpen ? "true" : "false"}
          >
            Create Project
          </button>
          <div
            className={`dropdown-menu end-0 ${isOpen ? "show" : ""}`}
            aria-labelledby="dropdownMenuButton"
          >
            {setting?.functions?.map((e, i) => (
              <Link
                key={i}
                to={"/functions/project/create-project"}
                state={{ id: e._id, function: e.function }}
                className="dropdown-item"
              >
                {e.function}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between  mx-3">
        <div className="d-flex align-items-center gap-2">
          {[
            { name: "Active", key: "active" },
            { name: "Archived", key: "archive" },
          ].map((t) => (
            <div
              className={`custom-tab p-2 page-title  ${
                activeTab === t.key
                  ? "font-weight-bold text-color-primary border-bottom border-primary"
                  : "font-weight-bold text-secondary"
              }`}
              onClick={() => setActiveTab(t.key)}
            >
              {t.name}
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center gap-3">
          <div className="">
            <select
              class="form-select"
              onChange={(e) => setSelectedFilter(e.target.value)}
              aria-label="Default select example"
            >
              {setting?.functions?.map((e, i) => (
                <option key={i} value={e._id}>
                  {e.function}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              style={{
                background: "#006aa8",
                color: "white",
                padding: "5px",
                border: "rounded",
              }}
              value={pageLimit}
              // onChange={handlePageLimitChange}`
              onChange={(e) => setPageLimit(e.target.value)}
            >
              {pagelimits.map((limit) => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
              <option value={totalProjects}>All</option>
            </select>
          </div>
        </div>
      </div>
      <div
        className="table-responsive text-wrap mt-2 table-Fixed mx-3"
        style={{
          height: "78vh",
          overflow: "auto",
          position: "relative",
          zIndex: 1,
          boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)",
        }}
      >
        <table className="table table-striped">
          <thead className="table">
            <tr className="text-color-primary">
              <th scope="col " className="lable-fw-size">
                Name
              </th>
              <th scope="col " className="lable-fw-size  text-center">
                Process
              </th>
              <th scope="col " className="lable-fw-size  text-center">
                Status
              </th>
              <th scope="col " className="lable-fw-size  text-center">
                Success
              </th>
              <th scope="col " className="lable-fw-size  text-center">
                Fail
              </th>
              <th scope="col " className="lable-fw-size  text-center">
                ScheduledAt
              </th>
              <th scope="col " className="lable-fw-size  text-center">
                Date
              </th>
            </tr>
          </thead>
          {isLoading ? (
            <tbody className="table-bordered">
              <tr>
                <td colSpan={"7"}>
                  <TableLoader />
                </td>
              </tr>
            </tbody>
          ) : filterData?.length > 0 ? (
            <>
              <tbody className="table-bordered">
                {filterData?.map((el, key) => {
                  return (
                    <React.Fragment key={key}>
                      <tr className="lable-fw-size ">
                        <td>
                          {el.completStatus === "SUCCEED" ||
                          el.completStatus === "CANCELLED" ||
                          el.completStatus === "SESSION NOT FOUND" ||
                          el.completStatus === "SCHEDULED" ||
                          (activeTab === "archive" &&
                            el.completStatus === "NOT STARTED") ||
                          el.completStatus === "RUNNING" ? (
                            <div>{truncatedTableCell(el.name)}</div>
                          ) : (
                            <Link
                              to={"/project/edit-project"}
                              state={{ id: el._id }}
                              className="btn-link"
                            >
                              {truncatedTableCell(el.name)}
                            </Link>
                          )}
                        </td>
                        {/* <td>
                          {el.completStatus === "SUCCEED" ||
                          el.completStatus === "CANCELLED" ||
                          el.completStatus === "SESSION NOT FOUND" ||
                          el.completStatus === "SCHEDULED" ||
                          (activeTab === "archive" &&
                            el.completStatus === "NOT STARTED") ||
                          el.completStatus === "RUNNING" ? (
                            <div>{truncatedTableCell(el.name)}</div>
                          ) : (
                            <Link
                              to={"/projects/manyprojects"}
                              state={{ id: el._id }}
                              className="btn-link"
                            >
                              {truncatedTableCell(el.name)}
                            </Link>
                          )}
                        </td> */}
                        <td className="text-center">
                          {el.completStatus === "RUNNING" &&
                          el.file_sending_status === true ? (
                            <PauseCircle
                              size={20}
                              color="orange"
                              onClick={() => handlePause(el._id, false)}
                            />
                          ) : (el.completStatus === "RUNNING" ||
                              el.completStatus === "SESSION NOT FOUND") &&
                            el.file_sending_status === false ? (
                            <>
                              <PlayCircle
                                size={20}
                                color="green"
                                onClick={() => handlePause(el._id, true)}
                              />
                              <span> </span>
                              <XCircle
                                size={20}
                                color="red"
                                onClick={() => {
                                  setCancelProject(true);
                                  setcancelProjectId(el._id);
                                }}
                              />
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="text-center">
                          {el.completStatus === "SUCCEED" ||
                          el.completStatus === "RUNNING" ||
                          el.completStatus === "FAILED" ||
                          el.completStatus === "CANCELLED" ? (
                            <>
                              <div className="d-flex flex-sm-row flex-column align-items-center justify-content-center">
                                {" "}
                                <div
                                  className={`${
                                    el.completStatus === "SUCCEED"
                                      ? "text-success"
                                      : "text-danger"
                                  } badge`}
                                >
                                  {el.completStatus}
                                </div>
                                <div
                                  style={{
                                    color: "#9c9fa6",
                                  }}
                                >
                                  <img
                                    alt="doc"
                                    src={window.location.origin + "/file.png"}
                                    width={20}
                                    height={20}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => getReport(el._id)}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              className={`${
                                el.completStatus === "NOT STARTED"
                                  ? "text-info"
                                  : "text-warning"
                              } badge`}
                            >
                              {el.completStatus}
                            </div>
                          )}
                        </td>
                        {/* <td className="text-center">
                          {el.completStatus !== "RUNNING" &&
                          el.completStatus !== "NOT STARTED" &&
                          el.completStatus !== "SCHEDULED" &&
                          el.completStatus !== "SESSION NOT FOUND" &&
                          (el.reminder_status === "NOT STARTED" ||
                            el.reminder_status === "SESSION NOT FOUND") ? (
                            <Link
                              to={"/project/message-reminder"}
                              state={{ id: el._id }}
                              className="btn-link"
                            >
                              <Send color="#006aa8" size={16} />
                            </Link>
                          ) : el.reminder_status === "SUCCEED" ? (
                            <div
                              style={{
                                color: "#9c9fa6",
                              }}
                            >
                              <img
                                alt="doc"
                                src={window.location.origin + "/file.png"}
                                width={20}
                                height={20}
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => getReminderReport(el._id)}
                              />
                            </div>
                          ) : (
                            "-"
                          )}
                        </td> */}
                        <td className="text-center">{getFindUser(el, true)}</td>
                        <td className="text-center">
                          {getFindUser(el, false)}
                        </td>
                        <td className="text-center">
                          {el.schedule_date
                            ? moment(el.schedule_date).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )
                            : "-"}
                        </td>
                        <td className="text-center">
                          {el.wDate
                            ? moment(el.wDate).format("DD-MM-YYYY")
                            : "-"}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </>
          ) : (
            <tbody className="table-bordered">
              <td colSpan={"6"} style={{ textAlign: "center" }}>
                <img src={noProjectGif} alt="No project" className="mt-1" />
              </td>
            </tbody>
          )}
        </table>
      </div>

      {showReport && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showReport}
          onHide={() => setShowReport(false)}
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto fw-bold">
              Project Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-end">
              <JsonToExcel
                title="Export Excel"
                data={sendedUser.filter((s) => {
                  let _data = s;
                  delete _data._id;
                  return _data;
                })}
                fileName="master"
                btnClassName="btn-gradient-primary rounded nav-font d-inline px-3 py-1"
                btnColor="#006aa8"
              />
            </div>
            <div
              className="table-responsive text-wrap mt-5"
              style={{
                height: "350px",
                overflowY: "auto",
                position: "relative",
                zIndex: 1,
              }}
            >
              <table className="table table-responsive table-striped table-bordered table-fixed">
                <thead className="table">
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th className="text-center">Send Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sendedUser.map((value, index) => {
                    return (
                      <tr key={index} className="lable-fw-size">
                        <td>{value.name}</td>
                        <td>{value.number}</td>
                        <td className="text-center">
                          {value.status ? (
                            <img
                              alt="success"
                              src={window.location.origin + "/checkmark.png"}
                              width={20}
                              height={20}
                            />
                          ) : (
                            <img
                              alt="fail"
                              src={window.location.origin + "/close.png"}
                              width={20}
                              height={20}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showReminderReport && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showReminderReport}
          onHide={() => setShowReminderReport(false)}
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto fw-bold">
              Reminder Message Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-end">
              <JsonToExcel
                title="Export Excel"
                data={reminderMessageSendedUser.filter((s) => {
                  let _data = s;
                  delete _data._id;
                  return _data;
                })}
                fileName="Reminder_Report"
                btnClassName="btn-gradient-primary rounded nav-font d-inline px-3 py-1 "
                btnColor="#006aa8"
              />
            </div>
            <div
              className="table-responsive text-wrap mt-5"
              style={{
                height: "350px",
                overflowY: "auto",
                position: "relative",
                zIndex: 1,
              }}
            >
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th className="text-center">Reminder Status</th>
                  </tr>
                </thead>
                <tbody>
                  {reminderMessageSendedUser.map((value, index) => {
                    return (
                      <tr key={index} className="lable-fw-size">
                        <td>{value.name}</td>
                        <td>{value.number}</td>
                        <td className="text-center">
                          {value.reminder_message_status ? (
                            <img
                              alt="success"
                              src={window.location.origin + "/checkmark.png"}
                              width={20}
                              height={20}
                            />
                          ) : (
                            <img
                              alt="fail"
                              src={window.location.origin + "/close.png"}
                              width={20}
                              height={20}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {isSessionStop && (
        <Modal
          size="sm"
          show={isSessionStop}
          onHide={() => setIsSessionStop(false)}
          centered
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto page-title">
              Session Not Found
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <div className="fw-bold">Scan Qr Code</div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn-light fw-bold  border rounded"
              onClick={() => getQr()}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn-gradient-primary fw-bold border rounded "
              onClick={() => setIsSessionStop(false)}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {cancelProject && (
        <Modal
          size="sm"
          show={cancelProject}
          onHide={() => setCancelProject(false)}
          centered
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto page-title">
              Cancel Project
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <div className="fw-bold">Are you sure?</div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn-light fw-bold  border rounded"
              onClick={() => handleCancel(cancelProjectId)}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn-gradient-primary fw-bold border rounded "
              onClick={() => setCancelProject(false)}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {qrModalStatus && (
        <Modal
          show={qrModalStatus}
          backdrop="static"
          centered
          className="qrmodal"
        >
          <Modal.Body>
            <div className="m-3">
              <div
                style={{
                  width: "264px",
                  height: "264px",
                  background: `url(${qrData})`,
                  // backgroundSize: "cover",
                  // backgroundPosition: "center",
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}

      {loading && (
        <div>
          <Loading loading={loading} loaderColor="#24292f" />
        </div>
      )}
    </>
  );
};

export default Projects;
