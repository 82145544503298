import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./fonts/FinalFonts/BHUJ-UNICODE.ttf";
import "./fonts/FinalFonts/Surat-Unicode.ttf";
import "./fonts/FinalFonts/Shruti.ttf";
import "./fonts/FinalFonts/Padmaa.ttf";
import "./fonts/FinalFonts/Mogra.ttf";
import "./fonts/FinalFonts/Rekha.ttf";
import "./fonts/FinalFonts/Ekatra-B.ttf";
import "./fonts/FinalFonts/HindVadodara-Bold.ttf";
import "./fonts/FinalFonts/Lohit_Gujarati.ttf";
import "./fonts/FinalFonts/MuktaVaani-Bold.ttf";
import "./fonts/FinalFonts/MuktaVaani-ExtraBold.ttf";
import "./fonts/FinalFonts/RAJKOT-UNICODE.ttf";
import "./fonts/FinalFonts/SpaceGrotesk-Medium.ttf";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
    <Toaster position="top-right" />
  </BrowserRouter>
  //  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
