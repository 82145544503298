import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { API } from "../../API";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";
import logo from "../../images/newLogo.svg";
import homeIMg from "../../images/zeropaper.webp";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { setAuth, setUserRole } = useAuth();
  const { auth } = useAuth();
  useEffect(() => {
    if (auth?.user) {
      auth.user.userType === "ADMIN"
        ? navigate("/admin-dashboard")
        : auth.user.userType === "USER"
        ? navigate("/user-dashboard")
        : navigate("/customer-care-dashboard");
    }
  }, [auth?.user, navigate]);
  const onSubmit = async (data) => {
    try {
      const convertdata = convertObjectToBinary(data);
      const login = await API.post("/auth/signin", { obj: convertdata });
      const response = login?.data;
      // console.log("=response", response);
      if (response?.success) {
        localStorage.setItem("user", JSON.stringify(response.user));
        localStorage.setItem("authToken", response.authToken);
        const user = response?.user;
        const role = response?.user?.userType;
        const accessToken = response?.authToken;
        setUserRole([role]);
        setAuth({ user: user, role: [role], accessToken: accessToken });
        toast.success(response.message);
        role === "ADMIN"
          ? navigate("/admin-dashboard")
          : role === "USER"
          ? navigate("/user-dashboard")
          : navigate("/customer-care-dashboard");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      // console.log("erorr", error);
      toast.error("Network Error");
    }
  };

  function convertObjectToBinary(obj) {
    let output = "",
      input = JSON.stringify(obj); // convert the json to string.
    // loop over the string and convert each charater to binary string.
    for (let i = 0; i < input.length; i++) {
      output += input[i].charCodeAt(0).toString(3) + " ";
    }
    return output.trimEnd();
  }
  return (
    <div className="bg-white vw-100 vh-100  d-flex flex-column">
      <div className="d-flex align-items-center">
        <div>
          <img
            src={logo}
            alt="logo"
            width={100}
            height={100}
            className="mt-1"
            color="#006aa8"
          />
        </div>
        <div style={{ color: "#006aa8" }}>
          <div
            className="fw-bold"
            style={{ fontSize: "22px", fontFamily: "SpaceGrotesk-Medium" }}
          >
            ZeroPaper
          </div>
          <div style={{ fontSize: "14px", fontFamily: "Proxima-Nova" }}>
            Create. Collaborate. Customize.
          </div>
        </div>
      </div>
      <div className="container flex-grow-1 d-flex justify-content-center align-items-center gap-3">
        <div>
          <img src={homeIMg} alt="logo" className="m-auto d-block mb-2 w-100" />
        </div>
        <div className="form-width m-auto ">
          <div className="shadow bg-white pt-4 pb-4 rounded">
            <div className="ps-3 pe-3 ps-md-5 pe-md-5">
              <h3
                className="fs-2 text-center title "
                style={{
                  color: "rgb(34, 34, 34)",
                  fontWeight: "700",
                  fontFamily: "SpaceGrotesk-Medium",
                }}
              >
                Welcome to ZeroPaper <br />
              </h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group ">
                  <label className="lable-fw-size">
                    Email <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    // autoFocus={false}
                    autoComplete="off"
                    className="form-control mt-1 form-fs form-label-border p-3"
                    placeholder="Enter email address"
                  />
                  {errors.email && (
                    <span className="text-danger">Email is required</span>
                  )}
                </div>

                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("password", { required: true })}
                    type="password"
                    className="form-control mt-1 form-fs form-label-border p-3"
                    placeholder="Enter Password"
                  />
                  {errors.password && (
                    <span className="text-danger">password is required</span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <div className="text-end text-primary title btn-link">
                    <Link to={"/forgot-password"}>Forgot Password ? </Link>
                  </div>
                </div>
                <div className="form-group row mt-2">
                  <div className="d-grid gap-2 mb-4 ">
                    <button
                      type="submit"
                      className="btn-gradient-primary mt-3 text-uppercase p-2 border rounded fs-5 fw-bold"
                      style={{ fontFamily: "SpaceGrotesk-Medium" }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <Toaster /> */}
      </div>
    </div>
  );
};
export default Login;
