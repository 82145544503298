import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API } from "../API";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { JsonToExcel } from "react-json-to-excel";
import { TableLoader } from "./TableLoader";
import { PaginationControl } from "react-bootstrap-pagination-control";
import noProjectGif from "../images/No_project.gif";
import { PauseCircle, PlayCircle, XCircle, Send } from "react-bootstrap-icons";
import { toast } from "react-hot-toast";
import Loading from "react-fullscreen-loading";
import { truncatedTableCell } from "../utils/helpers";

const pagelimit = 10;

const Project = () => {
  let { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [showReminderReport, setShowReminderReport] = useState(false);
  const [sendedUser, setSendedUser] = useState([]);
  const [reminderMessageSendedUser, setReminderMessageSendedUser] = useState([]);
  const [page, setPage] = useState(1);
  const [totalProjects, setTotalProjects] = useState(0);
  const [isSessionStop, setIsSessionStop] = useState(false);
  const [cancelProject, setCancelProject] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [cancelProjectId, setcancelProjectId] = useState("");
  const [qrData, setQrData] = useState("");
  const [qrModalStatus, setQrModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pageLimit, setPageLimit] = useState(10);

  const navigate = useNavigate();
  // const pagelimits = [10, 20, 30, 40, 50];

  // const getProject = React.useCallback(async () => {
  //   const getAllProject = await API.get(
  //     `/project/get?page=${page}&limit=${pageLimit}&type=${state.id}`
  //   );
  //   const response = getAllProject.data;
  //   setProjects(response.data.result);
  //   setTotalProjects(response.data.projectCount);
  //   setIsLoading(false);
  // }, [page, pageLimit]);

  // useEffect(() => {
  //   getProject();
  // }, [getProject, page, pageLimit]);

  // const handlePageLimitChange = (e) => {
  //   setPageLimit(parseInt(e.target.value));
  //   setPage(1);
  // };

  const pagelimits = [10, 20, 30, 40, 50];

  const getProject = React.useCallback(async () => {
    const getAllProject = await API.get(
      `/project/get?page=${page}&limit=${pageLimit}&type=${state.id}`
    );
    const response = getAllProject.data;
    setProjects(response.data.result);
    setTotalProjects(response.data.projectCount);
    setIsLoading(false);
  }, [page, pageLimit, totalProjects]);

  useEffect(() => {
    getProject();
  }, [getProject, page, pageLimit, totalProjects]);

  const handlePageLimitChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "All") {
      setPageLimit(totalProjects);
    } else {
      setPageLimit(selectedValue);
    }
    setPage(1);
  };

  // const navigate = useNavigate();
  // const getProject = React.useCallback(async () => {
  //   const getAllProject = await API.get(
  //     `/project/get?page=${page}&limit=${pagelimit}&type=${state.id}`
  //   );
  //   const response = getAllProject.data;
  //   setProjects(response.data.result);
  //   setTotalProjects(response.data.projectCount);
  //   setIsLoading(false);
  // }, [page]); 

  useEffect(() => {
    getProject();

    const interval = setInterval(() => {
      getProject();
      console.log(
        "=====================================interval Time out Projects tab================================"
      );
    }, 20000);

    // Clean up function to clear interval when the component unmounts
    return () => clearInterval(interval);
  }, [getProject, page]);
  const getReport = async (id) => {
    setShowReport(true);
    const { data } = await API.get(
      `/project/get-project-wise-user-list?projectId=${id}`
    );
    setSendedUser(data.data);
  };

  // console.log("projects--------------------", projects);
  // console.log("totalProjects", totalProjects);
  // console.log("pageLimit", pageLimit);

  const getReminderReport = async (id) => {
    setShowReminderReport(true);
    const { data } = await API.get(
      `/project/get-message-reminder-list?projectId=${id}`
    );
    setReminderMessageSendedUser(data.data);
  };

  const getFindUser = (project, value) => {
    if (project?.completStatus !== "NOT STARTED") {
      // console.log("?????????????",project);
      // console.log("value========",value);
      const result = project.countUser.filter((s) => s._id === value);
      if (result.length) return result[0].total;
      else return 0;
    } else {
      return "-";
    }
  };

  const handlePause = async (id, file_sending_status) => {
    const { data } = await API.post(
      `/whatsapp/fileSendingStatus?projectId=${id}`,
      {
        file_sending_status: file_sending_status,
      }
    );
    if (data.success) {
      toast.success(data.message);
      getProject();
    } else {
      toast.error("Failed to pause the project: " + data.message);
      setProjectId(id);
      setIsSessionStop(true);
    }
  };

  const handleCancel = async (id) => {
    const { data } = await API.patch(
      `/project/project-status-change?projectId=${id}`
    );
    await API.get(`/whatsapp/logout?projectId=${id}`);
    toast.success(data.message);
    setCancelProject(false);
    getProject();
  };

  let timer2;
  const getQr = async () => {
    if (!qrData) {
      setIsSessionStop(false);
      setLoading(true);
      timer2 = setInterval(async () => {
        const sesisonData = await API.get(
          `/whatsapp/issessionsaved/?projectId=${projectId}`
        );
        if (sesisonData.data.success === false) {
          const { data } = await API.get(
            `/whatsapp/getqr/?projectId=${projectId}`
          );
          setLoading(false);
          if (data.success) {
            setQrModalStatus(true);
            setQrData(data.data);
            getQrData();
          } else {
            toast.error(data.message);
          }
        }
      }, 10000);
    }
    if (qrData) {
      clearInterval(timer2);
    }
  };
  const getQrData = () => {
    return clearInterval(timer2);
  };

  let timer;
  const getScanQrCodeStatus = async () => {
    if (qrModalStatus) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(async () => {
        const { data } = await API.get(
          `/whatsapp/issessionsaved?projectId=${projectId}`
        );
        if (data.success) {
          setQrModalStatus(false);
          toast.success("Session added successfull");
        }
        // setSession(data);
      }, 10000);
    }
    if (!qrModalStatus) {
      clearInterval(timer);
    }
  };
  useEffect(() => {
    getScanQrCodeStatus();
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrModalStatus, timer]);

  // useEffect(() => {
  //   async function updateHold() {
  //     await API.patch(`/auth/user-hold-amount-release`);
  //   }
  //   updateHold();
  // }, []);

  return (
    <>
      <div className="container mt-3 container-main">
        <div className="d-flex align-items-center justify-content-between p-md-3 p-2">
          <h3 className="page-title custom-tab" onClick={() =>
                navigate("/functions")
              }>Projects { "> " + state.function}</h3>
          <div>
            <button
              type="button"
              className="btn-gradient-primary nav-font p-2 border rounded"
              onClick={() =>
                navigate("/functions/project/create-project", {
                  state: { function: state.function, id: state.id },
                })
              }
            >
              Create New
            </button>
          </div>
          <div>
            <select
              style={{
                background: "#006aa8",
                color: "white",
                padding: "5px",
                border: "rounded",
              }}
              value={pageLimit}
              onChange={handlePageLimitChange}
            >
              {pagelimits.map((limit) => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
              <option value={totalProjects}>All</option>
            </select>
          </div>
        </div>
        <div
          key={pageLimit}
          className="mt-3 bg-white rounded card-trans p-md-3 p-2"
        >
          <div
            className="table-responsive text-wrap mt-4 table-Fixed"
            style={{
              height: "600px",
              overflow: "auto",
              position: "relative",
              zIndex: 1,
              boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)",
            }}
          >
            <table className="table table-striped">
              <thead className="table">
                <tr className="text-color-primary">
                  <th scope="col " className="lable-fw-size">
                    Name
                  </th>
                  {/* <th scope="col " className="lable-fw-size">
                    Description
                  </th>   */}
                  <th scope="col " className="lable-fw-size  text-center">
                    Process
                  </th>
                  <th scope="col " className="lable-fw-size  text-center">
                    Status
                  </th>
                  {/* <th scope="col " className="lable-fw-size  text-center">
                    Reminder
                  </th> */}
                  {/* <th scope="col " className="lable-fw-size  text-center">
                    ReminderStatus
                  </th> */}
                  {/* <th scope="col " className="lable-fw-size  text-center">
                    ReminderScheduledAt
                  </th> */}
                  <th scope="col " className="lable-fw-size  text-center">
                    Success
                  </th>
                  <th scope="col " className="lable-fw-size  text-center">
                    Fail
                  </th>
                  <th scope="col " className="lable-fw-size  text-center">
                    ScheduledAt
                  </th>
                </tr>
                {/* <th scope="col " className="lable-fw-size  text-center">
                    CreatedOn
                  </th>
                  <th scope="col " className="lable-fw-size  text-center">
                    LastUpdatedOn
                  </th> */}
              </thead>

              {isLoading ? (
                <tbody className="table-bordered">
                  <tr>
                    <td colSpan={"7"}>
                      <TableLoader />
                    </td>
                  </tr>
                </tbody>
              ) : projects?.length > 0 ? (
                <>
                  <tbody className="table-bordered">
                    {projects?.map((el, key) => {
                      return (
                        <React.Fragment key={key}>
                          <tr className="lable-fw-size ">
                            <td>
                              {el.completStatus === "SUCCEED" ||
                              el.completStatus === "CANCELLED" ||
                              el.completStatus === "SESSION NOT FOUND" ||
                              el.completStatus === "SCHEDULED" ||
                              el.completStatus === "RUNNING" ? (
                                <div>{truncatedTableCell(el.name)}</div>
                              ) : (
                                <Link
                                  to={"/project/edit-project"}
                                  state={{ id: el._id }}
                                  className="btn-link"
                                >
                                  {truncatedTableCell(el.name)}
                                </Link>
                              )}
                            </td>
                            {/* <td>{truncatedTableCell(el.description)}</td> */}
                            <td className="text-center">
                              {el.completStatus === "RUNNING" &&
                              el.file_sending_status === true ? (
                                <PauseCircle
                                  size={20}
                                  color="orange"
                                  onClick={() => handlePause(el._id, false)}
                                />
                              ) : (el.completStatus === "RUNNING" ||
                                  el.completStatus === "SESSION NOT FOUND") &&
                                el.file_sending_status === false ? (
                                <>
                                  <PlayCircle
                                    size={20}
                                    color="green"
                                    onClick={() => handlePause(el._id, true)}
                                  />
                                  <span> </span>
                                  <XCircle
                                    size={20}
                                    color="red"
                                    onClick={() => {
                                      setCancelProject(true);
                                      setcancelProjectId(el._id);
                                    }}
                                  />
                                </>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="text-center">
                              {el.completStatus === "SUCCEED" ||
                              el.completStatus === "RUNNING" ||
                              el.completStatus === "FAILED" ||
                              el.completStatus === "CANCELLED" ? (
                                <>
                                  <div className="d-flex flex-sm-row flex-column align-items-center justify-content-center">
                                    {" "}
                                    <div
                                      className={`${
                                        el.completStatus === "SUCCEED"
                                          ? "text-success"
                                          : "text-danger"
                                      } badge`}
                                    >
                                      {el.completStatus}
                                    </div>
                                    <div
                                      style={{
                                        color: "#9c9fa6",
                                      }}
                                    >
                                      <img
                                        alt="doc"
                                        src={
                                          window.location.origin + "/file.png"
                                        }
                                        width={20}
                                        height={20}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => getReport(el._id)}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div
                                  className={`${
                                    el.completStatus === "NOT STARTED"
                                      ? "text-info"
                                      : "text-warning"
                                  } badge`}
                                >
                                  {el.completStatus}
                                </div>
                              )}
                            </td>
                            {/* <td className="text-center">
                              {el.completStatus !== "RUNNING" &&
                              el.completStatus !== "NOT STARTED" &&
                              el.completStatus !== "SCHEDULED" &&
                              el.completStatus !== "SESSION NOT FOUND" &&
                              (el.reminder_status === "NOT STARTED" ||
                                el.reminder_status === "SESSION NOT FOUND") ? (
                                <Link
                                  to={"/project/message-reminder"}
                                  state={{ id: el._id }}
                                  className="btn-link"
                                >
                                  <Send color="#006aa8" size={16} />
                                </Link>
                              ) : el.reminder_status === "SUCCEED" ? (
                                <div
                                  style={{
                                    color: "#9c9fa6",
                                  }}
                                >
                                  <img
                                    alt="doc"
                                    src={window.location.origin + "/file.png"}
                                    width={20}
                                    height={20}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => getReminderReport(el._id)}
                                  />
                                </div>
                              ) : (
                                "-"
                              )}
                            </td> */}
                            {/* <td className="text-center">
                              <div
                                className={`${
                                  el.reminder_status === "SUCCEED"
                                    ? "text-success"
                                    : el.reminder_status === "NOT STARTED"
                                    ? "text-info"
                                    : "text-danger"
                                } badge`}
                              >
                                {el.reminder_status}
                              </div>
                            </td> */}
                            {/* <td className="text-center">
                              {el.remider_schedule_date
                                ? moment(el.remider_schedule_date).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  )
                                : "-"}
                            </td> */}
                            <td className="text-center">
                              {getFindUser(el, true)}
                            </td>
                            <td className="text-center">
                              {getFindUser(el, false)}
                            </td>
                            <td className="text-center">
                              {el.schedule_date
                                ? moment(el.schedule_date).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  )
                                : "-"}
                            </td>
                            {/* <td className="text-center">
                              {moment(el.createdAt).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </td> */}
                            {/* <td className="text-center">
                              {moment(el.updatedAt).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </td> */}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </>
              ) : (
                <tbody className="table-bordered">
                  <td colSpan={"7"} style={{ textAlign: "center" }}>
                    <img src={noProjectGif} alt="No project" className="mt-1" />
                  </td>
                </tbody>
              )}
            </table>
          </div>
          {/* {projects?.length > 0 && (
            <div className="mt-3 zero-paggination">
              <PaginationControl
                page={page}
                between={4}
                total={totalProjects}
                limit={pagelimit}
                changePage={(page) => {
                  setPage(page);
                }}
              />
            </div>
          )} */}
        </div>
      </div>
      {showReport && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showReport}
          onHide={() => setShowReport(false)}
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto fw-bold">
              Project Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-end">
              <JsonToExcel
                title="Export Excel"
                data={sendedUser.filter((s) => {
                  let _data = s;
                  delete _data._id;
                  return _data;
                })}
                fileName="master"
                btnClassName="btn-gradient-primary rounded nav-font d-inline px-3 py-1"
                btnColor="#006aa8"
              />
            </div>
            <div
              className="table-responsive text-wrap mt-5"
              style={{
                height: "350px",
                overflowY: "auto",
                position: "relative",
                zIndex: 1,
              }}
            >
              <table className="table table-responsive table-striped table-bordered table-fixed">
                <thead className="table">
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th className="text-center">Send Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sendedUser.map((value, index) => {
                    return (
                      <tr key={index} className="lable-fw-size">
                        <td>{value.name}</td>
                        <td>{value.number}</td>
                        <td className="text-center">
                          {value.status ? (
                            <img
                              alt="success"
                              src={window.location.origin + "/checkmark.png"}
                              width={20}
                              height={20}
                            />
                          ) : (
                            <img
                              alt="fail"
                              src={window.location.origin + "/close.png"}
                              width={20}
                              height={20}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showReminderReport && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showReminderReport}
          onHide={() => setShowReminderReport(false)}
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto fw-bold">
              Reminder Message Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-end">
              <JsonToExcel
                title="Export Excel"
                data={reminderMessageSendedUser.filter((s) => {
                  let _data = s;
                  delete _data._id;
                  return _data;
                })}
                fileName="Reminder_Report"
                btnClassName="btn-gradient-primary rounded nav-font d-inline px-3 py-1 "
                btnColor="#006aa8"
              />
            </div>
            <div
              className="table-responsive text-wrap mt-5"
              style={{
                height: "350px",
                overflowY: "auto",
                position: "relative",
                zIndex: 1,
              }}
            >
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th className="text-center">Reminder Status</th>
                  </tr>
                </thead>
                <tbody>
                  {reminderMessageSendedUser.map((value, index) => {
                    return (
                      <tr key={index} className="lable-fw-size">
                        <td>{value.name}</td>
                        <td>{value.number}</td>
                        <td className="text-center">
                          {value.reminder_message_status ? (
                            <img
                              alt="success"
                              src={window.location.origin + "/checkmark.png"}
                              width={20}
                              height={20}
                            />
                          ) : (
                            <img
                              alt="fail"
                              src={window.location.origin + "/close.png"}
                              width={20}
                              height={20}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {isSessionStop && (
        <Modal
          size="sm"
          show={isSessionStop}
          onHide={() => setIsSessionStop(false)}
          centered
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto page-title">
              Session Not Found
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <div className="fw-bold">Scan Qr Code</div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn-light fw-bold  border rounded"
              onClick={() => getQr()}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn-gradient-primary fw-bold border rounded "
              onClick={() => setIsSessionStop(false)}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {cancelProject && (
        <Modal
          size="sm"
          show={cancelProject}
          onHide={() => setCancelProject(false)}
          centered
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto page-title">
              Cancel Project
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <div className="fw-bold">Are you sure?</div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn-light fw-bold  border rounded"
              onClick={() => handleCancel(cancelProjectId)}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn-gradient-primary fw-bold border rounded "
              onClick={() => setCancelProject(false)}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {qrModalStatus && (
        <Modal
          show={qrModalStatus}
          backdrop="static"
          centered
          className="qrmodal"
        >
          <Modal.Body>
            <div className="m-3">
              <div
                style={{
                  width: "264px",
                  height: "264px",
                  background: `url(${qrData})`,
                  // backgroundSize: "cover",
                  // backgroundPosition: "center",
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}

      {loading && (
        <div>
          <Loading loading={loading} loaderColor="#24292f" />
        </div>
      )}
    </>
  );
};
export default Project;
