import React, { useEffect, useRef, useState } from "react";
import { TypeBold, TypeItalic, EmojiSmile } from "react-bootstrap-icons";
import EmojiPicker from "emoji-picker-react";
import { useLocation } from "react-router-dom";
import { API } from "../API";
import { MessageReminderModel } from "./MessageReminderModel";
import { toast } from "react-hot-toast";

function MessageReminder() {
  let { state } = useLocation();
  const [project, setProject] = useState({});
  const [text, setText] = useState("");
  const textareaRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [variables, setvariables] = useState([]);
  const [scheduleDate, setScheduledDate] = useState(null);
  const minDate = new Date().toISOString().slice(0, 16);

  const currentDate = new Date();
  const maxDate = new Date(currentDate.getTime() + 3 * 7 * 24 * 60 * 60 * 1000); // Add 3 weeks in milliseconds

  // Format the maximum date in ISO 8601 format
  const formattedMaxDate = maxDate.toISOString().slice(0, 16);

  const handleScheduleDateChange = (e) => {
    setScheduledDate(e.target.value);
  };

  useEffect(() => {
    const getProject = async () => {
      const res = await API.get(`/project/get/${state.id}`);
      if (res.data.success) {
        setProject(res.data.data);
        setText(
          res.data.data.reminder_message ? res.data.data.reminder_message : ""
        );
        const arry = ["name"];
        for (let i = 1; i <= res.data.data.variable_length; i++) {
          const varKey = "var" + i;
          arry.push(varKey);
        }
        setvariables(arry);
      }
    };
    getProject();
  }, [state.id]);

  const onSubmit = async () => {
    const res = await API.put(`/project/set-reminder-message/${state.id}`, {
      reminderMessage: text,
      scheduledDate: scheduleDate ? Date.parse(scheduleDate) : "",
    });
    if (res.data.success) {
      setShowModal(true);
    } else {
      toast.error(res.data.message);
    }
  };

  const handleOptionClick = (option) => {
    const textarea = textareaRef.current;
    const cursorPosition = textarea.selectionStart;

    const beforeCursor = text.charAt(cursorPosition - 1);
    const afterCursor = text.charAt(cursorPosition);

    const spaceBefore =
      beforeCursor === " " || beforeCursor === "" || beforeCursor === "\n";
    const spaceAfter =
      afterCursor === " " || afterCursor === "" || afterCursor === "\n";

    const spaceToAdd = spaceBefore
      ? spaceAfter
        ? ""
        : " "
      : spaceAfter
      ? " "
      : "  ";

    const newText =
      text.slice(0, cursorPosition) +
      spaceToAdd +
      option +
      spaceToAdd +
      text.slice(cursorPosition);
    setText(newText);

    // Move the cursor to the end of the inserted option
    textarea.selectionStart =
      cursorPosition + spaceToAdd.length + option.length;
    textarea.selectionEnd = cursorPosition + spaceToAdd.length + option.length;
    textarea.focus();
    handleTextAreaChange({ target: { value: newText } });
  };

  const handleTextAreaChange = (event) => {
    const newValue = event.target.value;
    setText(newValue);
  };

  const handleBoldClick = () => {
    const textarea = textareaRef.current;
    const selectedText = textarea.value.substring(
      textarea.selectionStart,
      textarea.selectionEnd
    );

    // Check if the selected text contains italic formatting
    if (!selectedText.match(/^_.*_$/)) {
      const newText = `${text.substring(
        0,
        textarea.selectionStart
      )}*${selectedText}*${text.substring(textarea.selectionEnd)}`;
      setText(newText);
      handleTextAreaChange({ target: { value: newText } });
    }
  };

  const handleItalicClick = () => {
    const textarea = textareaRef.current;
    const selectedText = textarea.value.substring(
      textarea.selectionStart,
      textarea.selectionEnd
    );

    // Check if the selected text contains bold formatting
    if (!selectedText.match(/^\*.*\*$/)) {
      const newText = `${text.substring(
        0,
        textarea.selectionStart
      )}_${selectedText}_${text.substring(textarea.selectionEnd)}`;
      setText(newText);
      handleTextAreaChange({ target: { value: newText } });
    }
  };

  const handleEmojiClick = (emojiData) => {
    const textarea = textareaRef.current;
    const cursorPosition = textarea.selectionStart;

    const newText =
      text.slice(0, cursorPosition) +
      emojiData.emoji +
      text.slice(cursorPosition);
    setText(newText);

    handleTextAreaChange({ target: { value: newText } });

    textarea.selectionStart = cursorPosition + emojiData.emoji.length;
    textarea.selectionEnd = cursorPosition + emojiData.emoji.length;
    textarea.focus();
  };

  const handleModal = (val) => {
    setShowModal(val);
  };

  // const applyValuesToPlaceholders = async () => {
  //   let string_result = text;

  //   for (const key in element) {
  //     if (element.hasOwnProperty(key)) {
  //       const regex = new RegExp(`\\$\\{${key}\\}`, "g");
  //       string_result = string_result.replace(regex, element[key]);
  //     }
  //   }
  //   console.log("message", string_result);

  //   setText(string_result);
  // };

  return (
    <>
      <div className="container mt-4 container-main">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="page-title">Reminder Message</h3>
        </div>
        <div className="col-md-12">
          <div className="form-group mt-3">
            <label className="lable-fw-size">Message</label>
            <textarea
              ref={textareaRef}
              id="w3review"
              className="form-control mt-1 form-fs form-label-border p-3"
              rows="3"
              cols="50"
              name="customMsg"
              onChange={handleTextAreaChange}
              value={text}
            />
            <div className="d-flex justify-content-between">
              <div className="mt-3 d-flex flex-sm-row flex-column">
                {variables?.map((s, index) => {
                  return (
                    s !== "number" &&
                    s !== "_id" &&
                    s !== "userId" && (
                      <div
                        key={index}
                        className="me-2 p-md-2 rounded form-fs mt-2 mt-sm-0"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#e2e6ea",
                        }}
                        onClick={() => handleOptionClick(`\${${s}}`)}
                      >
                        {" "}
                        {s} {""}
                      </div>
                    )
                  );
                })}
              </div>

              <div className="mt-3 d-flex flex-sm-row flex-column">
                <TypeBold
                  size={30}
                  color={"#006aa8"}
                  onClick={handleBoldClick}
                />
                <TypeItalic
                  color={"#006aa8"}
                  size={30}
                  onClick={handleItalicClick}
                />
                <EmojiSmile
                  color={"#f98f03"}
                  size={30}
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                />
              </div>
            </div>
            {showEmojiPicker && (
              <div className="mt-2">
                <EmojiPicker onEmojiClick={handleEmojiClick} />{" "}
              </div>
            )}
          </div>

          <div className="mt-3 d-flex justify-content-between">
            <div className="mt-3">
              <label className="lable-fw-size">Schedule</label>
              <input
                type="datetime-local"
                className="form-control mt-1 form-fs form-label-border p-2"
                id="scheduleDate"
                aria-describedby="scheduleDate"
                name="scheduleDate"
                defaultValue={scheduleDate}
                onChange={handleScheduleDateChange}
                min={minDate}
                max={formattedMaxDate}
              />
            </div>
            <div className=" align-self-center">
              <button
                className="btn-gradient-primary nav-font border rounded next-btn-padding ms-2"
                type="submit"
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {showModal && (
          <MessageReminderModel
            handlemodal={handleModal}
            isShow={showModal}
            projectId={project._id}
          />
        )}
      </div>
    </>
  );
}

export default MessageReminder;
