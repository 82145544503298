import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { API } from "../API";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { HousesFill } from "react-bootstrap-icons";
import Card from "./Cards/PageCard";
import { DashboardTableLoader } from "./DashboardTableLoader";
import { DashboardBoxLoader } from "./DashboardBoxLoader ";
import { DashboardBoxColumnLoader } from "./DashboardBoxColumnLoader ";
import { PaginationControl } from "react-bootstrap-pagination-control";
import No_transaction from "../images/No_transaction.gif";
import { truncatedTableCell } from "../utils/helpers";
import { Link, useNavigate } from "react-router-dom";

const pagelimit = 10;

const Dashboard = () => {
  const [user, setUser] = useState({});
  const [transactionData, setTransactionData] = useState([]);
  const [isTransactionDataLoading, setIsTransactionDataLoading] =
    useState(true);
  const [projectData, setProjectData] = useState({});
  const [isProjectDataLoading, setIsProjectDataLoading] = useState(true);
  const [filterData, setFilterData] = useState({});
  const [isFilterDataLoading, setIsFilterDataLoading] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [amount, setAmount] = useState(1);
  const [rechargeStatus, setRechargeStatus] = useState(false);
  const myRef = useRef();
  const [page, setPage] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [setting, setSetting] = useState({});

  const navigate = useNavigate();
  const getAdminSetting = async () => {
    const { data } = await API.get("/admin-setting/");
    if (data.success) {
      setSetting(data.data);
    } else {
      toast.error(data.message);
    }
  };
  useEffect(() => {
    getAdminSetting();
  }, []);

  console.log("setting-------------", setting);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    myRef.current.setStartDate(moment().subtract(1, "week"));
    setUser(user);
  }, []);

  useEffect(() => {
    async function filterData() {
      if (user?._id) {
        const { data } = await API.post(`/project/get-filter-project`, {
          startDate: startDate ? startDate : moment().subtract(1, "week"),
          endDate: endDate ? endDate : moment(),
        });
        const record = data.data;
        if (record) {
          const projectDetails = record?.project;
          const successProject = projectDetails?.projects?.filter(
            (s) => s.status === "SUCCEED"
          );
          const failedProject = projectDetails?.projects?.filter(
            (s) => s.status === "FAILED"
          );
          setFilterData({
            ...record,
            total_success_project:
              successProject?.length > 0 ? successProject[0]?.count : 0,
            total_failed_project:
              failedProject?.length > 0 ? failedProject[0]?.count : 0,
          });
          setIsFilterDataLoading(false);
        } else {
          setFilterData({});
          setIsFilterDataLoading(false);
        }
      }
    }
    filterData();
  }, [endDate, startDate, user?._id]);
  useEffect(() => {
    async function fetchTranscationData() {
      if (user?._id) {
        const { data } = await API.post(
          `/credit/view-transaction/${user?._id}`,
          {
            startDate: startDate ? startDate : moment().subtract(1, "week"),
            endDate: endDate ? endDate : moment(),
            page: page,
            limit: pagelimit,
          }
        );
        if (data?.data?.transactions) {
          setTransactionData(data.data.transactions);
          setTotalTransactions(data.data.totalTransactions);
          setIsTransactionDataLoading(false);
        } else {
          setTransactionData(...{});
          setTotalTransactions(0);
          setIsTransactionDataLoading(false);
        }
      }
    }
    fetchTranscationData();
  }, [user, page, startDate, endDate]);
  useEffect(() => {
    async function getProjectHistory() {
      if (user?._id) {
        const { data } = await API.get(`/auth/user-project-history`);
        setProjectData({
          ...data.data.user_details,
          total_pdf_send: data.data.total_pdf_send,
        });
        setIsProjectDataLoading(false);
      }
    }
    getProjectHistory();
  }, [user]);

  const handleCallback = (start, end, label) => {
    myRef.current.setStartDate(start);
    myRef.current.setEndDate(end);
    setStartDate(start);
    setEndDate(end);
  };

  const onRecharge = () => {
    setRechargeStatus(true);
  };

  const downloadSmapleCsv = async () => {
    const res = await API.get(`/project/sample-xlsx`, {
      responseType: "blob",
    });
    const url = URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = url;
    link.download = `sample.xlsx`;
    link.click();
    URL.revokeObjectURL(url);
    toast.success("download Success");
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="container mt-3 container-main ">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="page-title-icon bg-gradient-primary text-white mr-2">
              <div style={{ margin: "4px" }}>
                <HousesFill />
              </div>
            </span>
            <h3 className="page-title" style={{ marginLeft: "8px" }}>
              Dashboard
            </h3>
          </div>
          <div>
            <button
              type="button"
              className="btn-gradient-primary nav-font p-2 border rounded"
              onClick={() => downloadSmapleCsv()}
            >
              Download Sample XLSX
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn-gradient-primary nav-font p-2 border rounded"
              onClick={() => onRecharge()}
            >
              Recharge Your Wallet
            </button>
          </div>
          {/* <div>
            <select className="btn-gradient-primary nav-font p-2 border rounded">
              <option selected>Create Project</option>
              {setting?.functions?.map((e, i) => (
                <>
                    <option
                      key={i}
                      className="btn-gradient-primary nav-font p-2 border rounded"
                      value={e._id}
                    >
                  <Link to={"/functions/project"} state={{ id: e._id }}>
                      {e.function}
                  </Link>
                    </option>
                </>
              ))}
            </select>
          </div> */}

          {/* <div className="btn-group">
            <button
              className="btn btn-dropdown btn-gradient-primary nav-font p-2 border rounded dropdown-toggle"
              type="button"
              id="defaultDropdown"
              onClick={toggleDropdown}
              aria-expanded={isOpen ? "true" : "false"}
            >
              Default dropdown
            </button>
            <ul
              className={`dropdown-menu ${isOpen ? "show" : ""}`}
              aria-labelledby="defaultDropdown"
            >
              {setting?.functions?.map((e, i) => (
                <Link
                  key={i}
                  to={"/functions/project"}
                  state={{ id: e._id }}
                  className="dropdown-item"
                >
                  {e.function}
                </Link>
              ))}
            </ul>
          </div> */}

          <div className="dropdown">
            <button
              className="btn btn-dropdown btn-gradient-primary nav-font p-2 border rounded dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              onClick={toggleDropdown}
              aria-haspopup="true"
              aria-expanded={isOpen ? "true" : "false"}
            >
              Create Project
            </button>
            <div
              className={`dropdown-menu ${isOpen ? "show" : ""}`}
              aria-labelledby="dropdownMenuButton"
            >
              {setting?.functions?.map((e, i) => (
                <Link
                  key={i}
                  to={"/functions/project/create-project"}
                  state={{ id: e._id, function: e.function }}
                  className="dropdown-item"
                >
                  {e.function}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className="wrap-content mt-2">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div>
                {/* <div className="form-group md-4 fw-bold">Search</div> */}
                <div className="form-group md-4 mt-2">
                  <DateRangePicker ref={myRef} onCallback={handleCallback}>
                    <input className="form-control nav-font" />
                  </DateRangePicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {isFilterDataLoading ? (
            <DashboardBoxLoader />
          ) : (
            <>
              <Card
                title={"Total Project"}
                value={
                  filterData?.project?.total_project
                    ? filterData?.project?.total_project
                    : 0
                }
                color={"bg-color-pink"}
              />

              <Card
                title={"Success"}
                value={
                  filterData?.total_success_project
                    ? filterData?.total_success_project
                    : 0
                }
                color={"bg-color-lightblue "}
              />
              <Card
                title={"Failed"}
                value={
                  filterData?.total_failed_project
                    ? filterData?.total_failed_project
                    : 0
                }
                color={"bg-color-green"}
              />
              <Card
                title={"Total Credit Used"}
                value={
                  filterData?.total_credit_used
                    ? ` ₹ ${filterData?.total_credit_used}`
                    : 0
                }
                color={"bg-color-danger"}
              />
            </>
          )}
        </div>
        <div className="row mt-3">
          <div className="col-xl-9">
            <div className="bg-white rounded card-trans">
              <div className="md-p-5 p-4 card-body">
                <div className="fw-bold" style={{ fontSize: "23px" }}>
                  Transaction
                </div>
                <div>
                  <div
                    className="table-responsive text-wrap mt-4 table-Fixed"
                    style={{
                      height: "500px",
                      overflow: "auto",
                      position: "relative",
                      zIndex: 1,
                      boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)",
                    }}
                  >
                    <table className="table table-fixed">
                      <thead className="table">
                        <tr className="text-color-primary align-center">
                          <th
                            scope="col"
                            className="lable-fw-size bg-color-secondary"
                          >
                            Added By
                          </th>
                          <th scope="col" className="lable-fw-size">
                            Account Name
                          </th>
                          <th scope="col" className="lable-fw-size">
                            Balance
                          </th>
                          <th scope="col" className="lable-fw-size">
                            Type
                          </th>
                          <th scope="col" className="lable-fw-size">
                            Description
                          </th>
                          <th scope="col" className="lable-fw-size">
                            Created Date
                          </th>
                        </tr>
                      </thead>

                      {isTransactionDataLoading ? (
                        <tbody className="table-bordered">
                          <tr>
                            <td colSpan={"5"}>
                              <DashboardTableLoader />
                            </td>
                          </tr>
                        </tbody>
                      ) : transactionData.length > 0 ? (
                        <tbody className="table-bordered">
                          {transactionData?.map((el, key) => {
                            return (
                              <React.Fragment key={key}>
                                <tr className="lable-fw-size">
                                  <td>{el?.addedBy?.fullName}</td>
                                  <td>{el?.user?.fullName}</td>
                                  <td>{el.balance}</td>
                                  <td>
                                    {el.type === "Credit" ? (
                                      <div className="text-success">
                                        {el.type}
                                      </div>
                                    ) : el.type === "Debit" ? (
                                      <div className="text-danger">
                                        {el.type}
                                      </div>
                                    ) : el.type === "Hold" ? (
                                      <div className="text-info">{el.type}</div>
                                    ) : (
                                      <div className="text-warning">
                                        {el.type}
                                      </div>
                                    )}
                                  </td>
                                  <td>{truncatedTableCell(el.description)}</td>
                                  <td>
                                    {moment(el.createdAt).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}{" "}
                        </tbody>
                      ) : (
                        <tbody className="table-bordered">
                          <td colSpan={"7"} style={{ textAlign: "center" }}>
                            <img
                              src={No_transaction}
                              alt="No Transaction"
                              className="mt-1"
                              width={"45%"}
                            />
                          </td>
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="mt-3 zero-paggination">
                    <PaginationControl
                      style={{ color: "red" }}
                      page={page}
                      between={4}
                      total={totalTransactions}
                      limit={pagelimit}
                      changePage={(page) => {
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 mt-xl-0 mt-4">
            <div className="d-flex flex-column mt-md-0 mt-3 mb-md-0 mb-4">
              {isProjectDataLoading ? (
                <DashboardBoxColumnLoader />
              ) : (
                <>
                  <div
                    className="border  border-color-primary rounded border-2 card-trans"
                    style={{ height: "134px" }}
                  >
                    <div className="d-flex flex-column static-card-padding">
                      <h5>Total Project</h5>
                      <div className="mt-2">
                        {projectData?.total_project
                          ? projectData?.total_project
                          : 0}
                      </div>
                    </div>
                  </div>

                  <div
                    className="border  border-color-primary rounded border-2 card-trans mt-4"
                    style={{ height: "134px" }}
                  >
                    <div className="d-flex flex-column static-card-padding">
                      <h5>Total Balance</h5>
                      <div className="mt-2">
                        {projectData?.total_balance
                          ? projectData?.total_balance
                          : 0}
                      </div>
                    </div>
                  </div>
                  <div
                    className="border  border-color-primary rounded border-2 card-trans mt-4"
                    style={{ height: "134px" }}
                  >
                    <div className="d-flex flex-column static-card-padding">
                      <h5>Hold Balance</h5>
                      <div className="mt-2">
                        {projectData?.hold_balance
                          ? projectData?.hold_balance
                          : 0}
                      </div>
                    </div>
                  </div>
                  <div
                    className="border  border-color-primary rounded border-2 card-trans mt-4"
                    style={{ height: "134px" }}
                  >
                    <div className="d-flex flex-column static-card-padding">
                      <h5>Total Pdf Send</h5>
                      <div className="mt-2">
                        {projectData?.total_pdf_send
                          ? projectData?.total_pdf_send
                          : 0}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-xl-4"></div>
        </div>
      </div>
      <div className="col-ms-3"></div>
      {
        <Modal
          show={rechargeStatus}
          onHide={() => setRechargeStatus(false)}
          backdrop={false}
          style={{ position: "fixed", top: 0, left: 0 }}
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto page-title">
              Recharge Your wallet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group md-4">
              <label className="lable-fw-size">
                Amount <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="number"
                min={1}
                className="form-control form-fs form-label-border mt-1 "
                defaultValue={amount}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-light fw-bold p-2 border rounded"
              onClick={() => setRechargeStatus(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-gradient-primary fw-bold p-2 border rounded"
              onClick={async () => {
                const { data } = await API.post(
                  `recharge-request/add-recharge-request`,
                  {
                    amount: amount,
                  }
                );
                if (data.success) {
                  toast.success(data.message);
                  setRechargeStatus(false);
                } else {
                  toast.error("Network Error");
                }
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      }
      {/* <Toaster /> */}
    </>
  );
};
export default Dashboard;
