import React, { useCallback, useEffect, useState } from "react";
import Draggable from "react-draggable";
export const TextComponent = ({
  text,
  width,
  height,
  inputRef,
  mode,
  size,
  fontFamily,
  positionTop,
  positionLeft,
  // onChangeText,
  handleMouseDown,
  handleMouseMove,
  handleMouseOut,
  handleMouseUp,
  lineHeight,
  color,
}) => {
  return (
    <>
      <div
        ref={inputRef}
        onMouseDown={handleMouseDown}
        // onMouseMove={handleMouseMove}
        // onMouseUp={handleMouseUp}
        // onMouseOut={handleMouseOut}
        style={{
          width: "max-content",
          border: 1,
          fontFamily,
          fontSize: size,
          lineHeight,
          // cursor: mode === "command" ? "move" : "default",
          cursor: "move",
          top: positionTop,
          left: positionLeft,
          borderColor: "gray",
          borderStyle: "solid",
          wordWrap: "break-word",
          padding: 0,
          position: "absolute",
        }}
      >
        <span
          className={
            color === "BHUJ-UNICODE"
              ? "font-face-bu"
              : color === "Surat-Unicode"
              ? "font-face-su "
              : color === "Shruti"
              ? "font-face-shruti"
              : color === "Padmaa"
              ? "font-face-padmaa"
              : color === "Rekha"
              ? "font-face-rekha"
              : color === "CourierBold"
              ? "font-face-courierBold"
              : color === "CourierNew"
              ? "font-face-courierNew"
              : color === "Ekatra-B"
              ? "font-face-ekatra-B"
              : color === "Helvetica-Bold"
              ? "font-face-helvetica-Bold"
              : color === "Helvetica-BoldOblique"
              ? "font-face-helvetica-BoldOblique"
              : color === "Helvetica"
              ? "font-face-helvetica"
              : color === "HindVadodara-Bold"
              ? "font-face-hindVadodara-Bold"
              : color === "Lohit_Gujarati"
              ? "font-face-lohit_Gujarati"
              : color === "Mogra"
              ? "font-face-mogra"
              : color === "MuktaVaani-Bold"
              ? "font-face-muktaVaani-Bold"
              : color === "MuktaVaani-ExtraBold"
              ? "font-face-muktaVaani-ExtraBold"
              : color === "RAJKOT-UNICODE"
              ? "font-face-RAJKOT-UNICODE"
              : ""
          }
          onMouseDown={handleMouseDown}
          style={{
            width: "100%",
            borderStyle: "none",
            borderWidth: 0,
            fontFamily,
            fontSize: `${size}px`,
            outline: "none",
            // padding: "5px 10px",
            boxSizing: "border-box",
            lineHeight,
            height,
            margin: 0,
            backgroundColor: "transparent",
            cursor: mode === "command" ? "move" : "text",
            color: `rgb(${color.r},${color.g},${color.b})`,
          }}
        >
          {Object.values(text)[0]}
        </span>
      </div>
    </>
  );
};
