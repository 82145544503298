import React from "react";

function PreviewText({ fontFamily, size, color, text }) {
  // console.log("=color", color);
  return (
    <div>
      <span
        className={
          color === "BHUJ-UNICODE"
            ? "font-face-bu"
            : color === "Surat-Unicode"
            ? "font-face-su "
            : color === "Shruti"
            ? "font-face-shruti"
            : color === "Padmaa"
            ? "font-face-padmaa"
            : color === "Rekha"
            ? "font-face-rekha"
            : color === "CourierBold"
            ? "font-face-courierBold"
            : color === "CourierNew"
            ? "font-face-courierNew"
            : color === "Ekatra-B"
            ? "font-face-ekatra-B"
            : color === "Helvetica-Bold"
            ? "font-face-helvetica-Bold"
            : color === "Helvetica-BoldOblique"
            ? "font-face-helvetica-BoldOblique"
            : color === "Helvetica"
            ? "font-face-helvetica"
            : color === "HindVadodara-Bold"
            ? "font-face-hindVadodara-Bold"
            : color === "Lohit_Gujarati"
            ? "font-face-lohit_Gujarati"
            : color === "Mogra"
            ? "font-face-mogra"
            : color === "MuktaVaani-Bold"
            ? "font-face-muktaVaani-Bold"
            : color === "MuktaVaani-ExtraBold"
            ? "font-face-muktaVaani-ExtraBold"
            : color === "RAJKOT-UNICODE"
            ? "font-face-RAJKOT-UNICODE"
            : ""
        }
        style={{
          width: "100%",
          borderStyle: "none",
          borderWidth: 0,
          fontFamily,
          fontSize: `${size}px`,
          outline: "none",
          padding: "5px 10px",
          boxSizing: "border-box",
          margin: 0,
          backgroundColor: "transparent",
          color: `rgb(${color.r},${color.g},${color.b})`,
        }}
      >
        {text}
      </span>
    </div>
  );
}

export default PreviewText;
