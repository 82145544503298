import React from "react";
import { useForm } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { API } from "../API";

const ForgotPassword = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const result = await API.post("/auth/forgot-password", data);
      if (result.data.success) {
        navigate("/login");
        toast.success(result.data.message);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {}
  };
  return (
    <div>
      <div className="container vw-100 vh-100 d-flex justify-content-center align-items-center">
        <div className="form-width m-auto">
          <div className="shadow bg-white pt-4 pb-4 rounded">
            <div className="ps-3 pe-3 ps-md-5 pe-md-5">
              <h3
                className="fs-2 text-center title"
                style={{ color: "rgb(34, 34, 34)", fontWeight: "700" }}
              >
                Forgot Password
              </h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="lable-fw-size text-center mt-3 subtitle-color">
                  Please enter the email associated with your account and we'll
                  send an email with instructions to reset your password.
                </div>
                <div className="form-group mt-2">
                  <label className="lable-fw-size">
                    Email <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    className="form-control mt-1 form-fs form-label-border p-3"
                    placeholder="Enter email address"
                  />
                  {errors.email && (
                    <span className="text-danger">Email is required</span>
                  )}
                </div>

                <div className="form-group row mt-2">
                  <div className="d-grid gap-2 mt-2 mb-4">
                    <button
                      type="submit"
                      className="btn-gradient-primary mt-3 text-uppercase border rounded p-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <Toaster /> */}
    </div>
  );
};

export default ForgotPassword;
