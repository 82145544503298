import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../API";
import { ArrowLeft } from "react-bootstrap-icons";

const AddCredit = () => {
  let { state } = useLocation();
  const [user, setUser] = React.useState();

  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const getUser = React.useCallback(async () => {
    const user = await API.get(`/auth/user/${state.id}`);
    const response = user.data;
    setUser(response.data);
  }, [state.id]);

  React.useEffect(() => {
    getUser();
  }, [getUser]);

  const onSubmit = async (data) => {
    data.user = user._id;
    data.addby = loggedUser._id;
    const addCredit = await API.post("/credit/add", data);
    const response = addCredit.data;
    if (response.success) {
      navigate("/manage-user");
      toast.success(response.message);
    } else toast.error(response.message);
  };
  return (
    <>
      <div className="container mt-3 container-main">
        <h3 className="page-title">Add Credit</h3>
        <div>
          <button
            style={{
              cursor: "pointer",
              backgroundColor: "#e2e6ea",
              border: "none",
            }}
            className="nav-font p-2 m-auto rounded mt-2"
            onClick={() => navigate("/manage-user")}
          >
            {" "}
            <ArrowLeft size={20} />
          </button>
        </div>

        <div className="card-trans">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-9 p-4 bg-white rounded">
              <div
                className="mb-2"
                style={{ color: "#9c9fa6", fontSize: "14px" }}
              >
                {user?.fullName} ({user?.email})
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Amount <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("amount", { required: true })}
                    type="number"
                    onKeyDown={(e) =>
                      ["e", "E", "+"].includes(e.key) && e.preventDefault()
                    }
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Amount"
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Description <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("description", { required: true })}
                    type="text"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Description"
                  />
                </div>
                <button
                  type="submit"
                  className="border rounded btn-gradient-primary mt-4 p-2"
                >
                  Add Credit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddCredit;
