import React, { useEffect, useState } from "react";
import { API } from "../API";
import { toast } from "react-hot-toast";
import noProjectGif from "../images/No_project.gif";
import { TableLoader } from "./TableLoader";
import moment from "moment";

const ArchiveProjects = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getAdminSetting = async () => {
    const { data } = await API.get("/project/get-delete");
    if (data.success) {
      setData(data.data.projectCounts);
      setIsLoading(false);
    } else {
      toast.error(data.message);
    }
  };
  useEffect(() => {
    getAdminSetting();
  }, []);

  return (
    <>
      <div className="container mt-3 container-main">
        <div className="d-flex align-items-center justify-content-between p-md-5 p-2">
          <h3 className="page-title">Projects </h3>
        </div>
        <div
          className="table-responsive text-wrap mt-4 table-Fixed"
          style={{
            height: "600px",
            overflow: "auto",
            position: "relative",
            zIndex: 1,
            boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)",
          }}
        >
          <table className="table table-striped">
            <thead className="table">
              <tr className="text-color-primary">
                <th scope="col " className="lable-fw-size ">
                  Name
                </th>
                <th scope="col " className="lable-fw-size text-center">
                  Type
                </th>
                {/* <th scope="col " className="lable-fw-size  text-center">
                  Status
                </th> */}
                {/* <th scope="col " className="lable-fw-size  text-center">
                    Success
                  </th>
                  <th scope="col " className="lable-fw-size  text-center">
                    Fail
                  </th> */}
                <th scope="col " className="lable-fw-size text-center">
                  Date
                </th>
              </tr>
            </thead>

            {isLoading ? (
              <tbody className="table-bordered">
                <tr>
                  <td colSpan={"7"}>
                    <TableLoader />
                  </td>
                </tr>
              </tbody>
            ) : data?.length > 0 ? (
              <>
                <tbody className="table-bordered ">
                  {data?.map((el, key) => {
                    return (
                      <React.Fragment key={key}>
                        <tr className="lable-fw-size ">
                          <td>
                            {el.completStatus === "SUCCEED" ||
                            el.completStatus === "CANCELLED" ||
                            el.completStatus === "SESSION NOT FOUND" ||
                            el.completStatus === "SCHEDULED" ||
                            el.completStatus === "NOT STARTED" ||
                            el.completStatus === "RUNNING" ? (
                              <div>{el.name}</div>
                            ) : (
                              // <Link
                              //   to={"/project/edit-project"}
                              //   state={{ id: el._id }}
                              //   className="btn-link"
                              // >
                              //   {truncatedTableCell(el.name)}
                              // </Link>
                              <div className="text-primary">{el.name}</div>
                            )}
                          </td>
                          <td className="text-center">{el?.zipcell?.functions?.function}</td>
                          {/* <td className="text-center">
                            {el.completStatus === "RUNNING" &&
                            el.file_sending_status === true ? (
                              <PauseCircle size={20} color="orange" />
                            ) : (el.completStatus === "RUNNING" ||
                                el.completStatus === "SESSION NOT FOUND") &&
                              el.file_sending_status === false ? (
                              <>
                                <PlayCircle size={20} color="green" />
                                <span> </span>
                                <XCircle size={20} color="red" />
                              </>
                            ) : (
                              "-"
                            )}
                          </td> */}
                          {/* <td className="text-center">
                            {el.completStatus === "SUCCEED" ||
                            el.completStatus === "RUNNING" ||
                            el.completStatus === "FAILED" ||
                            el.completStatus === "CANCELLED" ? (
                              <>
                                <div className="d-flex flex-sm-row flex-column align-items-center justify-content-center">
                                  {" "}
                                  <div
                                    className={`${
                                      el.completStatus === "SUCCEED"
                                        ? "text-success"
                                        : "text-danger"
                                    } badge`}
                                  >
                                    {el.completStatus}
                                  </div>
                                  <div
                                    style={{
                                      color: "#9c9fa6",
                                    }}
                                  >
                                    <img
                                      alt="doc"
                                      src={window.location.origin + "/file.png"}
                                      width={20}
                                      height={20}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div
                                className={`${
                                  el.completStatus === "NOT STARTED"
                                    ? "text-info"
                                    : "text-warning"
                                } badge`}
                              >
                                {el.completStatus}
                              </div>
                            )}
                          </td> */}
                          {/* <td className="text-center">
                              {el.completStatus !== "RUNNING" &&
                              el.completStatus !== "NOT STARTED" &&
                              el.completStatus !== "SCHEDULED" &&
                              el.completStatus !== "SESSION NOT FOUND" &&
                              (el.reminder_status === "NOT STARTED" ||
                                el.reminder_status === "SESSION NOT FOUND") ? (
                                <Link
                                  to={"/project/message-reminder"}
                                  state={{ id: el._id }}
                                  className="btn-link"
                                >
                                  <Send color="#006aa8" size={16} />
                                </Link>
                              ) : el.reminder_status === "SUCCEED" ? (
                                <div
                                  style={{
                                    color: "#9c9fa6",
                                  }}
                                >
                                  <img
                                    alt="doc"
                                    src={window.location.origin + "/file.png"}
                                    width={20}
                                    height={20}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => getReminderReport(el._id)}
                                  />
                                </div>
                              ) : (
                                "-"
                              )}
                          </td> */}
                          {/* <td className="text-center">
                              <div
                                className={`${
                                  el.reminder_status === "SUCCEED"
                                    ? "text-success"
                                    : el.reminder_status === "NOT STARTED"
                                    ? "text-info"
                                    : "text-danger"
                                } badge`}
                              >
                                {el.reminder_status}
                              </div>
                            </td> */}
                          {/* <td className="text-center">
                              {el.remider_schedule_date
                                ? moment(el.remider_schedule_date).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  )
                                : "-"}
                            </td> */}
                          <td className="text-center">
                            {el.wDate
                              ? moment(el.wDate).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                          {/* <td className="text-center">
                              {moment(el.createdAt).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </td> */}
                          {/* <td className="text-center">
                              {moment(el.updatedAt).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </td> */}
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </>
            ) : (
              <tbody className="table-bordered">
                <td colSpan={"7"} style={{ textAlign: "center" }}>
                  <img src={noProjectGif} alt="No project" className="mt-1" />
                </td>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default ArchiveProjects;
