import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { API } from "../API";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Toaster } from "react-hot-toast";
import { HousesFill } from "react-bootstrap-icons";
import Card from "./Cards/PageCard";
import { DashboardTableLoader } from "./DashboardTableLoader";
import { DashboardBoxLoader } from "./DashboardBoxLoader ";
import { DashboardBoxColumnLoader } from "./DashboardBoxColumnLoader ";
import { PaginationControl } from "react-bootstrap-pagination-control";
import "bootstrap/dist/css/bootstrap.min.css";
import { truncatedTableCell } from "../utils/helpers";

const pagelimit = 10;

const AdminDashboard = () => {
  const [user, setUser] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [isTransactionDataLoading, setIsTransactionDataLoading] =
    useState(true);
  const [filterData, setFilterData] = useState({});
  const [isFilterDataLoading, setIsFilterDataLoading] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const myRef = useRef();
  const [page, setPage] = useState(1);
  const [totalProjects, setTotalProjects] = useState(0);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    myRef.current.setStartDate(moment().subtract(1, "week"));
    setUser(user);
  }, []);

  useEffect(() => {
    async function filterData() {
      if (user?._id) {
        const { data } = await API.post(`/project/admin-dashboard`, {
          startDate: startDate ? startDate : moment().subtract(1, "week"),
          endDate: endDate ? endDate : moment(),
        });
        const record = data.data;
        if (record) {
          const projectDetails = record?.project;
          const successProject = projectDetails?.projects?.filter(
            (s) => s.status === "SUCCEED"
          );
          const failedProject = projectDetails?.projects?.filter(
            (s) => s.status === "FAILED"
          );
          const runningProject = projectDetails?.projects?.filter(
            (s) => s.status === "RUNNING"
          );
          const notStartedProject = projectDetails?.projects?.filter(
            (s) => s.status === "NOT STARTED"
          );
          const cancelledProject = projectDetails?.projects?.filter(
            (s) => s.status === "CANCELLED"
          );
          setFilterData({
            ...record,
            total_success_project:
              successProject?.length > 0 ? successProject[0]?.count : 0,
            total_failed_project:
              failedProject?.length > 0 ? failedProject[0]?.count : 0,
            total_running_project:
              runningProject?.length > 0 ? runningProject[0]?.count : 0,
            total_not_stated_project:
              notStartedProject?.length > 0 ? notStartedProject[0]?.count : 0,
            total_cancelled_project:
              cancelledProject?.length > 0 ? cancelledProject[0]?.count : 0,
          });
          setIsFilterDataLoading(false);
        } else {
          setFilterData({});
          setIsFilterDataLoading(false);
        }
      }
    }
    filterData();
  }, [endDate, startDate, user?._id]);
  useEffect(() => {
    async function fetchTranscationData() {
      if (user?._id) {
        const { data } = await API.post(`/credit/admin-dashboard-transaction`, {
          startDate: startDate ? startDate : moment().subtract(1, "week"),
          endDate: endDate ? endDate : moment(),
          page: page,
          limit: pagelimit,
        });
        setTransactionData(data.data.result);
        setTotalProjects(data.data.totalproject);
        setIsTransactionDataLoading(false);
      }
    }
    fetchTranscationData();
  }, [endDate, startDate, user, page]);

  useEffect(() => {
    async function fetchUsers() {
      if (user?._id) {
        const { data } = await API.get(`/auth/admin-dashboard-details`);
        setUsersData(data.data);
      }
    }
    fetchUsers();
  }, [user]);

  const handleCallback = (start, end, label) => {
    myRef.current.setStartDate(start);
    myRef.current.setEndDate(end);
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <div className="container mt-3 container-main ">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="page-title-icon bg-gradient-primary text-white mr-2">
              <div style={{ margin: "4px" }}>
                <HousesFill />
              </div>
            </span>
            <h3 className="page-title" style={{ marginLeft: "8px" }}>
              Dashboard
            </h3>
          </div>
        </div>
        <div>
          <div className="wrap-content mt-2">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div>
                {/* <div className="form-group md-4 fw-bold">Search</div> */}
                <div className="form-group md-4 mt-2">
                  <DateRangePicker ref={myRef} onCallback={handleCallback}>
                    <input className="form-control nav-font" />
                  </DateRangePicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {isFilterDataLoading ? (
            <DashboardBoxLoader />
          ) : (
            <>
              <Card
                title={"Total Users"}
                value={usersData?.total_users ? usersData.total_users : 0}
                secondTitle={"Total Users"}
                color={"bg-color-pink"}
              />

              <Card
                title={"Total Project"}
                value={
                  filterData?.project?.total_project
                    ? filterData?.project?.total_project
                    : 0
                }
                secondTitle={"Total Project"}
                color={"bg-color-lightblue"}
              />
              <Card
                title={"Total Success Project"}
                value={
                  filterData?.total_success_project
                    ? filterData?.total_success_project
                    : 0
                }
                secondTitle={"Total Success Project"}
                color={"bg-color-green"}
              />
              <Card
                title={"Total Not Started Project"}
                value={
                  filterData?.total_not_stated_project
                    ? filterData?.total_not_stated_project
                    : 0
                }
                secondTitle={"Total Not Started Project"}
                color={"bg-color-danger"}
              />
            </>
          )}
        </div>
        <div className="row mt-3">
          <div className="col-xl-9">
            <div className="bg-white rounded card-trans">
              <div className="md-p-5 p-4 card-body">
                <div className="fw-bold" style={{ fontSize: "23px" }}>
                  Transaction
                </div>
                <div>
                  <div
                    className="table-responsive text-wrap mt-4 table-Fixed"
                    style={{
                      height: "600px",
                      overflow: "auto",
                      position: "relative",
                      zIndex: 1,
                      boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)",
                    }}
                  >
                    <table className="table table-fixed">
                      <thead className="table">
                        <tr className="text-color-primary">
                          <th scope="col" className="lable-fw-size">
                            Added By
                          </th>
                          <th scope="col" className="lable-fw-size">
                            Account Name
                          </th>
                          <th scope="col" className="lable-fw-size">
                            Balance
                          </th>
                          <th scope="col" className="lable-fw-size">
                            Type
                          </th>
                          <th scope="col" className="lable-fw-size">
                            Description
                          </th>
                          <th scope="col" className="lable-fw-size">
                            Created Date
                          </th>
                        </tr>
                      </thead>

                      {isTransactionDataLoading ? (
                        <tbody className="table-bordered">
                          <tr>
                            <td colSpan={"5"}>
                              <DashboardTableLoader />
                            </td>
                          </tr>
                        </tbody>
                      ) : transactionData.length > 0 ? (
                        <tbody className="table-bordered">
                          {transactionData?.map((el, key) => {
                            return (
                              <React.Fragment key={key}>
                                <tr className="lable-fw-size">
                                  <td>{el?.addedBy?.fullName}</td>
                                  <td>{el?.user?.fullName}</td>
                                  <td>{el.balance}</td>
                                  <td>
                                    {el.type === "Credit" ? (
                                      <div className="text-success">
                                        {el.type}
                                      </div>
                                    ) : el.type === "Debit" ? (
                                      <div className="text-danger">
                                        {el.type}
                                      </div>
                                    ) : el.type === "Hold" ? (
                                      <div className="text-info">{el.type}</div>
                                    ) : (
                                      <div className="text-warning">
                                        {el.type}
                                      </div>
                                    )}
                                  </td>
                                  <td>{truncatedTableCell(el.description)}</td>
                                  <td>
                                    {moment(el.createdAt).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}{" "}
                        </tbody>
                      ) : (
                        <div
                          className="mt-3 container m-auto text-center"
                          style={{ padding: "50px" }}
                        >
                          <div className="fw-bold">No Record Found</div>
                        </div>
                      )}
                    </table>
                  </div>
                  <div className="mt-3 zero-paggination">
                    <PaginationControl
                      page={page}
                      between={4}
                      total={totalProjects}
                      limit={pagelimit}
                      changePage={(page) => {
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 mt-xl-0 mt-4">
            <div className="d-flex flex-column mt-md-0 mt-3 mb-md-0 mb-4">
              {isFilterDataLoading ? (
                <DashboardBoxColumnLoader />
              ) : (
                <>
                  <div
                    className="border  border-color-primary rounded border-2 card-trans"
                    style={{ height: "100px" }}
                  >
                    <div className="d-flex flex-column static-card-padding-user ">
                      <h5>Total Failed Project</h5>
                      <div className="mt-2">
                        {filterData?.total_failed_project
                          ? filterData?.total_failed_project
                          : 0}
                      </div>
                    </div>
                  </div>

                  <div
                    className="border  border-color-primary rounded border-2 card-trans mt-4"
                    style={{ height: "100px" }}
                  >
                    <div className="d-flex flex-column static-card-padding-user">
                      <h5>Total Running Projects</h5>
                      <div className="mt-2">
                        {filterData?.total_running_project
                          ? filterData?.total_running_project
                          : 0}
                      </div>
                    </div>
                  </div>
                  <div
                    className="border  border-color-primary rounded border-2 card-trans mt-4"
                    style={{ height: "100px" }}
                  >
                    <div className="d-flex flex-column static-card-padding-user">
                      <h5>Total canceled Projects</h5>
                      <div className="mt-2">
                        {filterData?.total_cancelled_project
                          ? filterData?.total_cancelled_project
                          : 0}
                      </div>
                    </div>
                  </div>
                  <div
                    className="border  border-color-primary rounded border-2 card-trans mt-4"
                    style={{ height: "100px" }}
                  >
                    <div className="d-flex flex-column static-card-padding-user">
                      <h5>Total Balance On Hold</h5>
                      <div className="mt-2">
                        {usersData?.hold_balance ? usersData.hold_balance : 0}
                      </div>
                    </div>
                  </div>
                  <div
                    className="border  border-color-primary rounded border-2 card-trans mt-4"
                    style={{ height: "100px" }}
                  >
                    <div className="d-flex flex-column static-card-padding-user">
                      <h5>Total PDF Send</h5>
                      <div className="mt-2">
                        {filterData?.total_pdf_send
                          ? filterData?.total_pdf_send
                          : 0}
                      </div>
                    </div>
                  </div>
                  <div
                    className="border  border-color-primary rounded border-2 card-trans mt-4"
                    style={{ height: "100px" }}
                  >
                    <div className="d-flex flex-column static-card-padding-user">
                      <h5>Total Balance Debited</h5>
                      <div className="mt-2">
                        {filterData?.total_credit_used
                          ? filterData?.total_credit_used
                          : 0}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-xl-4"></div>
        </div>
      </div>
      {/* <Toaster /> */}
    </>
  );
};
export default AdminDashboard;
