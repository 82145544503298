import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../API";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { PaginationControl } from "react-bootstrap-pagination-control";

const pagelimit = 10;

const ManageUser = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [users, setUsers] = React.useState();
  const [loggedUser, setLoggedUser] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserID] = useState("");
  const [totalUsersCount, setTotalUserCount] = useState(0);
  const [page, setPage] = useState(1);
  const [inputData, setInputData] = useState({
    email: "",
    fullName: "",
    phoneNumber: "",
    userType: "",
  });

  const inputEvent = (e) => {
    const { name, value } = e.target;
    setInputData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const getUser = React.useCallback(async () => {
    const getAllUser = await API.post(
      `/auth/?page=${page}&limit=${pagelimit}`,
      inputData
    );
    const response = getAllUser.data;
    setUsers(response.data.user);
    setTotalUserCount(response.data.userCount);
  }, [page, inputData]);

  const loginUser = React.useCallback(async () => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    const { data } = await API.get(`/auth/user/${loggedUser._id}`, {});
    if (data.success) {
      setLoggedUser(data.data);
    }
  }, []);
  React.useEffect(() => {
    loginUser();
    getUser();
  }, [getUser, loginUser]);
  const onDelete = async () => {
    const { data } = await API.delete(`/auth/user/${deleteUserId}`);
    if (data.success) {
      setDeleteModal(false);
      toast.success(data.message);
      getUser();
    } else {
      toast.error(data.message);
    }
  };

  const handleClose = () => setDeleteModal(false);

  return (
    <>
      <div className="container mt-3 container-main">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="page-title">Users</h3>
          {loggedUser?.userType === "ADMIN" && (
            <div>
              <button
                type="button"
                className="btn-gradient-primary nav-font p-2 border rounded"
                onClick={() => navigate("/create-user")}
              >
                {" "}
                Create New
              </button>
            </div>
          )}
        </div>
        <div
          className="mt-3 bg-white rounded card-trans p-md-4 p-3 "
          style={{
            maxHeight: "800px",
            overflow: "auto",
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="d-flex">
            <div className="form-outline p-1">
              <input
                type="search"
                id="email"
                name="email"
                onChange={inputEvent}
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div className="form-outline p-1">
              <input
                type="search"
                id="fullName"
                name="fullName"
                onChange={inputEvent}
                className="form-control"
                placeholder="Full Name"
              />
            </div>
            <div className="form-outline p-1">
              <input
                type="search"
                id="userType"
                name="userType"
                onChange={inputEvent}
                className="form-control"
                placeholder="User Type"
              />
            </div>
            <div className="form-outline p-1">
              <input
                type="search"
                id="phoneNumber"
                name="phoneNumber"
                onChange={inputEvent}
                className="form-control"
                placeholder="Phone No"
              />
            </div>
          </div>
          <div
            className="table-responsive text-wrap mt-4 table-Fixed"
            style={{ boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)" }}
          >
            <table className="table table-fixed">
              <thead className="table">
                <tr>
                  <th scope="col" className="lable-fw-size">
                    Email
                  </th>
                  <th scope="col" className="lable-fw-size">
                    FullName
                  </th>
                  <th scope="col" className="lable-fw-size">
                    Type
                  </th>
                  <th scope="col" className="lable-fw-size">
                    PhoneNumber
                  </th>
                  <th scope="col" className="lable-fw-size">
                    Balance
                  </th>
                  <th scope="col" className="lable-fw-size">
                    Charge
                  </th>
                  <th scope="col" className="lable-fw-size">
                    ReminderCharge
                  </th>
                  <th scope="col" className="lable-fw-size"></th>
                </tr>
              </thead>
              <tbody className="table-bordered">
                {users?.map((el, key) => {
                  return (
                    <React.Fragment key={key}>
                      <tr className="lable-fw-size">
                        <td>{el.email}</td>
                        <td>{el.fullName}</td>
                        <td>{el.userType}</td>
                        <td>{el.phoneNumber}</td>
                        <td>
                          {el.total_balance ? el.total_balance.toFixed(2) : 0}
                        </td>
                        <td>{el.charge}</td>
                        <td>{el.reminder_message_charge}</td>
                        <td>
                          <Link
                            to="/edit-user"
                            state={{ id: el._id }}
                            className="text-primary title btn-link"
                          >
                            Edit
                          </Link>{" "}
                          {loggedUser.userType === "ADMIN" && (
                            <>
                              |{" "}
                              <Link
                                to="/add-credit"
                                state={{ id: el._id }}
                                className="text-primary title btn-link"
                              >
                                Add Credit
                              </Link>{" "}
                            </>
                          )}
                          |{" "}
                          <Link
                            to="/view-transaction"
                            state={{ id: el._id }}
                            className="text-primary title btn-link"
                          >
                            View Transactions
                          </Link>
                          {loggedUser?.userType === "ADMIN" && (
                            <>
                              {" | "}{" "}
                              <Link
                                onClick={() => {
                                  if (user._id === el._id) {
                                    toast.error(
                                      "own account can not be deleted!"
                                    );
                                  } else {
                                    setDeleteUserID(el._id);
                                    setDeleteModal(true);
                                  }
                                }}
                                data-toggle="modal"
                                data-target="#exampleModal"
                                className="text-primary title btn-link"
                              >
                                Delete User
                              </Link>
                            </>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="mt-3 zero-paggination">
            <PaginationControl
              page={page}
              between={4}
              total={totalUsersCount}
              limit={pagelimit}
              changePage={(page) => {
                setPage(page);
              }}
            />
          </div>
        </div>
        {deleteModal && (
          <Modal show={deleteModal} onHide={handleClose} centered>
            <Modal.Header className="px-4 " closeButton>
              <Modal.Title className="ms-auto page-title">
                Delete User
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete User!</Modal.Body>
            <Modal.Footer>
              <button
                className="btn-light fw-bold p-2 border rounded"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="btn-gradient-primary fw-bold p-2 border rounded"
                onClick={onDelete}
              >
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      {/* <Toaster /> */}
    </>
  );
};
export default ManageUser;
