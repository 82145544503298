import React from "react";
import ContentLoader from "react-content-loader";

export const DashboardBoxLoader = () => {
  const Loader = (props) => {
    return (
      <ContentLoader
        speed={2}
        width={1040}
        height={130}
        primaryColor="#d9d9d9"
        secondaryColor="#ecebeb"
        {...props}

      >
        <rect x="0" y="13" rx="3" ry="3" width="290" height="110" />
        <rect x="345" y="13" rx="3" ry="3" width="290" height="110" />
        <rect x="695" y="13" rx="3" ry="3" width="290" height="110" />
        <rect x="1045" y="13" rx="3" ry="3" width="290" height="110" />
      </ContentLoader>
    );
  };

  return (
    <>
      {Array(1)
        .fill("")
        .map((e, i) => (
          <Loader key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
        ))}
    </>
  );
};
