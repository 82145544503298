import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { API } from "../API";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Toaster } from "react-hot-toast";
import { HousesFill } from "react-bootstrap-icons";
import Card from "./Cards/PageCard";
import { DashboardBoxLoader } from "./DashboardBoxLoader ";

const CustomerCareDashboard = () => {
  const [user, setUser] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [isFilterDataLoading, setIsFilterDataLoading] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const myRef = useRef();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    myRef.current.setStartDate(moment().subtract(1, "week"));
    setUser(user);
  }, []);

  useEffect(() => {
    async function filterData() {
      if (user?._id) {
        const { data } = await API.post(`/project/admin-dashboard`, {
          startDate: startDate ? startDate : moment().subtract(1, "week"),
          endDate: endDate ? endDate : moment(),
        });
        const record = data.data;
        if (record) {
          const projectDetails = record?.project;
          const successProject = projectDetails?.projects?.filter(
            (s) => s.status === "SUCCEED"
          );
          const failedProject = projectDetails?.projects?.filter(
            (s) => s.status === "FAILED"
          );
          const runningProject = projectDetails?.projects?.filter(
            (s) => s.status === "RUNNING"
          );
          const notStartedProject = projectDetails?.projects?.filter(
            (s) => s.status === "NOT STARTED"
          );
          const cancelledProject = projectDetails?.projects?.filter(
            (s) => s.status === "CANCELLED"
          );
          setFilterData({
            ...record,
            total_success_project:
              successProject?.length > 0 ? successProject[0]?.count : 0,
            total_failed_project:
              failedProject?.length > 0 ? failedProject[0]?.count : 0,
            total_running_project:
              runningProject?.length > 0 ? runningProject[0]?.count : 0,
            total_not_stated_project:
              notStartedProject?.length > 0 ? notStartedProject[0]?.count : 0,
            total_cancelled_project:
              cancelledProject?.length > 0 ? cancelledProject[0]?.count : 0,
          });
          setIsFilterDataLoading(false);
        } else {
          setFilterData({});
          setIsFilterDataLoading(false);
        }
      }
    }
    filterData();
  }, [endDate, startDate, user?._id]);

  useEffect(() => {
    async function fetchUsers() {
      if (user?._id) {
        const { data } = await API.get(`/auth/admin-dashboard-details`);
        setUsersData(data.data);
      }
    }
    fetchUsers();
  }, [user]);

  const handleCallback = (start, end, label) => {
    myRef.current.setStartDate(start);
    myRef.current.setEndDate(end);
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <div className="container mt-3 container-main ">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="page-title-icon bg-gradient-primary text-white mr-2">
              <div style={{ margin: "4px" }}>
                <HousesFill />
              </div>
            </span>
            <h3 className="page-title" style={{ marginLeft: "8px" }}>
              Dashboard
            </h3>
          </div>
        </div>
        <div>
          <div className="wrap-content mt-2">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div>
                {/* <div className="form-group md-4 fw-bold">Search</div> */}
                <div className="form-group md-4 mt-2">
                  <DateRangePicker ref={myRef} onCallback={handleCallback}>
                    <input className="form-control nav-font" />
                  </DateRangePicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {isFilterDataLoading ? (
            <DashboardBoxLoader />
          ) : (
            <>
              <Card
                title={"Total Users"}
                value={usersData?.total_users ? usersData.total_users : 0}
                secondTitle={"Total Users"}
                color={"bg-color-pink"}
              />

              <Card
                title={"Total Project"}
                value={
                  filterData?.project?.total_project
                    ? filterData?.project?.total_project
                    : 0
                }
                secondTitle={"Total Project"}
                color={"bg-color-lightblue"}
              />
              <Card
                title={"Total Success Project"}
                value={
                  filterData?.total_success_project
                    ? filterData?.total_success_project
                    : 0
                }
                secondTitle={"Total Success Project"}
                color={"bg-color-green"}
              />
              <Card
                title={"Total Not Started Project"}
                value={
                  filterData?.total_not_stated_project
                    ? filterData?.total_not_stated_project
                    : 0
                }
                secondTitle={"Total Not Started Project"}
                color={"bg-color-danger"}
              />
            </>
          )}
        </div>

        <div className="row mt-3">
          {isFilterDataLoading ? (
            <DashboardBoxLoader />
          ) : (
            <>
              <Card
                title={"Total Failed Project"}
                value={
                  filterData?.total_failed_project
                    ? filterData?.total_failed_project
                    : 0
                }
                color={"bg-color-danger"}
              />

              <Card
                title={"Total Running Projects"}
                value={
                  filterData?.total_running_project
                    ? filterData?.total_running_project
                    : 0
                }
                color={"bg-color-green"}
              />
              <Card
                title={"Total canceled Projects"}
                value={
                  filterData?.total_cancelled_project
                    ? filterData?.total_cancelled_project
                    : 0
                }
                color={"bg-color-lightblue"}
              />
              <Card
                title={"Total Balance On Hold"}
                value={usersData?.hold_balance ? usersData?.hold_balance : 0}
                color={"bg-color-pink"}
              />
            </>
          )}
        </div>
      </div>
      {/* <Toaster /> */}
    </>
  );
};
export default CustomerCareDashboard;
