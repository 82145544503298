import { API } from "../API";
import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import { PaginationControl } from "react-bootstrap-pagination-control";
import moment from "moment";
import { Download } from "react-bootstrap-icons";

const pagelimit = 10;

export const RechargeHistory = () => {
  const getUser = JSON.parse(localStorage.getItem("user"));
  const [transactionData, setTransactionData] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);

  const [page, setPage] = useState(1);

  useEffect(() => {
    async function fetchTranscationData() {
      const { data } = await API.post(
        `/credit/view-transaction/${getUser?._id}`,
        {
          type: "Credit",
          page: page,
          limit: pagelimit,
        }
      );
      if (data?.data?.transactions) {
        setTransactionData(data.data.transactions);
        setTotalTransactions(data.data.totalTransactions);
      } else {
        setTransactionData(...{});
        setTotalTransactions(0);
      }
    }
    fetchTranscationData();
  }, [getUser?._id, page]);

  const downloadInvoice = async (id) => {
    const res = await API.get(`credit/invoice/${id}`, {
      responseType: "blob",
    });

    const selectedTransaction = transactionData.filter((d) => {
      return d._id === id;
    });
    const invoiceName = `INV_${new Date(
      selectedTransaction[0].createdAt
    ).getTime()}`;

    const url = URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${invoiceName}.pdf`;
    link.click();

    URL.revokeObjectURL(url);
    toast.success("download Success");
  };
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-12">
          <div
            className="table-responsive text-wrap table-Fixed"
            style={{
              height: "300px",
              overflow: "auto",
              position: "relative",
              zIndex: 1,
              boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)",
            }}
          >
            <table className="table table-fixed">
              <thead className="table">
                <tr className="text-color-primary">
                  <th scope="col" className="lable-fw-size">
                    Added By
                  </th>
                  <th scope="col" className="lable-fw-size">
                    Balance
                  </th>
                  <th scope="col" className="lable-fw-size">
                    Description
                  </th>
                  <th scope="col" className="lable-fw-size">
                    Created Date
                  </th>
                  <th>Download</th>
                </tr>
              </thead>

              {transactionData.length > 0 && (
                <tbody className="table-bordered">
                  {transactionData?.map((el, key) => {
                    return (
                      <React.Fragment key={key}>
                        <tr className="lable-fw-size">
                          <td>{el?.addedBy?.fullName}</td>
                          <td>{el.balance}</td>
                          <td>{el.description}</td>
                          <td>
                            {moment(el.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                          </td>
                          <td className="text-center">
                            <Download onClick={() => downloadInvoice(el._id)} />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}{" "}
                </tbody>
              )}
            </table>
          </div>
        </div>
        <div className="mt-3 zero-paggination">
          <PaginationControl
            page={page}
            between={4}
            total={totalTransactions}
            limit={pagelimit}
            changePage={(page) => {
              setPage(page);
            }}
          />
        </div>
      </div>
    </>
  );
};
