import { getAsset } from "./prepareAssests";
import { PDFDocument, rgb, degrees } from "pdf-lib";
import KumarOnefonts from "./../fonts/FinalFonts/KumarOne-Regular.ttf";
import Aakar from "./../fonts/FinalFonts/Aakar.ttf";
import BHUJ_UNICODE from "./../fonts/FinalFonts/BHUJ-UNICODE.ttf";
import Ekatra from "./../fonts/FinalFonts/Ekatra.ttf";
import NotoSansGujarati from "./../fonts/FinalFonts/NotoSansGujarati-VariableFont_wdth,wght.ttf";
import NotoSerifGujarati from "./../fonts/FinalFonts/NotoSerifGujarati-VariableFont_wght.ttf";
import Padmaa from "./../fonts/FinalFonts/Padmaa.ttf";
import Rasa from "./../fonts/FinalFonts/Rasa-VariableFont_wght.ttf";
import Shruti from "./../fonts/FinalFonts/Shruti.ttf";
import Surat_Unicode from "./../fonts/FinalFonts/Surat-Unicode.ttf";
import Mogra from "./../fonts/FinalFonts/Mogra.ttf";
import Rekha from "./../fonts/FinalFonts/Rekha.ttf";
import Ekatra_B from "./../fonts/FinalFonts/Ekatra-B.ttf";
import HindVadodara_Bold from "./../fonts/FinalFonts/HindVadodara-Bold.ttf";
import Lohit_Gujarati from "./../fonts/FinalFonts/Lohit_Gujarati.ttf";
import MuktaVaani_Bold from "./../fonts/FinalFonts/MuktaVaani-Bold.ttf";
import MuktaVaani_ExtraBold from "./../fonts/FinalFonts/MuktaVaani-ExtraBold.ttf";
import RAJKOT_UNICODE from "./../fonts/FinalFonts/RAJKOT-UNICODE.ttf";
import fontKit from "@pdf-lib/fontkit";
import { API } from "../API";

export async function save(
  pdfFile,
  objects,
  name,
  varible,
  action,
  mobileNo,
  msg,
  projectId
) {
  // console.log("=objects", objects);
  // console.log("=varible", varible);
  let pdfDoc;
  const download = await getAsset("download");
  const readAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  try {
    pdfDoc = await PDFDocument.load(await readAsArrayBuffer(pdfFile), {
      updateMetadata: true,
    });
  } catch (e) {
    // console.log("Failed to load PDF.");
    throw e;
  }

  const pagesProcesses = pdfDoc.getPages().map(async (page, pageIndex) => {
    const pageObjects = objects[pageIndex];
    // 'y' starts from bottom in PDFLib, use this to calculate y
    const pageHeight = page.getHeight();
    if (pageObjects.length) {
      const embedProcesses = pageObjects.map(async (object) => {
        if (object.type === "text") {
          const { x, y, lineHeight, size, variableName, fontFamily, color } =
            object;
          pdfDoc.registerFontkit(fontKit);
          let selectedFonts;

          // 'Kumar One','Aakar','BHUJ-UNICODE','Ekatra','Surat-Unicode','Shruti','Noto Serif Gujarati','Noto Sans Gujarati','Rasa','Padmaa'
          if (fontFamily === "Kumar One") {
            selectedFonts = KumarOnefonts;
          } else if (fontFamily === "Aakar") {
            selectedFonts = Aakar;
          } else if (fontFamily === "BHUJ-UNICODE") {
            selectedFonts = BHUJ_UNICODE;
          } else if (fontFamily === "Shruti") {
            selectedFonts = Shruti;
          } else if (fontFamily === "Ekatra") {
            selectedFonts = Ekatra;
          } else if (fontFamily === "Surat-Unicode") {
            selectedFonts = Surat_Unicode;
          } else if (fontFamily === "Noto Serif Gujarati") {
            selectedFonts = NotoSansGujarati;
          } else if (fontFamily === "Noto Sans Gujarati") {
            selectedFonts = NotoSerifGujarati;
          } else if (fontFamily === "Rasa") {
            selectedFonts = Rasa;
          } else if (fontFamily === "Padmaa") {
            selectedFonts = Padmaa;
          } else if (fontFamily === "Rekha") {
            selectedFonts = Rekha;
          } else if (fontFamily === "Mogra") {
            selectedFonts = Mogra;
          } else if (fontFamily === "Ekatra-B") {
            selectedFonts = Ekatra_B;
          } else if (fontFamily === "HindVadodara-Bold") {
            selectedFonts = HindVadodara_Bold;
          } else if (fontFamily === "Lohit_Gujarati") {
            selectedFonts = Lohit_Gujarati;
          } else if (fontFamily === "MuktaVaani-Bold") {
            selectedFonts = MuktaVaani_Bold;
          } else if (fontFamily === "MuktaVaani-ExtraBold") {
            selectedFonts = MuktaVaani_ExtraBold;
          } else if (fontFamily === "RAJKOT-UNICODE") {
            selectedFonts = RAJKOT_UNICODE;
          }
          const fontBytes = await fetch(selectedFonts).then((res) =>
            res.arrayBuffer()
          );

          const pdfFont = await pdfDoc.embedFont(fontBytes, { subset: true });
          const fontSize = Number(size);

          const r = color.r / 255;
          const g = color.g / 255;
          const b = color.b / 255;
          const { height } = page.getSize();
          return () => {
            if (action === "test") {
              page.drawText("Zip Zip India", {
                x: 80,
                y: height / 2 + 200,
                size: 80,
                font: pdfFont,
                color: rgb(0.95, 0.1, 0.1),
                rotate: degrees(-35),
              });
            }
            page.drawText(varible[variableName], {
              font: pdfFont,
              size: fontSize,
              lineHeight,
              x: x,
              y: pageHeight - fontSize - y,
              color: rgb(r, g, b),
            });
          };
        }
      });
      // embed objects in order
      const drawProcesses = await Promise.all(embedProcesses);
      drawProcesses.forEach((p) => p());
    }
  });

  await Promise.all(pagesProcesses);
  try {
    // console.log("=pdfDocv>>>>>>>>>>>>", pdfDoc.getProducer());
    pdfDoc.setTitle("ZeroPaper");
    pdfDoc.setAuthor("ZeroPaper.in");
    pdfDoc.setCreator("ZeroPaper");
    pdfDoc.setProducer("ZeroPaper");
    // console.log("=pdfDocv>>>>SEtted", pdfDoc.getProducer());

    const pdfBytes = await pdfDoc.save();
    if (action === "download") {
      download(pdfBytes, name, "application/pdf");
    } else if (action === "test") {
      const data = new FormData();
      const bytes = new Uint8Array(pdfBytes);
      const fontBytes = await fetch(KumarOnefonts).then((res) =>
        res.arrayBuffer()
      );
      data.append("file", new Blob([bytes], { type: "application/pdf" }));
      data.append("mobileNo", mobileNo);
      data.append("message", msg);
      data.append("projectId", projectId);
      data.append("pdfName", varible.name);
      data.append("fontByte", fontBytes);
      pdfDoc.setCreator("ZeroPaper");
      pdfDoc.setProducer("ZeroPaper");
      const result = await API.post(
        `/whatsapp/sendtestmessage?projectId=${projectId}`,
        data
      );
      return result;
    } else if (action === "send") {
      const bytes = new Uint8Array(pdfBytes);
      // download(pdfBytes, 'test', "application/pdf");
      return bytes;
    }

    // const data = new FormData()
    // const bytes  = new Uint8Array( pdfBytes );
    // let reader = new FileReader();
    // let dataReader =''
    // reader.readAsDataURL(new Blob( [ bytes ], { type: "application/pdf" } ));
    // reader.onload = async function () {
    //   dataReader = reader.result
    //   let data_Append = new Blob( [ bytes ], { type: "application/pdf" } )
    //   data_Append.data =reader.result
    //   console.log("=BLOBBIII",data_Append);
    //   data.append('blob', data_Append)
    //   data.append('file', new Blob( [ bytes ], { type: "application/pdf" } ))
    //   const user = await API.post(`/whatsapp/sendtestmessage?projectId=64059fdade843399157fa8ac`,data);
    //   //  console.log("====reader.resultreader.resultreader.result",reader.result);
    // };
    // reader.onerror = function (error) {
    //     console.log('Error: ', error);
    // };
    // console.log("=reader>>>>>>>>>>>>>>>>",dataReader);

    // const blob   =
    // const docUrl = URL.createObjectURL( blob );
    // console.log("=docUrl",docUrl);
    // const user = await API.post(`/whatsapp/sendmessage?projectId=64059fdade843399157fa8ac`,data);

    // download(pdfBytes, name, "application/pdf");
  } catch (e) {
    console.log("Failed to save PDF.");
    throw e;
  }
}
