import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../API";
import { ArrowLeft } from "react-bootstrap-icons";

const CreateProject = () => {
  let { state } = useLocation();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    console.log("data--------------", data);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const res = await API.get(`/auth/user/${user._id}`);
      if (res.data.data.charge <= res.data.data.total_balance) {
        data.type = state.id;
        const project = await (
          await API.post("/project/create-project", data)
        ).data;
        if (project.success) {
          toast.success(project.message);
          navigate("/projects", {
            state: { id: state.id, function: state.function },
          });
        } else {
          toast.error(project.message);
        }
      } else {
        toast.error("You don't have enought balance to create new project!");
      }
    } catch (error) {
      // console.log("error", error);
    }
  };
  console.log("state.type-------------------------=", state.id);
  console.log("state.-------------------------=", state);
  console.log("state.functions-------------------------=", state.function);
  console.log("09090900909090909009", state.function === "Wedding");
  return (
    <>
      <div className="container mt-3 container-main">
        <h3 className="page-title">Create Project {" > " + state.function}</h3>
        <div>
          <button
            style={{
              cursor: "pointer",
              backgroundColor: "#e2e6ea",
              border: "none",
            }}
            className="nav-font p-2 m-auto rounded mt-2"
            onClick={() =>
              navigate("/projects", {
                state: { id: state.id, function: state.function },
              })
            }
          >
            <ArrowLeft size={20} />
          </button>
        </div>
        <div className="mt-3 card-trans p-md-4 p-2">
          <div className="row align-items-center justify-content-center">
            <div className="bg-white rounded col-md-9 p-4 mt-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group ">
                  <label className="lable-fw-size">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("name", { required: true })}
                    type="text"
                    onKeyDown={(e) =>
                      [">", "<", "/"].includes(e.key) && e.preventDefault()
                    }
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Description <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("description", { required: true })}
                    type="text"
                    onKeyDown={(e) =>
                      [">", "<", "/"].includes(e.key) && e.preventDefault()
                    }
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Description"
                  />
                </div>

                {state.function === "Wedding" && (
                  <>
                    <div className="form-group mt-3">
                      <label htmlFor="b_name">
                        Bride's Name <span className="text-danger">*</span>
                      </label>
                      <input
                        id="b_name"
                        {...register("b_name", { required: true })}
                        type="text"
                        className="form-control mt-1 form-fs form-label-border p-2"
                        placeholder="Enter Bride's Name"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="g_name">
                        Groom's Name <span className="text-danger">*</span>
                      </label>
                      <input
                        id="g_name"
                        {...register("g_name", { required: true })}
                        type="text"
                        className="form-control mt-1 form-fs form-label-border p-2"
                        placeholder="Enter Groom's Name"
                      />
                    </div>
                  </>
                )}
                <div className="form-group mt-3">
                  <label htmlFor="wDate">
                    Date of Event <span className="text-danger">*</span>
                  </label>
                  <input
                    id="wDate"
                    {...register("wDate", { required: true })}
                    type="date"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <button
                  type="submit"
                  className="btn-gradient-primary mr-2 p-2 mt-3 border rounded "
                >
                  Create
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateProject;
