import React from "react";
import { useForm } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API } from "../API";

const ResetPassword = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // console.log("=token", searchParams.get("token"));
  const onSubmit = async (data) => {
    try {
      if (data.password !== data.confirmPassword) {
        return toast.error("password does'nt match.");
      }
      if (searchParams.get("token")) {
        const result = await API.post("/auth/reset-password", {
          ...data,
          token: searchParams.get("token"),
        });
        if (result.data.success) {
          toast.success(result.data.message);
          navigate("/login");
        } else {
          toast.error(result.data.message);
        }
      } else {
        return toast.error("token is required");
      }
    } catch (error) {}
  };
  return (
    <div>
      <div className="container vw-100 vh-100 d-flex justify-content-center align-items-center">
        <div className="form-width m-auto">
          <div className="shadow bg-white pt-4 pb-4 rounded">
            <div className="ps-3 pe-3 ps-md-5 pe-md-5">
              <h3
                className="fs-2 title text-center"
                style={{ color: "rgb(34, 34, 34)", fontWeight: "700" }}
              >
                Reset Password
              </h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="lable-fw-size">
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("password", { required: true })}
                    type="password"
                    className="form-control mt-1 form-fs form-label-border"
                    placeholder="New Password"
                  />
                  {errors.password && (
                    <span className="text-danger">Password is required</span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("confirmPassword", { required: true })}
                    type="password"
                    className="form-control mt-1 form-fs form-label-border"
                    placeholder="Confirm Password"
                  />
                  {errors.confirmPassword && (
                    <span className="text-danger">
                      Confirm Password is required
                    </span>
                  )}
                </div>
                <div className="form-group row mt-2">
                  <div className="d-grid gap-2 mt-2 mb-4">
                    <button
                      type="submit"
                      className="btn btn-gradient-primary mt-3"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <Toaster /> */}
    </div>
  );
};

export default ResetPassword;
