import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { API } from "../API";
import Loading from "react-fullscreen-loading";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const MessageReminderModel = ({ handlemodal, isShow, projectId }) => {
  let navigate = useNavigate();
  const [project, setProject] = useState();
  const [qrModalStatus, setQrModalStatus] = useState(false);
  const [qrData, setQrData] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendFileModalStatus, setSendFileModalStatus] = useState(false);
  const [creditData, setCreditData] = useState({});
  const [isScan, setIsScan] = useState(false);
  const [mobileModalStatus, setMobileModalStatus] = useState(false);
  const [mobileNo, setMobileNo] = useState("91");

  const handleMobileClose = () => setMobileModalStatus(false);

  let timer;
  useEffect(() => {
    const getProject = async () => {
      const res = await API.get(`/project/get/${projectId}`);
      setProject(res.data.data);
    };
    getProject();
  }, [projectId]);

  const testMessage = async () => {
    setMobileModalStatus(false);
    if (isScan) {
      if (mobileNo !== "") {
        try {
          setLoading(true);
          const result = await API.post(
            `/whatsapp/sendTestReminderMessage?projectId=${projectId}`,
            { mobileNo: mobileNo }
          );
          if (result.data.success) {
            setLoading(false);
            toast.success(result.data.message);
          } else {
            setLoading(false);
            toast.error(result.data.message);
          }
        } catch (e) {
          // console.log("error", e);
        }
      } else {
        toast.error("please enter mobile no");
      }
    } else {
      toast.error("Please scan QR");
    }
  };

  async function sendFile() {
    const formData = new FormData();
    formData.append("projectId", projectId);
    const { data } = await API.get(
      `/whatsapp/sendReminderMessage/${projectId}`
    );
    if (data.success) {
      toast.success(data.message);
      navigate("/functions/project", {
        state: { id: project.type },
      });
    } else toast.error("");
  }

  let timer2;
  const getQr = async () => {
    if (!qrData) {
      setLoading(true);
      timer2 = setInterval(async () => {
        const sesisonData = await API.get(
          `/whatsapp/issessionsaved/?projectId=${projectId}`
        );
        if (sesisonData.data.success === false) {
          const { data } = await API.get(
            `/whatsapp/getqr/?projectId=${projectId}`
          );
          setLoading(false);
          if (data.success) {
            setQrModalStatus(true);
            setQrData(data.data);
            getQrData();
          } else {
            toast.error(data.message);
          }
        }
      }, 10000);
    }
    if (qrData) {
      clearInterval(timer2);
    }
  };

  const getQrData = () => {
    return clearInterval(timer2);
  };

  useEffect(() => {
    async function sessionExist() {
      const { data } = await API.get(
        `/whatsapp/issessionsaved?projectId=${projectId}`
      );
      if (data.success) setIsScan(true);
    }
    sessionExist();
  }, [projectId]);

  const getScanQrCodeStatus = async () => {
    if (qrModalStatus) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(async () => {
        const { data } = await API.get(
          `/whatsapp/issessionsaved?projectId=${projectId}`
        );
        if (data.success) {
          setIsScan(true);
          setQrModalStatus(false);
        }
        // setSession(data);
      }, 10000);
    }
    if (!qrModalStatus) {
      clearInterval(timer);
    }
  };

  useEffect(() => {
    getScanQrCodeStatus();
    return () => clearInterval(timer);
  }, [qrModalStatus, timer]);

  return (
    <>
      {isShow && (
        <>
          <Modal
            show={isShow}
            backdrop="static"
            centered
            className="qrmodal"
            hidden={loading}
            onHide={() => handlemodal(false)}
          >
            <Modal.Header className="justify-content-center" closeButton>
              <Modal.Title className="ms-auto page-title">
                Confirm Send Message
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              <div className="d-flex justify-content-center gap-3">
                {!isScan ? (
                  <button
                    className="btn-gradient-primary p-2 border rounded"
                    onClick={() => getQr()}
                  >
                    Scan Qr
                  </button>
                ) : (
                  <button
                    onClick={async () => {
                      const { data } = await API.get(
                        `/whatsapp/logout?projectId=${projectId}`
                      );
                      if (data?.success) {
                        toast.success(data.message);
                        setIsScan(false);
                      }
                    }}
                    className="btn-gradient-primary p-2 border rounded"
                  >
                    Log Out
                  </button>
                )}
                <button
                  className="btn-gradient-primary p-2 border rounded"
                  onClick={() => setMobileModalStatus(true)}
                >
                  Test
                </button>
                <button
                  className="btn-gradient-primary p-2 border rounded"
                  onClick={async () => {
                    if (isScan) {
                      const { data } = await API.get(
                        `/credit/check-reminder-message-credit/${projectId}`
                      );
                      if (data.success) {
                        setCreditData(data);
                        setSendFileModalStatus(true);
                      } else {
                        toast.error(data.message);
                      }
                    } else {
                      toast.error("Please scan qr");
                    }
                  }}
                >
                  Send Message
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <div>
            <div className="row mx-0">
              {qrModalStatus && (
                <Modal
                  show={qrModalStatus}
                  backdrop="static"
                  centered
                  className="qrmodal"
                >
                  <Modal.Body>
                    <div className="m-3">
                      <div
                        style={{
                          width: "264px",
                          height: "264px",
                          background: `url(${qrData})`,
                        }}
                      />
                    </div>
                  </Modal.Body>
                </Modal>
              )}

              {sendFileModalStatus && (
                <Modal
                  show={sendFileModalStatus}
                  onHide={() => setSendFileModalStatus(false)}
                  centered
                >
                  <Modal.Header className="px-4" closeButton>
                    <Modal.Title className="ms-auto page-title">
                      Confirm Send Message
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="m-3">
                      <div className="form-group md-4">
                        <div className="lable-fw-size">{`Your Total pdf is : ${creditData?.data?.count}`}</div>
                        <div className="label-fw-size">{`Your cut balance is : ${Number(
                          creditData?.data?.totalCharge
                        ).toFixed(2)}`}</div>
                        <div className="lable-fw-size">{`Your balance will be reduced to : ${Number(
                          creditData.data.remainingCredit
                        ).toFixed(2)}`}</div>
                        <div className="label-fw-size">{`If you want to continue, click on Continue.`}</div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn-light fw-bold p-2 btn-gradient-primary p-2 border rounded"
                      onClick={() => setSendFileModalStatus(false)}
                      style={{ width: "15%" }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-gradient-primary fw-bold p-2 border rounded "
                      style={{ width: "18%" }}
                      onClick={async () => {
                        const { data } = await API.post(
                          `auth/update-holdBalance`,
                          {
                            count: creditData?.data?.count,
                            projectId: projectId,
                            reminderMessage: true,
                          }
                        );
                        if (data.success) {
                          setSendFileModalStatus(false);
                          sendFile();
                        } else {
                          toast.error("Network Error");
                        }
                      }}
                    >
                      Continue
                    </button>
                  </Modal.Footer>
                </Modal>
              )}

              {mobileModalStatus && (
                <Modal
                  show={mobileModalStatus}
                  onHide={handleMobileClose}
                  centered
                >
                  <Modal.Header className="px-4" closeButton>
                    <Modal.Title className="ms-auto page-title">
                      Add Mobile No
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="m-3">
                      <div className="form-group md-4">
                        <label className="lable-fw-size">
                          Mobile No with country code{" "}
                          <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="tel"
                          id="mobile_no"
                          className="form-control form-fs form-label-border mt-1 "
                          name="mobile_no"
                          maxLength="12"
                          defaultValue={mobileNo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const numericValue = inputValue.replace(
                              /[^0-9]/g,
                              ""
                            ); // Remove non-numeric characters
                            setMobileNo(numericValue);
                          }}
                          onKeyDown={(e) => {
                            if (
                              (["e", "E", "+", "-", "."].includes(e.key) ||
                                /[a-zA-Z]/.test(e.key)) &&
                              e.key !== "Backspace" &&
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          pattern="[0-9]*"
                        ></input>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn-light fw-bold p-2 border rounded"
                      onClick={handleMobileClose}
                      style={{ width: "15%" }}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="btn-gradient-primary fw-bold p-2 border rounded"
                      style={{ width: "15%" }}
                      onClick={() => testMessage()}
                    >
                      Yes
                    </button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </div>
        </>
      )}
      {loading && (
        <div>
          <Loading loading={loading} loaderColor="#24292f" />
        </div>
      )}
    </>
  );
};
