import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { API } from "../../API";
import logo from "../../images/logo.png";

const Register = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [showSignup, setShowSignup] = useState(false);
  let [searchParams] = useSearchParams();
  // console.log("=token", searchParams.get("token"));
  useEffect(() => {
    if (process.env.REACT_APP_SIGNUP_PASS === searchParams.get("token")) {
      setShowSignup(true);
    } else {
      navigate("/unauthorized");
    }
    // console.log("env varible",process.env.REACT_APP_SIGNUP_PASS);
  }, [navigate, searchParams]);
  const onSubmit = async (data) => {
    try {
      if (data.password !== data.confirmPassword) {
        return toast.error("password does'nt match.");
      }

      // register
      delete data.confirmPassword;
      const register = await API.post("/auth/signup", data);
      const response = register.data;
      if (response.success) {
        toast.success(response.message);
        navigate("/login");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    showSignup && (
      <div className="container vw-100 vh-100 d-flex justify-content-center align-items-center">
        <div className="form-width m-auto ">
          <div className="shadow bg-white pt-4 pb-4 rounded">
            <div className="ps-3 pe-3 ps-md-5 pe-md-5">
              <img
                src={logo}
                alt="logo"
                width={150}
                height={100}
                className="m-auto d-block mb-2"
              />
              <h3
                className="fs-2 text-center title"
                style={{ color: "rgb(34, 34, 34)", fontWeight: "700" }}
              >
                Signup Here !
              </h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group ">
                  <label className="lable-fw-size">
                    Email <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    // autoFocus={false}
                    autoComplete="off"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter email address"
                  />
                  {errors.email && (
                    <span className="text-danger">Email is required</span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    FullName <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("fullName", { required: true })}
                    type="text"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Fullname"
                  />
                  {errors.email && (
                    <span className="text-danger">Email is required</span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    PhoneNumber <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("phoneNumber", { required: true })}
                    type="number"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter phone number"
                  />
                  {errors.phoneNumber && (
                    <span className="form-fs text-danger">
                      phoneNumber is required
                    </span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">Charge </label>
                  <input
                    {...register("charge")}
                    type="number"
                    defaultValue="5"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Charge"
                  />
                  {errors.charge && (
                    <span className="form-fs text-danger">
                      charge is required
                    </span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Role <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control mt-1 form-fs form-label-border p-2"
                    aria-label="Default select example"
                    {...register("userType")}
                  >
                    <option value="USER" selected>
                      User
                    </option>
                    <option value="CUSTOMER_CARE">Customer Care</option>
                    <option value="ADMIN">Admin</option>
                  </select>
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("password", { required: true })}
                    type="password"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Password"
                  />
                  {errors.password && (
                    <span className="text-danger">password is required</span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("confirmPassword", { required: true })}
                    type="password"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter confirm Password"
                  />
                  {errors.confirmPassword && (
                    <span className="form-fs text-danger">
                      Confirm Password is required
                    </span>
                  )}
                </div>
                <div className="form-group row mt-3">
                  <div className="d-grid gap-2 mb-4 ">
                    <button
                      type="submit"
                      className="btn btn-gradient-primary mt-3 text-uppercase"
                    >
                      sign up
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <Toaster /> */}
      </div>
    )
  );
};

export default Register;
