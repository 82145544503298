import React from "react";

function PageCard({ title, value, color, secondTitle, secondValue }) {
  return (
    <div className="col-md-3 mt-4">
      <div
        className={`${color} rounded `}
        // className={`${color} mb-2 border  border-color-primary rounded border-2`}
      >
        {/* <div className={`${color} bg-gradient-danger mb-2 border rounded`}> */}

        <div className="d-flex flex-column p-lg-4 p-md-2 p-sm-4 p-4 ">
          <div className="card-title1">{title}</div>
          {value && (
            <div className="d-flex flex-row justify-content-between">
              <h6 className="fw-bold">{value}</h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PageCard;
