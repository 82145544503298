import React from "react";
import ContentLoader from "react-content-loader";

export const DashboardBoxColumnLoader = () => {
  const Loader = (props) => {
    return (
      <ContentLoader
        speed={2}
        width={300}
        height={800}
        primaryColor="#d9d9d9"
        secondaryColor="#ecebeb"
        {...props}

      >
        <rect x="0" y="0" rx="3" ry="3" width="300" height="134" />
        <rect x="0" y="160" rx="3" ry="3" width="300" height="134" />
        <rect x="0" y="320" rx="3" ry="3" width="300" height="134" />
        <rect x="0" y="480" rx="3" ry="3" width="300" height="134" />
      </ContentLoader>
    );
  };

  return (
    <>
      {Array(1)
        .fill("")
        .map((e, i) => (
          <Loader key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
        ))}
    </>
  );
};
