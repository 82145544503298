export const editImage = async (imageURL, textObjects, variable) => {
  const image = new Image();
  image.src = imageURL;

  return new Promise((resolve) => {
    image.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d");

      context.drawImage(image, 0, 0, image.width, image.height);

      textObjects.forEach((textObj) => {
        context.font = `${textObj.size}px ${textObj.fontFamily}`;
        context.fillStyle = `rgb(${textObj.color.r}, ${textObj.color.g}, ${textObj.color.b})`;
        context.fillText(
          variable[textObj.variableName],
          textObj.x,
          textObj.y + 25
        );
      });

      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    };
  });
};

export const downloadImage = async (imageURL, textObjects, variable) => {
  const editedBlob = await editImage(imageURL, textObjects, variable);
  const link = document.createElement("a");
  link.href = URL.createObjectURL(editedBlob);
  link.download = `${variable.name}.png`;
  link.click();
  URL.revokeObjectURL(link.href);
};
