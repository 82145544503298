import React, { useEffect, useRef, useState } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Modal } from "react-bootstrap";
import { API } from "../../API";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import noCsvGif from "../../images/No_csv.gif";
import { SendFailedFileModel } from "../SendFailedFileModel";
import { Link, Navigate, useLocation } from "react-router-dom";
import { ArrowLeft } from "react-bootstrap-icons";

function Step1({
  nextStep,
  handleFormData,
  values,
  project,
  userList,
  failedUserList,
  csvValues,
  resetValue,
}) {
  const [error, setError] = useState(false);
  const [isSelectFromManual, setIsSelectFromManual] = useState(false);
  const initialRowData = {
    name: "",
    number: "",
    var1: "",
    var2: "",
    var3: "",
    var4: "",
    var5: "",
    var6: "",
    var7: "",
  };
  let [rows, setRows] = useState([initialRowData]);

  let { state } = useLocation();
  const [isNext, setIsNext] = useState(false);
  //State to store the values
  const fileInputRef = useRef(null);
  const [csvvalues, setCsvValues] = useState([]);
  const [editNumberStatus, setEditNumberStatus] = useState(false);
  // const [addCountryCode, setAddCountryCode] = useState(false);
  const [editNumber, setEditNumber] = useState({});
  const [mobileNo, setMobileNo] = useState("");
  // const [isCountryCodeButton, setIsCountryCodeButton] = useState(false);
  // const [duplicateCsvCount, setDuplicateCsvCount] = useState(0);

  useEffect(() => {
    if (values?.csv) {
      let fileExtension = "csv"; // Default to CSV

      // Check if the file has a CSV file extension
      if (values.csv.name.endsWith(".csv")) {
        fileExtension = "csv";
      } else if (values.csv.name.endsWith(".xlsx")) {
        fileExtension = "xlsx";
      } else {
        console.error(
          "Unsupported file type. Only CSV and XLSX files are allowed."
        );
        return;
      }

      if (fileExtension === "csv") {
        Papa.parse(values?.csv, {
          complete: function (results) {
            const rowsArray = [];
            const valuesArray = [];

            // Iterating data to get column name and their values
            results.data.map((d) => rowsArray.push(Object.keys(d)));

            const csvData = [];
            results.data.map((d, index) => {
              if (d[0] !== "") {
                csvData.push(d);
              }
              return d;
            });
            console.log("csvData------------------", csvData);
            csvData.map((d) => {
              let array = {};
              rowsArray[0].map((t, index) => {
                if (Object.values(d)[index] !== "") {
                  if (index === 0) {
                    array["name"] = Object.values(d)[index];
                  } else if (index === 1) {
                    let mobileNumber = Object.values(d)[index];
                    if (typeof mobileNumber === "string") {
                      mobileNumber = mobileNumber.replace(/^\+/, "");
                    }
                    array["number"] = mobileNumber;
                  } else {
                    array[`var${index - 1}`] = Object.values(d)[index];
                  }
                }
                return t;
              });
              valuesArray.push(array);
              return d;
            });
            // Filtered Values

            // const uniqueNumbers = new Set();
            // const uniqueData = [];
            // let deletedCount = 0;

            // for (const item of valuesArray) {
            //   if (!uniqueNumbers.has(item.number)) {
            //     uniqueNumbers.add(item.number);
            //     uniqueData.push(item);
            //   } else {
            //     deletedCount++;
            //   }
            // }
            // // setDuplicateCsvCount(deletedCount);
            // csvValues(uniqueData);
            // setCsvValues(uniqueData);

            const uniqueData = [];

            console.log("csvvalues=========", valuesArray);
            const duplicates = findDuplicateNumbers(valuesArray);
            if (duplicates.length > 0) {
              setDuplicateNumbers(duplicates);
            }

            for (const item of valuesArray) {
              uniqueData.push(item);
            }

            csvValues(uniqueData);
            setCsvValues(uniqueData);
          },
        });
      } else if (fileExtension === "xlsx") {
        const reader = new FileReader();
        const rowsArray = [];
        const valuesArray = [];
        reader.onload = (e) => {
          const arrayBuffer = e.target.result;
          const data = new Uint8Array(arrayBuffer);

          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

          jsonData.map(
            (d) => d[0] !== "" && d[0] && rowsArray.push(Object.keys(d))
          );

          const csvData = [];
          jsonData.map((d, index) => {
            if (d[0] !== "" && d[0]) {
              csvData.push(d);
            }
            return d;
          });
          console.log("csvData=========================", csvData);
          csvData.map((d) => {
            let array = {};
            rowsArray[0].map((t, index) => {
              if (Object.values(d)[index] !== "") {
                if (index === 0) {
                  array["name"] = Object.values(d)[index];
                } else if (index === 1) {
                  let mobileNumber = Object.values(d)[index];
                  if (typeof mobileNumber === "string") {
                    mobileNumber = mobileNumber.replace(/^\+/, "");
                  }
                  array["number"] = mobileNumber;
                } else {
                  array[`var${index - 1}`] = Object.values(d)[index];
                }
              }
              return t;
            });
            valuesArray.push(array);
            return d;
          });
          // Filtered Values

          // const uniqueData = [];

          console.log("valuesArray", valuesArray);

          const uniqueData = [];

          console.log("csvvalues=========", valuesArray);
          const duplicates = findDuplicateNumbers(valuesArray);
          if (duplicates.length > 0) {
            setDuplicateNumbers(duplicates);
          }
          for (const item of valuesArray) {
            uniqueData.push(item);
          }
          csvValues(uniqueData);
          setCsvValues(uniqueData);
        };
        reader.readAsArrayBuffer(values.csv);
      }
    }
  }, [values?.csv]);
  const submitFormData = () => {
    if (
      (userList.length === 0 && !values?.csv && csvvalues.length === 0) ||
      userList.length < 0
    ) {
      toast.error("CSV file is required");
      setError(true);
      return;
    }

    // const isCountryCodeProvided = csvvalues.every((item) => {
    //   const mobileNumber = String(item.number);
    //   return mobileNumber.startsWith("91");
    // });

    // if (!isCountryCodeProvided) {
    //   toast.error("Country code is required for all mobile numbers");
    //   setError(true);
    //   return;
    // }

    // Check for duplicate numbers
    const duplicateNumbers = findDuplicateNumbers(csvvalues);
    if (duplicateNumbers.length > 0) {
      // Display modal or message to handle duplicates
      // You can choose to proceed with duplicates or remove them
      setDuplicateNumbers(duplicateNumbers);
      setShowDuplicateDialog(true);
    } else {
      // Proceed to next step if no duplicates found
      if (project.completStatus === "FAILED") {
        setIsNext(true);
      } else {
        nextStep();
      }
    }
  };

  const handleAddRow = () => {
    setRows((prevRows) => [...prevRows, initialRowData]);
  };

  const handleChange = (e, rowIndex) => {
    const { name, value } = e.target;
    if (name === "number" && !/^\d+$/.test(value)) {
      return;
    }
    setRows((prevRows) =>
      prevRows.map((row, index) =>
        index === rowIndex ? { ...row, [name]: value } : row
      )
    );
  };

  const handleDeleteAllRows = () => {
    setRows([initialRowData]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    rows = rows.filter((item) => {
      return item.number !== "" && item.name !== "";
    });
    rows = removeBlankFields(rows);

    const uniqueNumbers = new Set();
    const uniqueData = [];
    let deletedCount = 0;

    for (const item of rows) {
      if (!uniqueNumbers.has(item.number)) {
        uniqueNumbers.add(item.number);
        uniqueData.push(item);
      } else {
        deletedCount++;
      }
    }
    // setDuplicateCsvCount(deletedCount);
    csvValues(uniqueData);
    setCsvValues(uniqueData);
    setIsSelectFromManual(false);
  };

  const removeBlankFields = () => {
    let maxVarCount = 0;
    for (const item of rows) {
      maxVarCount = Math.max(maxVarCount, Object.keys(item).length - 2);
    }

    const hasNonEmptyValue = Array.from({ length: maxVarCount }, () => false);

    for (const item of rows) {
      for (let i = 1; i <= maxVarCount; i++) {
        const varKey = "var" + i;
        if (item[varKey].trim() !== "") {
          hasNonEmptyValue[i - 1] = true;
        }
      }
    }

    const updatedData = rows.map((item) => {
      const updatedItem = { name: item.name, number: item.number };
      for (let i = 1; i <= maxVarCount; i++) {
        const varKey = "var" + i;
        if (item[varKey].trim() !== "" || hasNonEmptyValue[i - 1]) {
          updatedItem[varKey] = item[varKey];
        }
      }
      return updatedItem;
    });

    return updatedData;
  };

  // const [selectedCountryCode, setSelectedCountryCode] = useState("91");

  // const handleSelectCountry = (value, country) => {
  //   setSelectedCountryCode(country.dialCode);
  // };

  // const handleAddCountryCode = () => {
  //   rows = rows.filter((item) => {
  //     return item.number !== "" && item.name !== "";
  //   });
  //   rows = removeBlankFields(rows);
  //   if (rows.length > 0) {
  //     setAddCountryCode(!addCountryCode);
  //     const updatedData = rows.map((item) => {
  //       item.number = selectedCountryCode + item.number;
  //       return item;
  //     });

  //     const uniqueNumbers = new Set();
  //     const uniqueData = [];

  //     for (const item of updatedData) {
  //       if (!uniqueNumbers.has(item.number)) {
  //         uniqueNumbers.add(item.number);
  //         uniqueData.push(item);
  //       }
  //     }
  //     csvValues(uniqueData);
  //     setCsvValues(uniqueData);
  //   } else {
  //     const updatedData = csvvalues.map((item) => {
  //       item.number = selectedCountryCode + item.number;
  //       return item;
  //     });

  //     const uniqueNumbers = new Set();
  //     const uniqueData = [];

  //     for (const item of updatedData) {
  //       if (!uniqueNumbers.has(item.number)) {
  //         uniqueNumbers.add(item.number);
  //         uniqueData.push(item);
  //       }
  //     }
  //     csvValues(uniqueData);
  //     setCsvValues(uniqueData);

  //     csvValues(updatedData);
  //     setCsvValues(updatedData);
  //   }
  //   setAddCountryCode(false);
  //   setIsCountryCodeButton(true);
  // };

  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [duplicateNumbers, setDuplicateNumbers] = useState([]);

  const handleRemoveDuplicatesChange = (e) => {
    if (e.target.checked) {
      const duplicateNums = findDuplicateNumbers(csvvalues);
      if (duplicateNums.length > 0) {
        setDuplicateNumbers(duplicateNums);
        setShowDuplicateDialog(true);
      }
    }
  };

  const findDuplicateNumbers = (data) => {
    const numbers = data.map((item) => item.number);
    const uniqueNumbers = new Set(numbers);
    const duplicateNumbers = numbers.filter((item) => {
      if (uniqueNumbers.has(item)) {
        uniqueNumbers.delete(item);
        return false;
      }
      return true;
    });
    return duplicateNumbers;
  };

  const handleApproveDuplicates = () => {
    const uniqueData = csvvalues.filter((item, index) => {
      return (
        csvvalues.findIndex((elem) => elem.number === item.number) === index
      );
    });

    setCsvValues(uniqueData);

    const updatedDuplicates = findDuplicateNumbers(uniqueData);
    setDuplicateNumbers(updatedDuplicates);

    setShowDuplicateDialog(false);
  };

  const resetCsvValues = () => {
    // setDuplicateCsvCount(0);
    setRows([initialRowData]);
    csvValues([{}]);
    setCsvValues([]);
    setDuplicateNumbers([]);
    resetValue("csv");
    // setSelectedCountryCode("91");
    fileInputRef.current.value = null;
  };
  const totalCount = csvvalues.length + userList.length;
  console.log(state.id);
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-9">
          {/* <button
            style={{
              cursor: "pointer",
              backgroundColor: "#e2e6ea",
              border: "none",
            }}
            className="nav-font p-2 m-auto rounded mt-2"
            onClick={() =>
              Navigate("/projects", {
                state: { id: state.id, function: state.function },
              })
            }
          >
            <ArrowLeft size={20} />
          </button> */}
          {project?.completStatus === "FAILED" ? (
            <></>
          ) : (
            <>
              <div className="d-flex gap-5 justify-content-between">
                <label className="lable-fw-size gap-2">
                  Import Numbers <span className="text-danger">*</span>{" "}
                </label>
                {/* {csvvalues.length > 0 || rows[0].number !== "" ? (
                  <label className="lable-fw-size gap-2">
                    Add Country Code <span className="text-danger">*</span>{" "}
                  </label>
                ) : (
                  ""
                )} */}
                <label className="lable-fw-size"></label>
              </div>
              <div className="d-flex gap-5 justify-content-between">
                <div className="d-flex gap-2 mt-2">
                  <div className="btn-primary btn-file btn-gradient-primary nav-font border rounded next-btn-padding">
                    CSV / XLSX
                    <input
                      ref={fileInputRef}
                      type="file"
                      name="csv"
                      accept=".csv, .xlsx"
                      onChange={handleFormData("csv")}
                    />
                  </div>
                  <div
                    onClick={() => setIsSelectFromManual(!isSelectFromManual)}
                  >
                    <button className="btn-gradient-primary nav-font border rounded next-btn-padding">
                      Manual
                    </button>
                  </div>
                </div>
                <div className="mt-2">
                  <p>Total Number: {totalCount}</p>
                </div>
                <div className="mt-2">
                  {duplicateNumbers.length >= 0 && (
                    <p
                      className={`text-${
                        duplicateNumbers.length > 0 ? "danger" : "default"
                      }`}
                    >
                      Duplicate Number: {duplicateNumbers.length}
                    </p>
                  )}
                </div>

                {csvvalues.length > 0 || rows[0].number !== "" ? (
                  <>
                    {/* <div className="d-flex gap-2 mt-2 ">
                      <div className="zip-react-tel-input ">
                        <PhoneInput
                          country={"in"}
                          enableSearch={true}
                          value={selectedCountryCode}
                          onChange={(phone) => setSelectedCountryCode(phone)}
                          onSelect={handleSelectCountry}
                        />
                      </div>
                      <div onClick={handleAddCountryCode}>
                        <button
                          disabled={isCountryCodeButton}
                          className="btn-gradient-primary nav-font border rounded next-btn-padding"
                        >
                          Add
                        </button>
                      </div>
                    </div> */}
                    <div className="mt-2">
                      <button
                        className="btn-gradient-primary nav-font border rounded next-btn-padding"
                        onClick={() => resetCsvValues()}
                      >
                        Clear
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-2">
                <input
                  type="checkbox"
                  name="isMessage"
                  checked={values.isMessage}
                  onChange={handleFormData("isMessage")}
                />
                <label className="lable-fw-size ms-2">Only Message</label>
              </div>

              <div className="mt-2">
                <input
                  type="checkbox"
                  name="removeDuplicates"
                  onChange={handleRemoveDuplicatesChange}
                  checked={showDuplicateDialog}
                />
                <label className="lable-fw-size ms-2">Remove Duplicates</label>
              </div>
            </>
          )}

          {showDuplicateDialog && (
            <Modal
              show={showDuplicateDialog}
              onHide={() => setShowDuplicateDialog(false)}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Duplicate Numbers Found</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>The following numbers are duplicated:</p>
                <ul>
                  {duplicateNumbers.map((number, index) => (
                    <li key={index}>{number}</li>
                  ))}
                </ul>
                <p>Do you want to proceed and remove them?</p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowDuplicateDialog(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleApproveDuplicates}
                >
                  Approve
                </button>
              </Modal.Footer>
            </Modal>
          )}
          {project?.completStatus === "FAILED" ? (
            <>
              <div className="mt-3">
                {userList?.length > 0 && (
                  <div className="md-p-5 mt-1">
                    <div
                      className="table-responsive text-wrap table-Fixed"
                      style={{
                        height: "518px",
                        overflowY: "auto",
                        position: "relative",
                        zIndex: 1,
                        boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)",
                      }}
                    >
                      <table className="table table-bordered table-fixed">
                        <thead className="text-color-primary">
                          <tr>
                            {[
                              "Name",
                              "Number",
                              "var1",
                              "var2",
                              "var3",
                              "var4",
                              "var5",
                              "var6",
                              "var7",
                              "Edit",
                            ].map((rows, index) => {
                              return (
                                <th
                                  scope="col"
                                  key={index}
                                  className="lable-fw-size"
                                >
                                  {rows}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {userList.map((value, index) => {
                            return (
                              <tr className="lable-fw-size">
                                <td>{value?.name}</td>
                                <td>{value?.number}</td>
                                <td>{value?.var1}</td>
                                <td>{value?.var2}</td>
                                <td>{value?.var3}</td>
                                <td>{value?.var4}</td>
                                <td>{value?.var5}</td>
                                <td>{value?.var6}</td>
                                <td>{value?.var7}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn-gradient-primary border rounded"
                                    onClick={() => {
                                      setEditNumberStatus(true);
                                      setEditNumber(value);
                                      setMobileNo(value.number);
                                    }}
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className=" mt-3">
                {csvvalues?.length ? (
                  <>
                    <div className="md-p-5 mt-1">
                      <div
                        className="table-responsive text-wrap table-Fixed"
                        style={{
                          height: "518px",
                          overflowY: "auto",
                          position: "relative",
                          zIndex: 1,
                          boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)",
                        }}
                      >
                        <table className="table table-bordered table-fixed">
                          <thead className="text-color-primary">
                            <tr>
                              {[
                                "Name",
                                "Number",
                                "var1",
                                "var2",
                                "var3",
                                "var4",
                                "var5",
                                "var6",
                                "var7",
                              ].map((rows, index) => {
                                return (
                                  <th
                                    scope="col"
                                    key={index}
                                    className="lable-fw-size"
                                  >
                                    {rows}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {csvvalues.map((value, index) => {
                              return (
                                <tr key={index} className="lable-fw-size">
                                  {Object.keys(value).map((val, i) => {
                                    return <td key={i}>{value[val]}</td>;
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="container m-auto text-center"
                      style={{
                        padding: "50px",
                        color: "#9c9fa6",
                        borderStyle: "dashed",
                        borderWidth: "1.5px",
                      }}
                    >
                      <img
                        src={noCsvGif}
                        alt="No project"
                        className="mt-1"
                        width={"45%"}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <div className="mt-2 d-flex justify-content-between">
            {/* <div style={{ color: "red" }}>
              {duplicateCsvCount > 0
                ? `${duplicateCsvCount} duplicate number has been deleted`
                : ""}
            </div> */}
            <button
              type="submit"
              className="btn-gradient-primary nav-font border rounded next-btn-padding"
              onClick={() => submitFormData()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      {isSelectFromManual && (
        <Modal
          show={isSelectFromManual}
          centered
          onHide={() => setIsSelectFromManual(false)}
          size="lg"
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto page-title">
              Manual Import
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <form onSubmit={handleSubmit}>
                <table className="table-responsive">
                  <thead>
                    <tr>
                      {Object.keys(initialRowData).map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.keys(initialRowData).map((key) => (
                          <td key={key}>
                            {/* {key === "number" ? (
                              <div className="input-group">
                                <PhoneInput
                                  country={"in"} // Set the default country code to India
                                  value={row[key]}
                                  onChange={(phone) =>
                                    handleChange(
                                      { target: { name: key, value: phone } },
                                      rowIndex
                                    )
                                  }
                                  onSelect={handleSelectCountry}
                                />
                              </div>
                            ) : ( */}
                            <input
                              type="text"
                              className="form-control"
                              name={key}
                              value={row[key]}
                              onChange={(e) => handleChange(e, rowIndex)}
                            />
                            {/* )} */}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex gap-2">
                  <button
                    type="button"
                    className="btn-gradient-primary border rounded p-2 mt-2"
                    onClick={handleAddRow}
                  >
                    Add New
                  </button>
                  {rows.length > 1 && (
                    <button
                      type="button"
                      className="btn btn-danger mt-2"
                      onClick={handleDeleteAllRows}
                    >
                      Clear
                    </button>
                  )}
                  <button
                    type="submit"
                    className="btn-gradient-primary border rounded p-2 mt-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {editNumberStatus && (
        <Modal
          show={editNumberStatus}
          onHide={() => setEditNumberStatus(false)}
          centered
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto page-title">
              Edit Phone Number
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group md-4">
              <label className="lable-fw-size">
                Mobile No <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="tel"
                id="mobile_no"
                className="form-control form-fs form-label-border mt-1"
                name="mobile_no"
                maxLength="12"
                defaultValue={mobileNo}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                }}
              ></input>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-light fw-bold form-fs p-2 border rounded"
              onClick={() => setEditNumberStatus(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-gradient-primary form-fs p-2 border rounded"
              onClick={async () => {
                if (mobileNo !== "") {
                  const { data } = await API.patch(
                    `project/update-sended-user-number`,
                    {
                      number: mobileNo,
                      id: editNumber._id,
                    }
                  );
                  // console.log("=data", data);
                  if (data.success) {
                    toast.success(data.message);
                    failedUserList();
                    setEditNumberStatus(false);
                  } else {
                    setEditNumberStatus(false);
                    toast.error("Network Error");
                  }
                }
              }}
            >
              Continue
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {isNext && (
        <SendFailedFileModel
          isShow={isNext}
          csvVariables={userList}
          projectId={project._id}
        />
      )}
    </div>
  );
}

export default Step1;
