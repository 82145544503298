import React from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { API } from "../../API";
import { ArrowLeft } from "react-bootstrap-icons";

const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      if (data.password !== data.confirmPassword) {
        return toast.error("password doesn't match.");
      }

      // register
      delete data.confirmPassword;
      const register = await API.post("/auth/signup", data);
      const response = register.data;
      if (response.success) {
        toast.success(response.message);
        navigate("/manage-user");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="container mt-3 container-main">
        <h3 className="page-title">Create User</h3>
        <div>
          <button
            style={{
              cursor: "pointer",
              backgroundColor: "#e2e6ea",
              border: "none",
            }}
            className="nav-font p-2 m-auto rounded mt-2"
            onClick={() => navigate("/manage-user")}
          >
            {" "}
            <ArrowLeft size={20} />
          </button>
        </div>
        <div className="card-trans ">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-9 p-4 bg-white rounded">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="lable-fw-size">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("email", {
                      required: true,
                    })}
                    type="email"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter email address"
                  />
                  {errors.email && (
                    <span className="form-fs text-danger">
                      Email is required
                    </span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    FullName <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("fullName", { required: true })}
                    type="text"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Fullname"
                  />
                  {errors.fullName && (
                    <span className="form-fs text-danger">
                      FullName is required
                    </span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    PhoneNumber <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("phoneNumber", { required: true })}
                    type="number"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter PhoneNumber"
                  />
                  {errors.phoneNumber && (
                    <span className="form-fs text-danger">
                      phoneNumber is required
                    </span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">Charge </label>
                  <input
                    {...register("charge")}
                    type="number"
                    defaultValue="5"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Charge"
                  />
                  {errors.charge && (
                    <span className="form-fs text-danger">
                      charge is required
                    </span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Reminder Message Charge
                  </label>
                  <input
                    {...register("reminder_message_charge")}
                    type="number"
                    defaultValue="5"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Charge"
                  />
                  {errors.charge && (
                    <span className="form-fs text-danger">
                      Reminder Message Charge is required
                    </span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Role <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control mt-1 form-fs form-label-border p-2"
                    aria-label="Default select example"
                    {...register("userType")}
                  >
                    <option value="USER" selected>
                      User
                    </option>
                    <option value="CUSTOMER_CARE">Customer Care</option>
                    <option value="ADMIN">Admin</option>
                  </select>
                </div>

                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("password", { required: true })}
                    type="password"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter Password"
                  />
                  {errors.password && (
                    <span className="form-fs text-danger">
                      Password is required
                    </span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("confirmPassword", { required: true })}
                    type="password"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    placeholder="Enter confirm Password"
                  />
                  {errors.confirmPassword && (
                    <span className="form-fs text-danger">
                      Confirm Password is required
                    </span>
                  )}
                </div>
                <button type="submit" className="btn btn-gradient-primary mt-4">
                  Create
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <Toaster /> */}
    </>
  );
};
export default Signup;
