import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../API";
import { ArrowLeft } from "react-bootstrap-icons";

const EditUser = () => {
  let { state } = useLocation();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm({
    defaultValues: async () =>
      await (
        await API.get(`/auth/user/${state.id}`)
      ).data.data,
  });
  const onSubmit = async (data) => {
    try {
      delete data.updatedAt;
      delete data.createdAt;
      const response = await (await API.patch("/auth/user", data)).data;
      if (response.success) {
        toast.success(response.message);
        navigate("/manage-user");
      }
    } catch (error) {}
  };
  return (
    <>
      <div className="container mt-3 container-main">
        <h3 className="page-title">Edit User</h3>
        <div>
          <button
            style={{
              cursor: "pointer",
              backgroundColor: "#e2e6ea",
              border: "none",
            }}
            className="nav-font p-2 m-auto rounded mt-2"
            onClick={() => navigate("/manage-user")}
          >
            {" "}
            <ArrowLeft size={20} />
          </button>
        </div>
        <div className="card-trans">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-9 p-4 bg-white rounded">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="lable-fw-size">Email</label>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    className="form-control mt-1 form-fs form-label-border p-2"
                    disabled
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">FullName</label>
                  <input
                    {...register("fullName", { required: true })}
                    type="text"
                    className="form-control mt-1 form-fs form-label-border p-2"
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">Charge</label>
                  <input
                    {...register("charge", { required: true })}
                    type="number"
                    step="any"
                    min="0"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    className="form-control mt-1 form-fs form-label-border p-2"
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">
                    Reminder Message Charge
                  </label>
                  <input
                    {...register("reminder_message_charge", { required: true })}
                    type="number"
                    step="any"
                    min="0"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    className="form-control mt-1 form-fs form-label-border p-2"
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="lable-fw-size">Phone Number</label>
                  <input
                    {...register("phoneNumber", { required: true })}
                    type="text"
                    className="form-control mt-1 form-fs form-label-border p-2"
                  />
                </div>
                <button
                  type="submit"
                  className="border rounded btn-gradient-primary mt-3 p-2"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditUser;
