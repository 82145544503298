/* eslint-disable jsx-a11y/anchor-is-valid */
import toast from "react-hot-toast";
import { useNavigate, Link } from "react-router-dom";
import { Wallet, Bell, Power, FiletypePdf, Send } from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { API } from "../API";
import moment from "moment";
import useAuth from "../hooks/useAuth";
import { NavLink } from "react-router-dom";
import logo from "../images/newLogo.svg";

export const Header = () => {
  const { auth, setAuth } = useAuth();
  const [state, setState] = useState({
    menu: false,
    isOpen: false,
    homeLinkClass: "nav-item nav-link",
    aboutLinkClass: "nav-item nav-link",
    menuClass: "",
  });
  const [notificationStatus, setNotificationStatus] = useState(false);
  const [rechageButtonDisabled, setRechageButtonDisabled] = useState(false);
  const [acceptData, setAcceptData] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notifyNumber, setNotifiyNumber] = useState(0);
  const [isLoggedInUser, setIsLoggedInUser] = useState({});
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log("user", user);
  console.log(confirmModal);
  useEffect(() => {
    async function getUser() {
      if (user?._id) {
        const { data } = await API.get(`/auth/user/${user._id}`);
        setIsLoggedInUser(data.data);
        localStorage.setItem("user", JSON.stringify(data.data));
      }
    }
    getUser();
  }, [user._id]);
  const toggleMenu = () => {
    setState({
      ...state,
      menu: !state.menu,
    });
  };
  // console.log("=authIN Header Page", auth);
  useEffect(() => {
    async function getNotification() {
      if (user?.userType !== "USER") {
        const { data } = await API.get(
          "/recharge-request/new-recharge-request"
        );
        setNotifiyNumber(data.data);
      }
    }
    getNotification();
  }, [user?.userType]);
  const show = state.menu ? "show" : "";
  const getRequestBalanceData = async (req, res) => {
    const { data } = await API.get(`/recharge-request/get-recharge-request`);
    setNotificationData(data.data);
  };
  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    setAuth({});
    toast.success("logout successFully.");
    navigate("/login");
  };
  // console.log("=state.homeLinkClass", state.homeLinkClass);
  return (
    <>
      <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-white border ">
        <div className="container-fluid">
          <div className="d-flex d-lg-none justify-content-between align-items-center w-100">
            <NavLink
              className={`${state.homeLinkClass} nav-font p-0 d-flex align-items-center`}
              to={
                user?.userType === "ADMIN"
                  ? "/admin-dashboard"
                  : user?.userType === "USER"
                  ? "/user-dashboard"
                  : "/customer-care-dashboard"
              }
              style={({ isActive }) => ({
                color: isActive ? "#006aa8" : "",
              })}
            >
              <img
                src={logo}
                alt="logo"
                width={55}
                height={55}
                className="m-auto d-block"
              />
              <div
                className="align-items-center fw-bold"
                style={{ fontSize: "18px", color: "#006aa8" }}
              >
                ZeroPaper
              </div>
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div
            className={
              "justify-content-between collapse navbar-collapse " + show
            }
          >
            <ul className="navbar-nav align-items-center gap-4">
              <li className="nav-item d-none d-lg-block">
                {" "}
                <NavLink
                  className={`${state.homeLinkClass} nav-font p-0 d-flex align-items-center`}
                  exact={"true"}
                  to={
                    user?.userType === "ADMIN"
                      ? "/admin-dashboard"
                      : user?.userType === "USER"
                      ? "/user-dashboard"
                      : "/customer-care-dashboard"
                  }
                  style={({ isActive }) => ({
                    color: isActive ? "#006aa8" : "",
                  })}
                >
                  <img
                    src={logo}
                    alt="logo"
                    width={55}
                    height={55}
                    className="m-auto d-block"
                  />
                  <div
                    className="align-items-center fw-bold"
                    style={{ fontSize: "18px", color: "#006aa8" }}
                  >
                    ZeroPaper
                  </div>
                </NavLink>
              </li>

              {user?.userType === "USER" && (
                <>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link nav-font"
                          to={"/projects"}
                          exact={"true"}
                          style={({ isActive }) => ({
                            color: isActive ? "#006aa8" : "",
                          })}
                        >
                          Projects
                        </NavLink>
                      </li>
                  {/* <li className="nav-item">
                    <NavLink
                      className="nav-link nav-font"
                      to={"/functions"}
                      exact={"true"}
                      style={({ isActive }) => ({
                        color: isActive ? "#006aa8" : "",
                      })}
                    >
                      Functions
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      className="nav-link nav-font"
                      to={"/archive"}
                      exact={"true"}
                      style={({ isActive }) => ({
                        color: isActive ? "#006aa8" : "",
                      })}
                    >
                      Archive
                    </NavLink>
                  </li>
                  {authToken && (
                    <>
                      <li className="nav-item nav-font ms-lg-2">
                        <div
                          className={`d-flex flex-row align-items-center ${
                            !state.menu ? "justify-content-center" : ""
                          }`}
                        >
                          <div
                            style={{
                              marginLeft: `${!state.menu ? "5px" : ""}`,
                            }}
                          >
                            <Wallet size={20} color="#9c9fa6" />
                          </div>
                          <div
                            className="nav-link"
                            style={{ marginLeft: `${state.menu ? "5px" : ""}` }}
                          >
                            <span>
                              {isLoggedInUser.total_balance
                                ? isLoggedInUser?.total_balance.toFixed(2)
                                : auth?.user?.total_balance.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li className="nav-item nav-font  ms-lg-2">
                        <div
                          className={`d-flex flex-row align-items-center ${
                            !state.menu ? "justify-content-center" : ""
                          }`}
                        >
                          <div
                            style={{
                              marginLeft: `${!state.menu ? "5px" : ""}`,
                            }}
                          >
                            <FiletypePdf size={20} color="#9c9fa6" />
                          </div>
                          <div
                            className="nav-link"
                            style={{ marginLeft: `${state.menu ? "5px" : ""}` }}
                          >
                            <span>
                              {isLoggedInUser.total_balance
                                ? Math.floor(
                                    isLoggedInUser?.total_balance /
                                      isLoggedInUser.charge
                                  )
                                : Math.floor(
                                    auth?.user?.total_balance /
                                      auth?.user?.charge
                                  )}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li className="nav-item nav-font ms-lg-2">
                        <div
                          className={`d-flex flex-row align-items-center ${
                            !state.menu ? "justify-content-center" : ""
                          }`}
                        >
                          <div
                            style={{
                              marginLeft: `${!state.menu ? "5px" : ""}`,
                            }}
                          >
                            <Send size={20} color="#9c9fa6" />
                          </div>
                          <div
                            className="nav-link"
                            style={{ marginLeft: `${state.menu ? "5px" : ""}` }}
                          >
                            <span>
                              {isLoggedInUser.total_balance
                                ? Math.floor(
                                    isLoggedInUser?.total_balance /
                                      isLoggedInUser.reminder_message_charge
                                  )
                                : Math.floor(
                                    auth?.user?.total_balance /
                                      auth?.user?.reminder_message_charge
                                  )}
                            </span>
                          </div>
                        </div>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
            <ul className="navbar-nav">
              {authToken ? (
                <>
                  {isLoggedInUser.userType !== "USER" && (
                    <li className="nav-item nav-font">
                      <div
                        className="nav-link mt-1"
                        style={{
                          marginLeft: `${!state.menu ? "5px" : ""}`,
                          cursor: "pointer",
                        }}
                        onClick={async () => {
                          setNotificationStatus(true);
                          getRequestBalanceData();
                        }}
                      >
                        <span>
                          <Bell size={20} color="#9c9fa6" />
                        </span>
                        {notifyNumber > 0 && (
                          <>
                            <span
                              className="notify-icon"
                              style={{ backgroundColor: "#fe7c96" }}
                            >
                              {notifyNumber}
                            </span>
                          </>
                        )}
                      </div>
                    </li>
                  )}
                  <li className="nav-item nav-font">
                    <Link className="nav-link" to={"/userprofile"}>
                      <div
                        className="rounded-circle text-light text-center "
                        style={{
                          lineHeight: "30px",
                          height: "30px",
                          width: "30px",
                          backgroundColor: "#006aa8",
                        }}
                      >
                        {isLoggedInUser?.fullName
                          ? isLoggedInUser?.fullName?.charAt(0)
                          : auth?.user?.fullName?.charAt(0)}
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item nav-font">
                    <div onClick={() => logout()} className="nav-link">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        style={{
                          lineHeight: "30px",
                          cursor: "pointer",
                          width: "30px",
                          height: "30px",
                          color: "#9c9fa6",
                        }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                        />
                      </svg> */}
                      <div style={{ marginTop: "3px" }}>
                        <Power size={20} color="#9c9fa6" />
                      </div>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link to={"/login"}>Login</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
      {/* <Toaster /> */}
      {notificationStatus && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={notificationStatus}
          onHide={() => setNotificationStatus(false)}
        >
          <Modal.Header className="px-4" closeButton>
            <Modal.Title className="ms-auto fw-bold">
              Requested Amount
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="table-responsive text-wrap mt-3"
              style={{
                height: "350px",
                overflowY: "auto",
                position: "relative",
                zIndex: 1,
              }}
            >
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Update By</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {notificationData.length > 0 ? (
                    notificationData.map((value, index) => {
                      return (
                        <tr key={index} className="lable-fw-size">
                          <td>{value?.userId?.fullName}</td>
                          <td>{value?.amount}</td>
                          <td>
                            {value.requestStatus === "PENDING" ? (
                              <>
                                <div className="d-flex">
                                  <button
                                    className="btn-gradient-primary nav-font p-1 border absborder rounded"
                                    style={{
                                      fontSize: "12px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setAcceptData(value);
                                      setConfirmModal(true);
                                      setNotificationStatus(false);
                                    }}
                                  >
                                    Accept
                                  </button>
                                  <button
                                    className="btn-gradient-primary nav-font border rounded p-1"
                                    style={{
                                      marginLeft: "4px",
                                      fontSize: "12px",
                                      cursor: "pointer",
                                    }}
                                    onClick={async () => {
                                      const { data } = await API.post(
                                        "/recharge-request/update-status",
                                        {
                                          id: value?._id,
                                          status: "REJECTED",
                                        }
                                      );
                                      if (data.success) {
                                        setNotificationStatus(false);
                                        getRequestBalanceData();
                                        toast.success(data.message);
                                      } else {
                                        toast.error("Network Error");
                                      }
                                    }}
                                  >
                                    Reject
                                  </button>
                                </div>
                              </>
                            ) : value?.requestStatus === "ACCEPTED" ? (
                              <img
                                alt="success"
                                src={window.location.origin + "/checkmark.png"}
                                width={20}
                                height={20}
                              />
                            ) : value?.requestStatus === "REJECTED" ? (
                              <img
                                alt="fail"
                                src={window.location.origin + "/close.png"}
                                width={20}
                                height={20}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{value.statusUpdateBy?.fullName}</td>
                          <td>
                            {" "}
                            {moment(value.createdAt).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div
                      className="mt-3 container m-auto text-center border rounded "
                      style={{ padding: "50px" }}
                    >
                      <div className="fw-bold">No Record Found</div>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
      {confirmModal && (
        <Modal
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={confirmModal}
          onHide={() => setConfirmModal(false)}
        >
          <Modal.Body>
            <div
              className="table-responsive text-wrap text-center p-3"
              style={{
                overflowY: "auto",
                position: "relative",
                zIndex: 1,
              }}
            >
              <div>Are you sure?</div>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn-gradient-primary nav-font border rounded py-1 px-3"
                  style={{
                    marginLeft: "4px",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  onClick={async () => setConfirmModal(false)}
                >
                  NO
                </button>
                <button
                  disabled={rechageButtonDisabled}
                  className="btn-gradient-primary nav-font border rounded py-1 px-3 ms-2"
                  style={{ fontSize: "12px", cursor: "pointer" }}
                  onClick={async () => {
                    setRechageButtonDisabled(true);
                    const { data } = await API.post("/credit/add", {
                      amount: acceptData?.amount,
                      user: acceptData?.userId?._id,
                      description: "Added by admin",
                    });
                    if (data.success) {
                      const { data } = await API.post(
                        "/recharge-request/update-status",
                        {
                          id: acceptData?._id,
                          status: "ACCEPTED",
                        }
                      );
                      if (data.success) {
                        setConfirmModal(false);
                        // setNotificationStatus(false);
                        getRequestBalanceData();
                        toast.success(data.message);
                      } else {
                        toast.error("Network Error");
                      }
                    } else {
                      toast.error("Network Error");
                    }
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
