import React, { useState, useRef, useEffect } from "react";
import { TextComponent } from "./TextComponent";
import { getMovePosition } from "../../utils/helpers";

export const Text = ({
  x,
  y,
  text,
  width,
  height,
  lineHeight,
  size,
  fontFamily,
  pageHeight,
  pageWidth,
  updateTextAttachment,
  color,
}) => {
  const inputRef = useRef(null);
  const [mouseDown, setMouseDown] = useState(false);
  const [positionTop, setPositionTop] = useState(y);
  const [positionLeft, setPositionLeft] = useState(x);
  const [operation, setOperation] = useState("NO_MOVEMENT");
  const [textMode, setTextMode] = useState("command");
  useEffect(() => {
    setPositionTop(y);
    setPositionLeft(x);
  }, [x, y]);
  const handleMouseMove = (event) => {
    event.preventDefault();

    if (mouseDown) {
      const { top, left } = getMovePosition(
        positionLeft,
        positionTop,
        event.movementX,
        event.movementY,
        width,
        height,
        pageWidth,
        pageHeight
      );

      setPositionTop(top);
      setPositionLeft(left);
    }
  };

  const handleMousedown = (event) => {
    event.preventDefault();
    if (textMode !== 'command') {
      return;
    }

    setMouseDown(true);
    setOperation('MOVE');
  };

  const handleMouseUp = (event) => {
    event.preventDefault();
    if (textMode !== 'command') {
      return;
    }

    setMouseDown(false);

    if (operation === 'MOVE') {
      const { top, left } = getMovePosition(
        positionLeft,
        positionTop,
        event.movementX,
        event.movementY,
        width,
        height,
        pageWidth,
        pageHeight
      );

      updateTextAttachment({
        x: left,
        y: top,
      });
    }

    // if (operation === DragActions.SCALE) {
    //     updateTextObject({
    //         x: positionLeft,
    //         y: positionTop,
    //     });

    // }

    setOperation('NO_MOVEMENT');
  };

  const handleMouseOut = (event) => {
    if (operation === 'MOVE') {
      handleMouseUp(event);
    }
  };



  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove]);














  // const onChangeText = (event) => {
  //   const value = event.currentTarget.value;
  //   setContent(value);
  // };
  // console.log("=x", x);
  // console.log("=y", y);
  // console.log("=positionTop", positionTop);
  // console.log("=positionLeft", positionLeft);
  return (
    <TextComponent
      text={text}
      width={width}
      height={height}
      mode={textMode}
      size={size}
      color={color}
      lineHeight={lineHeight}
      inputRef={inputRef}
      fontFamily={fontFamily}
      positionTop={positionTop}
      // onChangeText={onChangeText}
      positionLeft={positionLeft}
   
      handleMouseDown={handleMousedown}
    />
  );
};
