import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../API";
import { ArrowLeft } from "react-bootstrap-icons";
import { PaginationControl } from "react-bootstrap-pagination-control";

const pagelimit = 10;

const ViewTransaction = (props) => {
  const [allTransaction, setAllTransaction] = useState([]);
  let { state } = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState(1);
  const getUser = useCallback(async () => {
    const user = await API.post(`/credit/view-transaction/${state.id}`, {
      page: page,
      limit: pagelimit,
    });
    const { success, data } = user.data;
    if (success) {
      setAllTransaction(data.transactions);
      setTotalTransactions(data.totalTransactions);
    }
    // setUser(response.data);
  }, [page, state.id]);
  useEffect(() => {
    getUser();
  }, [getUser]);
  return (
    <>
      <div className="container mt-3 container-main">
        <div>
          <button
            style={{
              cursor: "pointer",
              backgroundColor: "#e2e6ea",
              border: "none",
            }}
            className="nav-font p-2 m-auto rounded mt-2"
            onClick={() => navigate("/manage-user")}
          >
            {" "}
            <ArrowLeft size={20} />
          </button>
        </div>
        <h3 className="page-title mt-3">All Transactions</h3>

        <div className="mt-3 bg-white rounded card-trans p-md-4 p-3 ">
          {allTransaction.length > 0 ? (
            <>
              <div className="table-responsive text-wrap mt-4 table-Fixed">
                <table className="table table-fixed">
                  <thead className="table">
                    <tr className="lable-fw-size">
                      <th scope="col">Added By</th>
                      <th scope="col">Account Name</th>
                      <th scope="col">Balance</th>
                      <th scope="col">Type</th>
                      <th scope="col">Description</th>
                      <th scope="col">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allTransaction?.map((el, key) => {
                      return (
                        <React.Fragment key={key}>
                          <tr className="lable-fw-size">
                            <td>{el?.addedBy?.fullName}</td>
                            <td>{el?.user?.fullName}</td>
                            <td>{el.balance}</td>
                            <td>{el.type}</td>
                            <td>{el.description}</td>
                            <td>
                              {moment(el.createdAt).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <PaginationControl
                page={page}
                between={4}
                total={totalTransactions}
                limit={pagelimit}
                changePage={(page) => {
                  setPage(page);
                }}
              />
            </>
          ) : (
            <div
              className="mt-3 container m-auto text-center border rounded "
              style={{
                padding: "50px",
                color: "#9c9fa6",
                borderStyle: "dashed",
                borderWidth: "1.5px",
              }}
            >
              <div className="fw-bold">No Record Found</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewTransaction;
