import React from "react";
import ContentLoader from "react-content-loader";

export const TableLoader = () => {
  const Loader = (props) => {
    return (
      <ContentLoader
        height={40}
        width={1200}
        speed={2}
        primaryColor="#d9d9d9"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect x="10" y="13" rx="4" ry="4" width="50" height="12" />
        <rect x="130" y="13" rx="6" ry="6" width="50" height="12" />
        <rect x="320" y="13" rx="6" ry="6" width="50" height="12" />
        <rect x="530" y="13" rx="6" ry="6" width="50" height="12" />
        <rect x="720" y="13" rx="6" ry="6" width="50" height="12" />
        <rect x="880" y="13" rx="6" ry="6" width="100" height="12" />
        <rect x="1050" y="13" rx="6" ry="6" width="100" height="12" />
      </ContentLoader>
    );
  };

  return (
    <>
      {Array(10)
        .fill("")
        .map((e, i) => (
          <Loader key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
        ))}
    </>
  );
};
