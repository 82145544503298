import { useForm } from "react-hook-form";
import { API } from "../API";
import toast from "react-hot-toast";

export const Password = () => {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    try {
      if (data.newPassword !== data.confirmPassword)
        return toast.error("new password and confirm password doesn't match");

      delete data.confirmPassword;
      const updatePassword = await API.post("/auth/updatePassword", data);
      const response = updatePassword.data;
      if (response.success) toast.success(response.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-12">
          <form className="form-floating" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label className="lable-fw-size">Current Password</label>
              <input
                {...register("currentPassword")}
                type="password"
                className="form-control mt-1 form-fs form-label-border p-2"
              />
            </div>
            <div className="mt-3">
              <label className="lable-fw-size">New Password</label>
              <input
                {...register("newPassword")}
                type="password"
                className="form-control mt-1 form-fs form-label-border p-2"
              />
            </div>
            <div className="mt-3 mb-3">
              <label className="lable-fw-size">Confirm New Password</label>
              <input
                {...register("confirmPassword")}
                type="password"
                className="form-control mt-1 form-fs form-label-border p-2"
              />
            </div>
            <div className="lable-fw-size">
              <button
                type="submit"
                className="btn-gradient-primary mt-3 border rounded p-2 px-3"
                value="Update Password"
              >
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
