import React, { useCallback, useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import { API } from "../API";
import { useLocation, useNavigate } from "react-router-dom";
import Step1 from "./Stepper/Step1";
import { toast } from "react-hot-toast";
import { getAsset, prepareAssets } from "../utils/prepareAssests";
import Step2 from "./Stepper/Step2";
import Step3 from "./Stepper/Step3";
import { EditPdfProject } from "./EditPdfProject";
import { EditImageProject } from "./EditImageProject";
import { SendMessageModel } from "./SendMessageModel";
prepareAssets();

const EditProject1 = (props) => {
  let { state } = useLocation();
  let navigate = useNavigate();
  const [step, setstep] = useState(0);
  const [project, setProject] = useState();
  const [userList, setUserList] = useState([]);
  const [pdfDocument, setPdfDocument] = useState();
  const [url, setUrl] = useState("");
  const [pdfEditorStatus, setPdfEditorStatus] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [formData, setFormData] = useState({
    csv: "",
    pdf: "",
    image: "",
    customMsg: "",
    delaySecond: 2,
    pdfDocument: "",
    isMessage: false,
    scheduleDate: null,
  });
  const nextStep = () => {
    if (formData.isMessage) {
      setstep(step + 2);
    } else {
      setstep(step + 1);
    }
  };
  const prevStep = () => {
    if (formData.isMessage) {
      setstep(step - 2);
    } else {
      setstep(step - 1);
    }
  };

  const getProject = useCallback(async () => {
    const result = await (await API.get(`/project/get/${state.id}`)).data.data;
    if (result?.completStatus === "SUCCEED")
      return navigate("/functions/project", {
        state: { id: result.type },
      });
    setProject(result);
    setFormData((prevState) => ({
      ...prevState,
      customMsg: result.message,
    }));
  }, [navigate, state.id]);

  const getAllFailedUserList = useCallback(async () => {
    if (project?.completStatus === "FAILED") {
      const { data } = await API.get(
        `/project/failed-project-users-list?projectId=${state.id}`
      );
      setUserList(data.data);
    }
  }, [project?.completStatus, state.id]);

  useEffect(() => {
    getProject();
  }, [getProject]);

  useEffect(() => {
    getAllFailedUserList();
  }, [getAllFailedUserList, project]);

  // useEffect(() => {
  //   async function updateProject() {
  //     // You can await here
  //     const projectId = JSON.parse(localStorage.getItem("projectId"));
  //     // console.log("=projectId>>>>>>>>>", projectId);
  //     if (projectId) {
  //       const { data } = await API.patch(
  //         `/project/project-status-change?projectId=${projectId}`
  //       );
  //       await API.get(`/whatsapp/logout?projectId=${projectId}`);
  //       if (data.success) {
  //         localStorage.removeItem("projectId");
  //       }
  //     }
  //   }
  //   updateProject();
  // }, []);

  const readAsImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
        image.src = e.target.result;
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  const readAsPDF = async (file) => {
    const pdfjsLib = await getAsset("pdfjsLib");
    const blob = new Blob([file]);
    const url = window.URL.createObjectURL(blob);
    return pdfjsLib.getDocument(url).promise;
  };

  const pdf = async (file) => {
    const isPDF = file.type === "application/pdf";

    try {
      if (isPDF) {
        const pdf = await readAsPDF(file);
        return {
          file,
          name: file.name,
          isPDF: true,
          pages: Array(pdf.numPages)
            .fill(0)
            .map((_, index) => pdf.getPage(index + 1)),
        };
      } else {
        const image = await readAsImage(file);
        return {
          file,
          name: file.name,
          isPDF: false,
          image,
        };
      }
    } catch (error) {
      toast.error(isPDF ? "Failed to load PDF" : "Failed to load image");
      throw new Error(isPDF ? "Failed to load PDF" : "Failed to load image");
    }
  };

  const handleInputData = (input) => async (e) => {
    // input value from the form
    if (e.target.type === "file") {
      input =
        e.target.files[0].type.split("/")[0] === "image"
          ? "image"
          : e.target.files[0].type === "application/pdf"
          ? "pdf"
          : "csv";
      e.target.name = input;
      if (e.target.name === "pdf") {
        const MAX_FILE_SIZE = 5120;
        var file = e.target.files[0];
        const fileSizeKiloBytes = file.size / 1024;
        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          toast.error("File size is greater than maximum limit");
          return;
        } else {
          setFormData({
            csv: formData.csv,
            pdf: formData.pdf,
            image: "",
            customMsg: formData.customMsg,
            delaySecond: formData.delaySecond,
            pdfDocument: formData.pdfDocument,
            isMessage: formData.isMessage,
          });
          const result = await pdf(e.target.files[0]);
          setFormData((prevState) => ({
            ...prevState,
            [input]: e.target.files[0],
            pdfDocument: result,
          }));
          setPdfDocument(result);
          setUrl(URL.createObjectURL(file));
        }
      } else if (e.target.name === "image") {
        setFormData({
          csv: formData.csv,
          pdf: "",
          image: formData.image,
          customMsg: formData.customMsg,
          delaySecond: formData.delaySecond,
          pdfDocument: formData.pdfDocument,
          isMessage: formData.isMessage,
        });
        let result = await pdf(e.target.files[0]);

        setFormData((prevState) => ({
          ...prevState,
          [input]: e.target.files[0],
          pdfDocument: result,
        }));
        result.url = URL.createObjectURL(e.target.files[0]);
        setPdfDocument(result);
        setUrl(URL.createObjectURL(e.target.files[0]));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [input]: e.target.files[0],
        }));
      }
    } else if (e.target.type === "number") {
      let value = parseInt(e.target.value);
      if (e.target.max && e.target.min) {
        e.target.value = value; // for 000 like input cleanup to 0
        let max = parseInt(e.target.max);
        let min = parseInt(e.target.min);
        if (value > max) e.target.value = e.target.max;
        if (value < min) e.target.value = e.target.min;
      }
      setFormData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
    } else if (e.target.type === "checkbox") {
      const { checked } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [input]: checked,
      }));
    } else {
      const { value } = e.target;
      //updating for data state taking previous state and then adding new value to create new object
      setFormData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
    }
  };

  const handleMsg = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      customMsg: e,
    }));
  };

  const handleCsvValue = (data) => {
    setCsvData(data);
  };

  const handleModel = (val) => {
    setPdfEditorStatus(val);
  };

  const onUpload = async () => {
    if (
      !formData.csv &&
      !formData.pdf &&
      !formData.image &&
      !formData.isMessage
    )
      return toast.error("Please attach all files");
    const form = new FormData();
    form.append("csvArray", JSON.stringify(csvData));
    form.append("csvFile", formData.csv);
    form.append("pdfFile", formData.pdf);
    form.append("image", formData.image);
    form.append("message", formData.customMsg ? formData.customMsg : "");
    form.append("projectId", project._id);
    form.append("delaySecond", formData.delaySecond);
    form.append("isOnlyMessage", formData.isMessage);
    form.append(
      "scheduledDate",
      formData.scheduleDate ? Date.parse(formData.scheduleDate) : ""
    );

    const { data } = await API.post("/project/upload-project-file", form);
    if (data.success) {
      toast.success(data.message);
      setPdfEditorStatus(true);
    } else {
      toast.error(data.message);
    }
  };

  const resetCsvValues = (name) => {
    if (name === "csv") {
      setFormData({
        csv: "",
        pdf: formData.pdf,
        image: formData.image,
        customMsg: formData.customMsg,
        delaySecond: formData.delaySecond,
        pdfDocument: formData.pdfDocument,
        isMessage: formData.isMessage,
        scheduleDate: formData.scheduleDate,
      });
      setCsvData([]);
    }
    if (name === "pdf") {
      setFormData({
        csv: formData.csv,
        pdf: "",
        image: "",
        customMsg: formData.customMsg,
        delaySecond: formData.delaySecond,
        pdfDocument: formData.pdfDocument,
        isMessage: formData.isMessage,
        scheduleDate: formData.scheduleDate,
      });
      setUrl("");
      setPdfDocument(null);
    }
  };
  const displayTab = () => {
    switch (step) {
      case 0:
        return (
          <Step1
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={formData}
            project={project}
            userList={userList}
            failedUserList={getAllFailedUserList}
            csvValues={handleCsvValue}
            resetValue={resetCsvValues}
          />
        );
      case 1:
        return (
          <Step2
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
            url={url}
            resetValue={resetCsvValues}
          />
        );
      case 2:
        return (
          <Step3
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
            project={project}
            userList={userList}
            handleMsg={handleMsg}
            csvData={csvData}
            onUpload={onUpload}
          />
        );
      default:
        return (
          <Step1
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={formData}
            project={project}
            userList={userList}
          />
        );
    }
  };
  return (
    <>
      {!pdfEditorStatus && (
        <div
          className="container"
          style={{
            boxShadow: "0 1px 10px 2px rgba(0,0,0,0.2)",
          }}
        >
          <div className="mt-3">
            <Stepper
              activeStep={step}
              styleConfig={{
                activeBgColor: "#006aa8",
                completedBgColor: "#ffbf23",
              }}
            >
              <Step label="Import Numbers" style={{ cursor: "auto" }} />
              <Step label="Upload PDF/Image" style={{ cursor: "auto" }} />
              <Step label="Upload" style={{ cursor: "auto" }} />
            </Stepper>
          </div>
          <div className="mt-3 bg-white rounded card-trans p-md-4 p-3">
            <div>{displayTab()}</div>
          </div>
        </div>
      )}
      {pdfEditorStatus && pdfDocument?.isPDF ? (
        <EditPdfProject
          pdfDocument={pdfDocument}
          varible={
            project?.completStatus === "FAILED" ? userList[0] : csvData[0]
          }
          csvVariables={
            project?.completStatus === "FAILED" ? userList : csvData
          }
          projectId={project._id}
          pdfFile={formData.pdf}
          image={formData.image}
          failedStatus={project?.completStatus === "FAILED" ? true : false}
          customMsg={formData?.customMsg}
          delaySecond={formData?.delaySecond}
        />
      ) : pdfEditorStatus && formData.isMessage ? (
        <SendMessageModel
          handlemodal={handleModel}
          isShow={pdfEditorStatus}
          csvVariables={csvData}
          customMsg={formData?.customMsg}
          projectId={project._id}
        />
      ) : (
        pdfEditorStatus && (
          <EditImageProject
            pdfDocument={pdfDocument}
            varible={
              project?.completStatus === "FAILED" ? userList[0] : csvData[0]
            }
            csvVariables={
              project?.completStatus === "FAILED" ? userList : csvData
            }
            projectId={project._id}
            pdfFile={formData.pdf}
            image={formData.image}
            failedStatus={project?.completStatus === "FAILED" ? true : false}
            customMsg={formData?.customMsg}
            delaySecond={formData?.delaySecond}
          />
        )
      )}
    </>
  );
};

export default EditProject1;
