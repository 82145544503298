import { API } from "../API";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";

export const AdminSettings = () => {
  const [setting, setSetting] = useState({});
  const [functions, setFunctions] = useState([]);

  const getAdminSetting = async () => {
    const { data } = await API.get("/admin-setting/");

    if (data.success) {
      setSetting(data.data);
      console.log("data", data.data);
      setFunctions(data.data.functions || []);
    } else {
      toast.error(data.message);
    }
  };
  useEffect(() => {
    getAdminSetting();
  }, []);

  const handleAddFunction = () => {
    setFunctions([...functions, { function: "", icon_url: "" }]);
  };

  const handleFunctionChange = (index, key, value) => {
    const newFunctions = [...functions];
    newFunctions[index][key] = value;
    setFunctions(newFunctions);
  };

  // const handleDeleteFunction = (index) => {
  //   const newFunctions = [...functions];
  //   newFunctions.splice(index, 1);
  //   setFunctions(newFunctions);
  // };

  const onSubmit = async () => {
    const hasError = functions.some(
      (func) =>
        func._id && (func.function.trim() === "" || func.icon_url.trim() === "")
    );

    if (hasError) {
      toast.error("Please fill all details");
    } else {
      const filtered = functions.filter((obj, i) => {
        return obj.function.trim() !== "" && obj.icon_url.trim() !== "";
      });
      const payload = {
        functions: filtered.map((func) => {
          const payloadFunction = {
            function: func.function,
            icon_url: func.icon_url,
          };
          if (func._id) {
            payloadFunction._id = func._id;
          }
          return payloadFunction;
        }),
      };

      const { data } = await API.put("/admin-setting/", payload);
      if (data.success) {
        toast.success(data.message);
        getAdminSetting();
      } else {
        toast.error(data.message);
      }
    }
  };

  return (
    <>
      {/* <div className="mt-2" style={{ fontSize: "16px", opacity: "0.9" }}>
        Profile
      </div> */}
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="form-floating">
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <label className="fs-20">Functions</label>
                <button
                  className="btn-gradient-primary border rounded px-3 py-1"
                  onClick={() => handleAddFunction()}
                >
                  Add New
                </button>
              </div>
              {functions?.map((e, index) => {
                return (
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <input
                        type="text"
                        className="form-control form-fs form-label-border p-2 mt-3"
                        defaultValue={e.function}
                        onChange={(event) =>
                          handleFunctionChange(
                            index,
                            "function",
                            event.target.value
                          )
                        }
                      />
                      <input
                        type="text"
                        className="form-control mt-1 form-fs form-label-border p-2 mt-3"
                        value={e.icon_url}
                        onChange={(event) =>
                          handleFunctionChange(
                            index,
                            "icon_url",
                            event.target.value
                          )
                        }
                        placeholder="Icon URL"
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <div className="lable-fw-size">
              <button
                className="btn-gradient-primary mt-3 border rounded p-2 px-4"
                onClick={() => onSubmit()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
