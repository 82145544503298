import React, { useEffect, useState } from "react";
import { API } from "../API";
import { toast } from "react-hot-toast";
import BorderCard from "./Cards/BorderCard";
import { Link } from "react-router-dom";

const Events = () => {
  const [setting, setSetting] = useState({});

  const getAdminSetting = async () => {
    const { data } = await API.get("/admin-setting/");
    if (data.success) {
      setSetting(data.data);
    } else {
      toast.error(data.message);
    }
  };
  useEffect(() => {
    getAdminSetting();
  }, []);
  const colors = [
    "#fff283",
    "#FFD2EF",
    "#93C3EC",
    "#0BCB8D",
    "#ffa873",
    "#d09eff",
  ];

  return (
    <>
      <div className="container mt-3 container-main">
        <div className="row mt-3">
          {setting?.functions?.map((e, i) => (
            <>
              <div className="col-md-3 mt-4">
                <Link
                  to={"/functions/project"}
                  state={{ id: e._id ,function: e.function}}
                  className="btn-link text-decoration-none"
                >
                  <BorderCard
                    title={e.function}
                    bg={colors[i % colors.length]}
                    src={e.icon_url}
                  />
                </Link>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default Events;
