import React, { useEffect, useRef, useState } from "react";

export const ImagePage = ({ imageURL, dimensions, updateDimensions }) => {
  const canvasRef = useRef(null);
  const [width, setWidth] = useState((dimensions && dimensions.width) || 0);
  const [height, setHeight] = useState((dimensions && dimensions.height) || 0);

  useEffect(() => {
    const renderImage = () => {
      const image = new Image();
      image.src = imageURL;

      image.onload = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        setWidth(image.width);
        setHeight(image.height);

        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0, image.width, image.height);

        const newDimensions = {
          width: image.width,
          height: image.height,
        };
        updateDimensions(newDimensions);
      };
    };

    renderImage();
  }, [imageURL, updateDimensions, width]);

  return (
    <div>
      <canvas ref={canvasRef} width={width} height={height} />
    </div>
  );
};
