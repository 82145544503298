import React from 'react';
import { Text } from './Text';

export const Attachments = ({
  attachments,
  pdfName,
  pageDimensions,
  updateAttachment,
}) => {
  const handleAttachmentUpdate = (index) => (
    attachment
  ) => updateAttachment(index, attachment);
  // console.log("===attachments",attachments);

  return attachments ? (
    <>
      {attachments.length
        ? attachments.map((attachment, index) => {
            const key = `${pdfName}-${index}`;
            if (attachment.type === 'text') {
              return (
                <Text
                  key={key}
                  pageWidth={pageDimensions.width}
                  pageHeight={pageDimensions.height}
                  updateTextAttachment={handleAttachmentUpdate(index)}
                  {...(attachment)}
                />
              );
            }
            return null;
          })
        : null}
    </>
  ) : null;
};
