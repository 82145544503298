import React from "react";

function BorderCard({ title, value, src, bg }) {
  return (
    <>
      <div
        className={`text-color-primary rounded border-2 h-100`}
        style={{ background: bg }}
      >
        <div className="d-flex flex-column p-lg-4 p-md-2 p-sm-4 p-4 ">
          <div className="d-flex align-items-center">
            <img
              src={src ? src : "https://placehold.co/50x50"}
              alt={`img-${title}`}
              className="me-2 rounded-circle"
              width={50}
              height={50}
            />
            <div className="card-title1">{title}</div>
          </div>
          {value && (
            <div className="d-flex flex-row justify-content-between">
              <h6 className="fw-bold">{value}</h6>
            </div>
          )}
        </div>
      </div>

      {/* <div
      className={`text-color-primary border border-color-primary rounded border-2 h-100`}
    >
      <div className="d-flex flex-column p-lg-4 p-md-2 p-sm-4 p-4 ">
        <div className="d-flex align-items-center">
          <img
            src={src ? src : "https://placehold.co/50x50"}
            alt={`img-${title}`}
            className="me-2 rounded-circle"
            width={50}
            height={50}
          />
          <div className="card-title1">{title}</div>
        </div>
        {value && (
          <div className="d-flex flex-row justify-content-between">
            <h6 className="fw-bold">{value}</h6>
          </div>
        )}
      </div>
    </div> */}
    </>
  );
}

export default BorderCard;
