import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { API } from "../API";
import Loading from "react-fullscreen-loading";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const SendFailedFileModel = ({ isShow, csvVariables, projectId }) => {
  let navigate = useNavigate();
  const [project, setProject] = useState();
  const [qrModalStatus, setQrModalStatus] = useState(false);
  const [qrData, setQrData] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendFileModalStatus, setSendFileModalStatus] = useState(false);
  const [creditData, setCreditData] = useState({});
  const [isScan, setIsScan] = useState(false);

  let timer;

  useEffect(() => {
    const getProject = async () => {
      const res = await API.get(`/project/get/${projectId}`);
      setProject(res.data.data);
    };
    getProject();
  }, [projectId]);

  async function sendFile() {
    const formData = new FormData();
    formData.append("projectId", projectId);
    formData.append("csv", JSON.stringify(csvVariables));
    const { data } = await API.post(
      `/whatsapp/sendfiles?projectId=${projectId}`,
      formData
    );
    if (data.success) {
      toast.success(data.message);
      navigate("/projects", {
        state: { id: project.type },
      });
    } else toast.error("");
  }

  let timer2;
  const getQr = async () => {
    if (!qrData) {
      setLoading(true);
      timer2 = setInterval(async () => {
        const sesisonData = await API.get(
          `/whatsapp/issessionsaved/?projectId=${projectId}`
        );
        if (sesisonData.data.success === false) {
          const { data } = await API.get(
            `/whatsapp/getqr/?projectId=${projectId}`
          );
          setLoading(false);
          if (data.success) {
            setQrModalStatus(true);
            setQrData(data.data);
            getQrData();
          } else {
            toast.error(data.message);
          }
        }
      }, 10000);
    }
    if (qrData) {
      clearInterval(timer2);
    }
  };

  const getQrData = () => {
    return clearInterval(timer2);
  };

  useEffect(() => {
    async function sessionExist() {
      const { data } = await API.get(
        `/whatsapp/issessionsaved?projectId=${projectId}`
      );
      if (data.success) setIsScan(true);
    }
    sessionExist();
  }, [projectId]);

  const getScanQrCodeStatus = async () => {
    if (qrModalStatus) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(async () => {
        const { data } = await API.get(
          `/whatsapp/issessionsaved?projectId=${projectId}`
        );
        if (data.success) {
          setIsScan(true);
          setQrModalStatus(false);
        }
        // setSession(data);
      }, 10000);
    }
    if (!qrModalStatus) {
      clearInterval(timer);
    }
  };

  useEffect(() => {
    getScanQrCodeStatus();
    return () => clearInterval(timer);
  }, [qrModalStatus, timer]);

  return (
    <>
      {isShow && (
        <>
          <Modal
            show={isShow}
            backdrop="static"
            centered
            className="qrmodal"
            hidden={loading}
          >
            <Modal.Header className="justify-content-center">
              <Modal.Title className="page-title">
                Confirm Send File
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              <div className="d-flex justify-content-center gap-3">
                {!isScan ? (
                  <button
                    className="btn-gradient-primary p-2 border rounded"
                    onClick={() => getQr()}
                  >
                    Scan Qr
                  </button>
                ) : (
                  <div
                    onClick={async () => {
                      const { data } = await API.get(
                        `/whatsapp/logout?projectId=${projectId}`
                      );
                      if (data?.success) {
                        toast.success(data.message);
                        setIsScan(false);
                      }
                    }}
                    className="btn-gradient-primary p-2 border rounded"
                  >
                    Log Out
                  </div>
                )}
                <button
                  className="btn-gradient-primary p-2 border rounded"
                  onClick={async () => {
                    if (isScan) {
                      let data;
                      if (project.is_only_message) {
                        const res = await API.get(
                          `/credit/check-reminder-message-credit/${projectId}?count=${csvVariables.length}`
                        );
                        data = res.data;
                      } else {
                        const res = await API.post("/credit/checkcredit", {
                          count: csvVariables.length,
                        });
                        data = res.data;
                      }
                      if (data.success) {
                        setCreditData(data);
                        setSendFileModalStatus(true);
                      } else {
                        toast.error(data.message);
                      }
                    } else {
                      toast.error("Please scan qr");
                    }
                  }}
                >
                  Send File
                </button>
              </div>

              {/* <div className="d-flex justify-content-end">
                <div>
                  {!isScan ? (
                    <div className="mt-3 ms-2">
                      <div
                        className="col-md-12 text-end"
                        style={{ fontSize: 16 }}
                      >
                        <button
                          className="btn-gradient-primary p-2 border rounded"
                          onClick={() => getQr()}
                        >
                          Scan Qr
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3 ms-2">
                      <div
                        className="col-md-12 text-end"
                        style={{ fontSize: 14 }}
                      >
                        <div
                          onClick={async () => {
                            const { data } = await API.get(
                              `/whatsapp/logout?projectId=${projectId}`
                            );
                            if (data?.success) {
                              toast.success(data.message);
                              setIsScan(false);
                            }
                          }}
                          className="btn-gradient-primary p-2 border rounded"
                        >
                          Log Out
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <div className="mt-3">
                  <div className="col-md-12 ms-2">
                    <button
                      className="btn-gradient-primary p-2 border rounded"
                      onClick={async () => {
                        if (isScan) {
                          const { data } = await API.post(
                            "/credit/checkcredit",
                            {
                              count: csvVariables.length,
                            }
                          );
                          if (data.success) {
                            setCreditData(data);
                            setSendFileModalStatus(true);
                          } else {
                            toast.error(data.message);
                          }
                        } else {
                          toast.error("Please scan qr");
                        }
                      }}
                    >
                      Send File
                    </button>
                  </div>
                </div>
              </div> */}
            </Modal.Body>
          </Modal>
          <div>
            <div className="row mx-0">
              {qrModalStatus && (
                <Modal
                  show={qrModalStatus}
                  backdrop="static"
                  centered
                  className="qrmodal"
                >
                  <Modal.Body>
                    <div className="m-3">
                      <div
                        style={{
                          width: "264px",
                          height: "264px",
                          background: `url(${qrData})`,
                        }}
                      />
                    </div>
                  </Modal.Body>
                </Modal>
              )}

              {sendFileModalStatus && (
                <Modal
                  show={sendFileModalStatus}
                  onHide={() => setSendFileModalStatus(false)}
                  centered
                >
                  <Modal.Header className="px-4" closeButton>
                    <Modal.Title className="ms-auto page-title">
                      Confirm Send File
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="m-3">
                      <div className="form-group md-4">
                        <div className="lable-fw-size">{`Your Total pdf is : ${csvVariables.length}`}</div>
                        <div className="lable-fw-size">{`Your cut balance is : ${Number(
                          creditData?.data?.totalCharge
                        ).toFixed(2)}`}</div>
                        <div className="lable-fw-size">{`Your balance will be reduced to : ${Number(
                          creditData.data.remainingCredit
                        ).toFixed(2)}`}</div>
                        <div className="lable-fw-size">{`If you want to continue, click on Continue.`}</div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn-light fw-bold p-2 btn-gradient-primary p-2 border rounded"
                      onClick={() => setSendFileModalStatus(false)}
                      style={{ width: "15%" }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-gradient-primary fw-bold p-2 border rounded "
                      style={{ width: "18%" }}
                      onClick={async () => {
                        const { data } = await API.post(
                          `auth/update-holdBalance`,
                          {
                            count: csvVariables.length,
                            projectId: projectId,
                            reminderMessage: project.is_only_message
                              ? true
                              : false,
                          }
                        );
                        if (data.success) {
                          setSendFileModalStatus(false);
                          sendFile();
                        } else {
                          toast.error("Network Error");
                        }
                      }}
                    >
                      Continue
                    </button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </div>
        </>
      )}
      {loading && (
        <div>
          <Loading loading={loading} loaderColor="#24292f" />
        </div>
      )}
    </>
  );
};
