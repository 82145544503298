import { useForm } from "react-hook-form";
import { API } from "../API";
import toast from "react-hot-toast";

export const UserProfile = () => {
  const getUser = JSON.parse(localStorage.getItem("user"));

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: getUser.email,
      fullName: getUser.fullName,
      phoneNumber: getUser.phoneNumber,
    },
  });

  const onSubmit = async (data) => {
    const updateUserProfile = await API.post("/auth/updateUser", data);
    const response = updateUserProfile.data;
    if (response.success) {
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(response.data));
      toast.success(response.message);
    }
  };

  return (
    <>
      {/* <div className="mt-2" style={{ fontSize: "16px", opacity: "0.9" }}>
        Profile
      </div> */}
      <div className="row align-items-center">
        <div className="col-md-12">
          <form className="form-floating" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label className="lable-fw-size">Email</label>
              <input
                {...register("email")}
                type="email"
                className="form-control mt-1 form-fs form-label-border p-2"
                disabled
              />
            </div>
            <div className="mt-3">
              <label className="lable-fw-size">Full Name</label>
              <input
                {...register("fullName")}
                type="text"
                className="form-control mt-1 form-fs form-label-border p-2"
              />
            </div>
            <div className="mt-3 mb-3">
              <label className="lable-fw-size">Phone Number</label>
              <input
                {...register("phoneNumber")}
                type="text"
                className="form-control mt-1 form-fs form-label-border p-2"
              />
            </div>
            <div className="lable-fw-size">
              <button
                type="submit"
                className="btn-gradient-primary mt-3 border rounded p-2 px-4"
                value="Save"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
